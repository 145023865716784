/*
 * Type
 */

 @font-face {
   font-display: swap;
   font-family: 'Schnyder S';
   font-weight: 300;
   font-style: normal;
   src: url('../fonts/schnyder-s-light.woff2') format('woff2');
}

 @font-face {
   font-display: swap;
   font-family: 'Akkurat';
   font-weight: 400;
   font-style: normal;
   src: url('../fonts/akkurat-regular.woff2') format('woff2');
}

 @font-face {
   font-display: swap;
   font-family: 'Starling';
   font-weight: 400;
   font-style: normal;
   src: url('../fonts/starling-book.woff2') format('woff2');
}

 @font-face {
   font-display: swap;
   font-family: 'Starling';
   font-weight: 400;
   font-style: italic;
   src: url('../fonts/starling-book-italic.woff2') format('woff2');
}

:root {
    /* Mobile */

    /**
     * @classname: type-style-1
     * @description: H1 (Schnyder S)
     */
    --type-style-1-family: 'Schnyder S', serif;
    --type-style-1-weight: 300;
    --type-style-1-size: 4.5rem;
    --type-style-1-line-height: calc(50 / 45);
    --type-style-1-letter-spacing: 0.05rem;
    --type-style-1-text-transform: none;

    /**
     * @classname: type-style-2
     * @description: H2
     */
    --type-style-2-family: 'Akkurat', sans-serif;
    --type-style-2-weight: 400;
    --type-style-2-size: 2.4rem;
    --type-style-2-line-height: calc(30 / 24);
    --type-style-2-letter-spacing: 0.06rem;
    --type-style-2-text-transform: none;

    /**
     * @classname: type-style-3
     * @description: H3
     */
    --type-style-3-family: 'Akkurat', sans-serif;
    --type-style-3-weight: 400;
    --type-style-3-size: 1.7rem;
    --type-style-3-line-height: calc(24 / 17);
    --type-style-3-letter-spacing: 0.07rem;
    --type-style-3-text-transform: none;

    /**
     * @classname: type-style-4
     * @description: H4
     */
    --type-style-4-family: 'Akkurat', sans-serif;
    --type-style-4-weight: 400;
    --type-style-4-size: 1.3rem;
    --type-style-4-line-height: calc(18 / 13);
    --type-style-4-letter-spacing: 0.07rem;
    --type-style-4-text-transform: none;

    /**
     * @classname: type-style-5
     * @description: Body / Book
     */
    --type-style-5-family: 'Starling', sans-serif;
    --type-style-5-weight: 400;
    --type-style-5-size: 1.8rem;
    --type-style-5-line-height: calc(24 / 18);
    --type-style-5-letter-spacing: 0;
    --type-style-5-text-transform: none;

    /**
     * @classname: type-style-6
     * @description: Quote small
     */
    --type-style-6-family: 'Schnyder S', serif;
    --type-style-6-weight: 300;
    --type-style-6-size: 3.5rem;
    --type-style-6-line-height: calc(39 / 35);
    --type-style-6-letter-spacing: 0.07rem;
    --type-style-6-text-transform: none;

    /* Tablet */

    --sm-type-style-1-family: 'Schnyder S', serif;
    --sm-type-style-1-weight: 300;
    --sm-type-style-1-size: 6rem;
    --sm-type-style-1-line-height: calc(66 / 60);
    --sm-type-style-1-letter-spacing: 0.08rem;
    --sm-type-style-1-text-transform: none;

    --sm-type-style-2-family: var(--type-style-2-family);
    --sm-type-style-2-weight: var(--type-style-2-weight);
    --sm-type-style-2-size: var(--type-style-2-size);
    --sm-type-style-2-line-height: var(--type-style-2-line-height);
    --sm-type-style-2-letter-spacing: var(--type-style-2-letter-spacing);
    --sm-type-style-2-text-transform: var(--type-style-2-text-transform);

    --sm-type-style-3-family: var(--type-style-3-family);
    --sm-type-style-3-weight: var(--type-style-3-weight);
    --sm-type-style-3-size: var(--type-style-3-size);
    --sm-type-style-3-line-height: var(--type-style-3-line-height);
    --sm-type-style-3-letter-spacing: var(--type-style-3-letter-spacing);
    --sm-type-style-3-text-transform: var(--type-style-3-text-transform);

    --sm-type-style-4-family: var(--type-style-4-family);
    --sm-type-style-4-weight: var(--type-style-4-weight);
    --sm-type-style-4-size: var(--type-style-4-size);
    --sm-type-style-4-line-height: var(--type-style-4-line-height);
    --sm-type-style-4-letter-spacing: var(--type-style-4-letter-spacing);
    --sm-type-style-4-text-transform: var(--type-style-4-text-transform);

    --sm-type-style-5-family: var(--type-style-5-family);
    --sm-type-style-5-weight: var(--type-style-5-weight);
    --sm-type-style-5-size: var(--type-style-5-size);
    --sm-type-style-5-line-height: var(--type-style-5-line-height);
    --sm-type-style-5-letter-spacing: var(--type-style-5-letter-spacing);
    --sm-type-style-5-text-transform: var(--type-style-5-text-transform);

    --sm-type-style-6-family: 'Schnyder S', serif;
    --sm-type-style-6-weight: 300;
    --sm-type-style-6-size: 4.5rem;
    --sm-type-style-6-line-height: calc(50 / 45);
    --sm-type-style-6-letter-spacing: 0.07rem;
    --sm-type-style-6-text-transform: none;

    /* Laptop */

    --md-type-style-1-family: 'Schnyder S', serif;
    --md-type-style-1-weight: 300;
    --md-type-style-1-size: 7rem;
    --md-type-style-1-line-height: calc(75 / 70);
    --md-type-style-1-letter-spacing: 0.1rem;
    --md-type-style-1-text-transform: none;

    --md-type-style-2-family: 'Akkurat', sans-serif;
    --md-type-style-2-weight: 400;
    --md-type-style-2-size: 3rem;
    --md-type-style-2-line-height: calc(36 / 30);
    --md-type-style-2-letter-spacing: 0.06rem;
    --md-type-style-2-text-transform: none;

    --md-type-style-3-family: var(--type-style-3-family);
    --md-type-style-3-weight: var(--type-style-3-weight);
    --md-type-style-3-size: var(--type-style-3-size);
    --md-type-style-3-line-height: var(--type-style-3-line-height);
    --md-type-style-3-letter-spacing: var(--type-style-3-letter-spacing);
    --md-type-style-3-text-transform: var(--type-style-3-text-transform);

    --md-type-style-4-family: var(--type-style-4-family);
    --md-type-style-4-weight: var(--type-style-4-weight);
    --md-type-style-4-size: var(--type-style-4-size);
    --md-type-style-4-line-height: var(--type-style-4-line-height);
    --md-type-style-4-letter-spacing: var(--type-style-4-letter-spacing);
    --md-type-style-4-text-transform: var(--type-style-4-text-transform);

    --md-type-style-5-family: var(--type-style-5-family);
    --md-type-style-5-weight: var(--type-style-5-weight);
    --md-type-style-5-size: var(--type-style-5-size);
    --md-type-style-5-line-height: var(--type-style-5-line-height);
    --md-type-style-5-letter-spacing: var(--type-style-5-letter-spacing);
    --md-type-style-5-text-transform: var(--type-style-5-text-transform);

    --md-type-style-6-family: var(--sm-type-style-6-family);
    --md-type-style-6-weight: var(--sm-type-style-6-weight);
    --md-type-style-6-size: var(--sm-type-style-6-size);
    --md-type-style-6-line-height: var(--sm-type-style-6-line-height);
    --md-type-style-6-letter-spacing: var(--sm-type-style-6-letter-spacing);
    --md-type-style-6-text-transform: var(--sm-type-style-6-text-transform);

    /* Large desktop */

    --lg-type-style-1-family: 'Schnyder S', serif;
    --lg-type-style-1-weight: 300;
    --lg-type-style-1-size: 11rem;
    --lg-type-style-1-line-height: calc(120 / 110);
    --lg-type-style-1-letter-spacing: 0.11rem;
    --lg-type-style-1-text-transform: none;

    --lg-type-style-2-family: 'Akkurat', sans-serif;
    --lg-type-style-2-weight: 400;
    --lg-type-style-2-size: 4.5rem;
    --lg-type-style-2-line-height: calc(55 / 45);
    --lg-type-style-2-letter-spacing: 0.07rem;
    --lg-type-style-2-text-transform: none;

    --lg-type-style-3-family: 'Akkurat', sans-serif;
    --lg-type-style-3-weight: 400;
    --lg-type-style-3-size: 2.3rem;
    --lg-type-style-3-line-height: calc(32 / 23);
    --lg-type-style-3-letter-spacing: 0.03rem;
    --lg-type-style-3-text-transform: none;

    --lg-type-style-4-family: 'Akkurat', sans-serif;
    --lg-type-style-4-weight: 400;
    --lg-type-style-4-size: 1.6rem;
    --lg-type-style-4-line-height: calc(19 / 16);
    --lg-type-style-4-letter-spacing: 0.1rem;
    --lg-type-style-4-text-transform: none;

    --lg-type-style-5-family: 'Starling', sans-serif;
    --lg-type-style-5-weight: 400;
    --lg-type-style-5-size: 2.4rem;
    --lg-type-style-5-line-height: calc(34 / 24);
    --lg-type-style-5-letter-spacing: 0.02rem;
    --lg-type-style-5-text-transform: none;

    --lg-type-style-6-family: var(--sm-type-style-6-family);
    --lg-type-style-6-weight: var(--sm-type-style-6-weight);
    --lg-type-style-6-size: var(--sm-type-style-6-size);
    --lg-type-style-6-line-height: var(--sm-type-style-6-line-height);
    --lg-type-style-6-letter-spacing: var(--sm-type-style-6-letter-spacing);
    --lg-type-style-6-text-transform: var(--sm-type-style-6-text-transform);

}

html {
    font-size: 62.5%; /* Reset root value to 10px */
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

body {
    font: var(--type-style-5-weight) var(--type-style-5-size) / var(--type-style-5-line-height)  var(--type-style-5-family);
    letter-spacing: var(--type-style-5-letter-spacing);
    color: var(--black);
}

@media (--breakpoint-lg) {
    body {
        font: var(--lg-type-style-5-weight) var(--lg-type-style-5-size) / var(--lg-type-style-5-line-height)  var(--lg-type-style-5-family);
        letter-spacing: var(--lg-type-style-5-letter-spacing);
    }
}

/* ========================================================================= */
/* Rich Text */
/* ========================================================================= */

.rich-text :first-child { margin-top: 0; }

.rich-text :last-child { margin-bottom: 0; }

.rich-text i,
.rich-text em {
    font-style: italic;
}

.rich-text b,
.rich-text strong {
    font-weight: bold;
}

.rich-text:not(.rich-text-pullquote) a {
    text-decoration: none;
    background-image: linear-gradient(to bottom, var(--acid), var(--acid));
    background-size: 100% 2px;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: 100% 100%;
}

.rich-text:not(.rich-text-pullquote) a:hover,
.rich-text:not(.rich-text-pullquote) a:focus {
    background-image: linear-gradient(to bottom, currentColor, currentColor);
    background-size: 100% 1px;
}

.type-style-2 .rich-text:not(.rich-text-pullquote) a:hover,
.type-style-2.rich-text:not(.rich-text-pullquote) a:hover,
.type-style-2 .rich-text:not(.rich-text-pullquote) a:focus,
.type-style-2.rich-text:not(.rich-text-pullquote) a:focus {
    background-size: 100% 2px;
}

.rich-text.rich-text-pullquote a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    text-decoration-thickness: 2px;
    text-decoration-color: var(--acid);
    transition-property: text-decoration-color;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
}

.rich-text.rich-text-pullquote a:hover,
.rich-text.rich-text-pullquote a:focus {
    text-decoration-color: currentColor;
}

.rich-text.rich-text-pullquote.rich-text-pullquote__quote-icon::before {
    content: '“';
}

.rich-text ul li, .rich-text ol li {
    display: flex;
    overflow: hidden;
    align-items: baseline;
}

.rich-text ol {
    counter-reset: item;
}

.rich-text ol > li {
    align-items: baseline;
    counter-increment: item;
}

.rich-text ol > li:before {
    content: counter(item) ".";
    display: inline-block;
    margin-right: var(--space-4);
}
.rich-text ul > li::before {
    content: "—";
    display: inline-block;
    margin-right: 1.5rem;
    margin-right: var(--space-4);
}

@media (--breakpoint-md) {
    .rich-text.rich-text-pullquote a {
        text-underline-offset: 0.7rem;
    }
}

@media (--breakpoint-lg) {
    .rich-text.rich-text-pullquote {
        position: relative;
    }

    .rich-text.rich-text-pullquote.rich-text-pullquote__quote-icon::before {
        position: absolute;
        right: 100%;
        top: -.2em;
        margin-right: .2em;
    }
}

@media screen and (min-width: 2250px) {
    .rich-text.rich-text-pullquote.rich-text-pullquote__quote-icon::before {
        margin-right: .3em;
    }
}

.rich-text.rich-text-pullquote a.tag-link {
    position: relative;
}

.rich-text.rich-text-pullquote a.tag-link:after {
    content: attr(data-page-count);
    display: inline-block;
    font: var(--type-style-4-weight) var(--type-style-4-size) / var(--type-style-4-line-height)  var(--type-style-4-family);
    letter-spacing: var(--type-style-4-letter-spacing);
    color: var(--black-40);
    vertical-align: top;
    margin-top: 4px;
}

/**
 * Spacings:
 * 1. In flow version: text that should have a top space as
 *    it is inserted visually after another piece of content.
 * 2. Not in flow version: text that shouldn't include a top spacing.
 */

/* [1] */
.rich-text > p,
.rich-text > ul,
.rich-text > ol,
.rich-text > h2,
.rich-text > h3,
.rich-text > h4 {
    margin-top: var(--space-5);
}

/* [2] */
.rich-text:not(.rich-text--in-flow) > p:first-child,
.rich-text:not(.rich-text--in-flow) > ul:first-child,
.rich-text:not(.rich-text--in-flow) > ol:first-child,
.rich-text:not(.rich-text--in-flow) > h2:first-child,
.rich-text:not(.rich-text--in-flow) > h3:first-child,
.rich-text:not(.rich-text--in-flow) > h4:first-child {
    margin-top: 0;
}

@media (--breakpoint-sm) {
    /* [1] */
    .rich-text > p,
    .rich-text > ul,
    .rich-text > ol,
    .rich-text > h2,
    .rich-text > h3,
    .rich-text > h4 {
        margin-top: var(--space-6);
    }

    /* [2] */
    .rich-text:not(.rich-text--in-flow) > p:first-child,
    .rich-text:not(.rich-text--in-flow) > ul:first-child,
    .rich-text:not(.rich-text--in-flow) > ol:first-child,
    .rich-text:not(.rich-text--in-flow) > h2:first-child,
    .rich-text:not(.rich-text--in-flow) > h3:first-child,
    .rich-text:not(.rich-text--in-flow) > h4:first-child {
        margin-top: 0;
    }
}

@media (--breakpoint-md) {
    .rich-text.rich-text-pullquote a.tag-link:after {
        margin-top: 8px;
    }
}

@media (--breakpoint-lg) {
    .rich-text.rich-text-pullquote a.tag-link:after {
        font: var(--lg-type-style-4-weight) var(--lg-type-style-4-size) / var(--lg-type-style-4-line-height)  var(--lg-type-style-4-family);
        letter-spacing: var(--lg-type-style-4-letter-spacing);
        margin-top: 17px;
    }
}

/* ========================================================================= */
/* Dash / Topline */
/* ========================================================================= */

.dash {
    position: relative;
}

.dash:before {
    background-color: var(--black-20);
    content: '';
    display: block;
    height: 1px;
    position: relative;
    width: 30px;
}

.topline {
    position: relative;
}

.topline:before {
    background-color: var(--black-20);
    content: '';
    display: block;
    height: 1px;
    margin-bottom: var(--space-4);
    position: relative;
    width: 30px;
}

/* ========================================================================= */
/* Scopes */
/* ========================================================================= */

/**
 * A scope indicates a maximum width a text can reach based on its size.
 */

.heading-scope {
    max-width: 14.5em;
}

.text-scope {
    max-width: 22em;
}

/* ========================================================================= */
/* Scopes */
/* ========================================================================= */

.underline-2px {
    text-decoration-thickness: 2px;
}

.underline-offset-3px {
    text-underline-offset: 3px;
}

/* Testimonial quote prefix */

.testimonial-quote-icon:before {
    content: '“';
}

@media (--breakpoint-sm) {
    .testimonial-quote-icon:before {
        content: '';
    }

    .sm-testimonial-quote-icon {
        position: relative;
    }

    .sm-testimonial-quote-icon:before {
        content: '“';
        right: 100%;
        top: 0;
        margin-right: 0.5em;
    }
}

@media (--breakpoint-md) {
    .sm-testimonial-quote-icon:before {
        position: absolute;
    }
}

/* ========================================================================= */
/* Rich lists */
/* ========================================================================= */

/**
 * Rich lists are basically rich texts presented as lists.
 */
.rich-list p {
    margin-top: var(--space-2);
}