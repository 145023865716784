/*
 * Icons
 */

 [class ^= 'icon-'] {
     pointer-events: none;
 }

.icon-logo {
    width: 40px;
    height: 116px;
}

@media (--breakpoint-md) {
    .icon-logo {
        width: 30px;
        height: 87px;
    }
}

@media (--breakpoint-lg) {
    .icon-logo {
        width: 45px;
        height: 130px;
    }
}

.icon-burger {
    width: 35px;
    height: 25px;
}

.icon-plus,
.icon-minus {
    width: 25px;
    height: 25px;
}

@media (--breakpoint-md) {
    .icon-plus,
    .icon-minus {
        width: 30px;
        height: 30px;
    }
}

.icon-triangle {
    width: 10px;
    height: 12px;
}

.icon-play {
    width: 120px;
    height: 120px;
}
