/*
 * Spaces
 */


:root {
    --space-1: 1px;
    --space-2: 1rem;
    --space-3: 2rem;
    --space-4: 4rem;
    --space-5: 5rem;
    --space-6: 6rem;
    --space-7: 7rem;
    --space-8: 8rem;
    --space-9: 9rem;
    --space-10: 10rem;
    --space-11: 11rem;
    --space-12: 12rem;
    --space-13: 13rem;
    --space-14: 14rem;
    --space-15: 15rem;
    --space-16: 16rem;
}


.p0   { padding:0; }
.pt0  { padding-top:0; }
.pb0  { padding-bottom:0; }
.pl0  { padding-left:0; }
.pr0  { padding-right:0; }
.px0  { padding-left:0; padding-right:0; }
.py0  { padding-top:0; padding-bottom:0; }

.m0   { margin:0; }
.mt0  { margin-top: 0 }
.my0  { margin-top: 0; margin-bottom: 0; }
.mt0  { margin-top:0; }
.mb0  { margin-bottom:0; }
.ml0  { margin-left:0; }
.mr0  { margin-right:0; }
.mn0  { margin: 0; }
.mxn0 { margin-left: 0; margin-right: 0; }
.myn0 { margin-top: 0; margin-bottom: 0; }
.mln0 { margin-left: 0; }
.mrn0 { margin-right: 0; }
.mtn0 { margin-top: 0; }
.mbn0 { margin-bottom: 0; }


.p1   { padding: var(--space-1); }
.pt1  { padding-top: var(--space-1); }
.pb1  { padding-bottom: var(--space-1); }
.pl1  { padding-left: var(--space-1); }
.pr1  { padding-right: var(--space-1); }
.px1  { padding-left: var(--space-1); padding-right: var(--space-1); }
.py1  { padding-top: var(--space-1); padding-bottom: var(--space-1); }

.m1   { margin: var(--space-1); }
.mt1  { margin-top: var(--space-1); }
.mb1  { margin-bottom: var(--space-1); }
.ml1  { margin-left: var(--space-1); }
.mr1  { margin-right: var(--space-1); }
.mn1  { margin: calc(-1 * var(--space-1)); }
.mxn1 { margin-left: calc(-1 * var(--space-1)); margin-right: calc(-1 * var(--space-1)); }
.myn1 { margin-top: calc(-1 * var(--space-1)); margin-bottom: calc(-1 * var(--space-1)); }
.mln1 { margin-left: calc(-1 * var(--space-1)); }
.mrn1 { margin-right: calc(-1 * var(--space-1)); }
.mtn1 { margin-top: calc(-1 * var(--space-1)); }
.mbn1 { margin-bottom: calc(-1 * var(--space-1)); }


.p2   { padding: var(--space-2); }
.pt2  { padding-top: var(--space-2); }
.pb2  { padding-bottom: var(--space-2); }
.pl2  { padding-left: var(--space-2); }
.pr2  { padding-right: var(--space-2); }
.px2  { padding-left: var(--space-2); padding-right: var(--space-2); }
.py2  { padding-top: var(--space-2); padding-bottom: var(--space-2); }

.m2   { margin: var(--space-2); }
.mt2  { margin-top: var(--space-2); }
.mb2  { margin-bottom: var(--space-2); }
.ml2  { margin-left: var(--space-2); }
.mr2  { margin-right: var(--space-2); }
.mn2  { margin: calc(-1 * var(--space-2)); }
.mxn2 { margin-left: calc(-1 * var(--space-2)); margin-right: calc(-1 * var(--space-2)); }
.myn2 { margin-top: calc(-1 * var(--space-2)); margin-bottom: calc(-1 * var(--space-2)); }
.mln2 { margin-left: calc(-1 * var(--space-2)); }
.mrn2 { margin-right: calc(-1 * var(--space-2)); }
.mtn2 { margin-top: calc(-1 * var(--space-2)); }
.mbn2 { margin-bottom: calc(-1 * var(--space-2)); }


.p3   { padding: var(--space-3); }
.pt3  { padding-top: var(--space-3); }
.pb3  { padding-bottom: var(--space-3); }
.pl3  { padding-left: var(--space-3); }
.pr3  { padding-right: var(--space-3); }
.px3  { padding-left: var(--space-3); padding-right: var(--space-3); }
.py3  { padding-top: var(--space-3); padding-bottom: var(--space-3); }

.m3   { margin: var(--space-3); }
.mt3  { margin-top: var(--space-3); }
.mb3  { margin-bottom: var(--space-3); }
.ml3  { margin-left: var(--space-3); }
.mr3  { margin-right: var(--space-3); }
.mn3  { margin: calc(-1 * var(--space-3)); }
.mxn3 { margin-left: calc(-1 * var(--space-3)); margin-right: calc(-1 * var(--space-3)); }
.myn3 { margin-top: calc(-1 * var(--space-3)); margin-bottom: calc(-1 * var(--space-3)); }
.mln3 { margin-left: calc(-1 * var(--space-3)); }
.mrn3 { margin-right: calc(-1 * var(--space-3)); }
.mtn3 { margin-top: calc(-1 * var(--space-3)); }
.mbn3 { margin-bottom: calc(-1 * var(--space-3)); }


.p4   { padding: var(--space-4); }
.pt4  { padding-top: var(--space-4); }
.pb4  { padding-bottom: var(--space-4); }
.pl4  { padding-left: var(--space-4); }
.pr4  { padding-right: var(--space-4); }
.px4  { padding-left: var(--space-4); padding-right: var(--space-4); }
.py4  { padding-top: var(--space-4); padding-bottom: var(--space-4); }

.m4   { margin: var(--space-4); }
.mt4  { margin-top: var(--space-4); }
.mb4  { margin-bottom: var(--space-4); }
.ml4  { margin-left: var(--space-4); }
.mr4  { margin-right: var(--space-4); }
.mn4  { margin: calc(-1 * var(--space-4)); }
.mxn4 { margin-left: calc(-1 * var(--space-4)); margin-right: calc(-1 * var(--space-4)); }
.myn4 { margin-top: calc(-1 * var(--space-4)); margin-bottom: calc(-1 * var(--space-4)); }
.mln4 { margin-left: calc(-1 * var(--space-4)); }
.mrn4 { margin-right: calc(-1 * var(--space-4)); }
.mtn4 { margin-top: calc(-1 * var(--space-4)); }
.mbn4 { margin-bottom: calc(-1 * var(--space-4)); }


.p5   { padding: var(--space-5); }
.pt5  { padding-top: var(--space-5); }
.pb5  { padding-bottom: var(--space-5); }
.pl5  { padding-left: var(--space-5); }
.pr5  { padding-right: var(--space-5); }
.px5  { padding-left: var(--space-5); padding-right: var(--space-5); }
.py5  { padding-top: var(--space-5); padding-bottom: var(--space-5); }

.m5   { margin: var(--space-5); }
.mt5  { margin-top: var(--space-5); }
.mb5  { margin-bottom: var(--space-5); }
.ml5  { margin-left: var(--space-5); }
.mr5  { margin-right: var(--space-5); }
.mn5  { margin: calc(-1 * var(--space-5)); }
.mxn5 { margin-left: calc(-1 * var(--space-5)); margin-right: calc(-1 * var(--space-5)); }
.myn5 { margin-top: calc(-1 * var(--space-5)); margin-bottom: calc(-1 * var(--space-5)); }
.mln5 { margin-left: calc(-1 * var(--space-5)); }
.mrn5 { margin-right: calc(-1 * var(--space-5)); }
.mtn5 { margin-top: calc(-1 * var(--space-5)); }
.mbn5 { margin-bottom: calc(-1 * var(--space-5)); }


.p6   { padding: var(--space-6); }
.pt6  { padding-top: var(--space-6); }
.pb6  { padding-bottom: var(--space-6); }
.pl6  { padding-left: var(--space-6); }
.pr6  { padding-right: var(--space-6); }
.px6  { padding-left: var(--space-6); padding-right: var(--space-6); }
.py6  { padding-top: var(--space-6); padding-bottom: var(--space-6); }

.m6   { margin: var(--space-6); }
.mt6  { margin-top: var(--space-6); }
.mb6  { margin-bottom: var(--space-6); }
.ml6  { margin-left: var(--space-6); }
.mr6  { margin-right: var(--space-6); }
.mn6  { margin: calc(-1 * var(--space-6)); }
.mxn6 { margin-left: calc(-1 * var(--space-6)); margin-right: calc(-1 * var(--space-6)); }
.myn6 { margin-top: calc(-1 * var(--space-6)); margin-bottom: calc(-1 * var(--space-6)); }
.mln6 { margin-left: calc(-1 * var(--space-6)); }
.mrn6 { margin-right: calc(-1 * var(--space-6)); }
.mtn6 { margin-top: calc(-1 * var(--space-6)); }
.mbn6 { margin-bottom: calc(-1 * var(--space-6)); }


.p7   { padding: var(--space-7); }
.pt7  { padding-top: var(--space-7); }
.pb7  { padding-bottom: var(--space-7); }
.pl7  { padding-left: var(--space-7); }
.pr7  { padding-right: var(--space-7); }
.px7  { padding-left: var(--space-7); padding-right: var(--space-7); }
.py7  { padding-top: var(--space-7); padding-bottom: var(--space-7); }

.m7   { margin: var(--space-7); }
.mt7  { margin-top: var(--space-7); }
.mb7  { margin-bottom: var(--space-7); }
.ml7  { margin-left: var(--space-7); }
.mr7  { margin-right: var(--space-7); }
.mn7  { margin: calc(-1 * var(--space-7)); }
.mxn7 { margin-left: calc(-1 * var(--space-7)); margin-right: calc(-1 * var(--space-7)); }
.myn7 { margin-top: calc(-1 * var(--space-7)); margin-bottom: calc(-1 * var(--space-7)); }
.mln7 { margin-left: calc(-1 * var(--space-7)); }
.mrn7 { margin-right: calc(-1 * var(--space-7)); }
.mtn7 { margin-top: calc(-1 * var(--space-7)); }
.mbn7 { margin-bottom: calc(-1 * var(--space-7)); }


.p8   { padding: var(--space-8); }
.pt8  { padding-top: var(--space-8); }
.pb8  { padding-bottom: var(--space-8); }
.pl8  { padding-left: var(--space-8); }
.pr8  { padding-right: var(--space-8); }
.px8  { padding-left: var(--space-8); padding-right: var(--space-8); }
.py8  { padding-top: var(--space-8); padding-bottom: var(--space-8); }

.m8   { margin: var(--space-8); }
.mt8  { margin-top: var(--space-8); }
.mb8  { margin-bottom: var(--space-8); }
.ml8  { margin-left: var(--space-8); }
.mr8  { margin-right: var(--space-8); }
.mn8  { margin: calc(-1 * var(--space-8)); }
.mxn8 { margin-left: calc(-1 * var(--space-8)); margin-right: calc(-1 * var(--space-8)); }
.myn8 { margin-top: calc(-1 * var(--space-8)); margin-bottom: calc(-1 * var(--space-8)); }
.mln8 { margin-left: calc(-1 * var(--space-8)); }
.mrn8 { margin-right: calc(-1 * var(--space-8)); }
.mtn8 { margin-top: calc(-1 * var(--space-8)); }
.mbn8 { margin-bottom: calc(-1 * var(--space-8)); }


.p9   { padding: var(--space-9); }
.pt9  { padding-top: var(--space-9); }
.pb9  { padding-bottom: var(--space-9); }
.pl9  { padding-left: var(--space-9); }
.pr9  { padding-right: var(--space-9); }
.px9  { padding-left: var(--space-9); padding-right: var(--space-9); }
.py9  { padding-top: var(--space-9); padding-bottom: var(--space-9); }

.m9   { margin: var(--space-9); }
.mt9  { margin-top: var(--space-9); }
.mb9  { margin-bottom: var(--space-9); }
.ml9  { margin-left: var(--space-9); }
.mr9  { margin-right: var(--space-9); }
.mn9  { margin: calc(-1 * var(--space-9)); }
.mxn9 { margin-left: calc(-1 * var(--space-9)); margin-right: calc(-1 * var(--space-9)); }
.myn9 { margin-top: calc(-1 * var(--space-9)); margin-bottom: calc(-1 * var(--space-9)); }
.mln9 { margin-left: calc(-1 * var(--space-9)); }
.mrn9 { margin-right: calc(-1 * var(--space-9)); }
.mtn9 { margin-top: calc(-1 * var(--space-9)); }
.mbn9 { margin-bottom: calc(-1 * var(--space-9)); }


.p10   { padding: var(--space-10); }
.pt10  { padding-top: var(--space-10); }
.pb10  { padding-bottom: var(--space-10); }
.pl10  { padding-left: var(--space-10); }
.pr10  { padding-right: var(--space-10); }
.px10  { padding-left: var(--space-10); padding-right: var(--space-10); }
.py10  { padding-top: var(--space-10); padding-bottom: var(--space-10); }

.m10   { margin: var(--space-10); }
.mt10  { margin-top: var(--space-10); }
.mb10  { margin-bottom: var(--space-10); }
.ml10  { margin-left: var(--space-10); }
.mr10  { margin-right: var(--space-10); }
.mn10  { margin: calc(-1 * var(--space-10)); }
.mxn10 { margin-left: calc(-1 * var(--space-10)); margin-right: calc(-1 * var(--space-10)); }
.myn10 { margin-top: calc(-1 * var(--space-10)); margin-bottom: calc(-1 * var(--space-10)); }
.mln10 { margin-left: calc(-1 * var(--space-10)); }
.mrn10 { margin-right: calc(-1 * var(--space-10)); }
.mtn10 { margin-top: calc(-1 * var(--space-10)); }
.mbn10 { margin-bottom: calc(-1 * var(--space-10)); }


.p11   { padding: var(--space-11); }
.pt11  { padding-top: var(--space-11); }
.pb11  { padding-bottom: var(--space-11); }
.pl11  { padding-left: var(--space-11); }
.pr11  { padding-right: var(--space-11); }
.px11  { padding-left: var(--space-11); padding-right: var(--space-11); }
.py11  { padding-top: var(--space-11); padding-bottom: var(--space-11); }

.m11   { margin: var(--space-11); }
.mt11  { margin-top: var(--space-11); }
.mb11  { margin-bottom: var(--space-11); }
.ml11  { margin-left: var(--space-11); }
.mr11  { margin-right: var(--space-11); }
.mn11  { margin: calc(-1 * var(--space-11)); }
.mxn11 { margin-left: calc(-1 * var(--space-11)); margin-right: calc(-1 * var(--space-11)); }
.myn11 { margin-top: calc(-1 * var(--space-11)); margin-bottom: calc(-1 * var(--space-11)); }
.mln11 { margin-left: calc(-1 * var(--space-11)); }
.mrn11 { margin-right: calc(-1 * var(--space-11)); }
.mtn11 { margin-top: calc(-1 * var(--space-11)); }
.mbn11 { margin-bottom: calc(-1 * var(--space-11)); }


.p12   { padding: var(--space-12); }
.pt12  { padding-top: var(--space-12); }
.pb12  { padding-bottom: var(--space-12); }
.pl12  { padding-left: var(--space-12); }
.pr12  { padding-right: var(--space-12); }
.px12  { padding-left: var(--space-12); padding-right: var(--space-12); }
.py12  { padding-top: var(--space-12); padding-bottom: var(--space-12); }

.m12   { margin: var(--space-12); }
.mt12  { margin-top: var(--space-12); }
.mb12  { margin-bottom: var(--space-12); }
.ml12  { margin-left: var(--space-12); }
.mr12  { margin-right: var(--space-12); }
.mn12  { margin: calc(-1 * var(--space-12)); }
.mxn12 { margin-left: calc(-1 * var(--space-12)); margin-right: calc(-1 * var(--space-12)); }
.myn12 { margin-top: calc(-1 * var(--space-12)); margin-bottom: calc(-1 * var(--space-12)); }
.mln12 { margin-left: calc(-1 * var(--space-12)); }
.mrn12 { margin-right: calc(-1 * var(--space-12)); }
.mtn12 { margin-top: calc(-1 * var(--space-12)); }
.mbn12 { margin-bottom: calc(-1 * var(--space-12)); }


.p13   { padding: var(--space-13); }
.pt13  { padding-top: var(--space-13); }
.pb13  { padding-bottom: var(--space-13); }
.pl13  { padding-left: var(--space-13); }
.pr13  { padding-right: var(--space-13); }
.px13  { padding-left: var(--space-13); padding-right: var(--space-13); }
.py13  { padding-top: var(--space-13); padding-bottom: var(--space-13); }

.m13   { margin: var(--space-13); }
.mt13  { margin-top: var(--space-13); }
.mb13  { margin-bottom: var(--space-13); }
.ml13  { margin-left: var(--space-13); }
.mr13  { margin-right: var(--space-13); }
.mn13  { margin: calc(-1 * var(--space-13)); }
.mxn13 { margin-left: calc(-1 * var(--space-13)); margin-right: calc(-1 * var(--space-13)); }
.myn13 { margin-top: calc(-1 * var(--space-13)); margin-bottom: calc(-1 * var(--space-13)); }
.mln13 { margin-left: calc(-1 * var(--space-13)); }
.mrn13 { margin-right: calc(-1 * var(--space-13)); }
.mtn13 { margin-top: calc(-1 * var(--space-13)); }
.mbn13 { margin-bottom: calc(-1 * var(--space-13)); }


.p14   { padding: var(--space-14); }
.pt14  { padding-top: var(--space-14); }
.pb14  { padding-bottom: var(--space-14); }
.pl14  { padding-left: var(--space-14); }
.pr14  { padding-right: var(--space-14); }
.px14  { padding-left: var(--space-14); padding-right: var(--space-14); }
.py14  { padding-top: var(--space-14); padding-bottom: var(--space-14); }

.m14   { margin: var(--space-14); }
.mt14  { margin-top: var(--space-14); }
.mb14  { margin-bottom: var(--space-14); }
.ml14  { margin-left: var(--space-14); }
.mr14  { margin-right: var(--space-14); }
.mn14  { margin: calc(-1 * var(--space-14)); }
.mxn14 { margin-left: calc(-1 * var(--space-14)); margin-right: calc(-1 * var(--space-14)); }
.myn14 { margin-top: calc(-1 * var(--space-14)); margin-bottom: calc(-1 * var(--space-14)); }
.mln14 { margin-left: calc(-1 * var(--space-14)); }
.mrn14 { margin-right: calc(-1 * var(--space-14)); }
.mtn14 { margin-top: calc(-1 * var(--space-14)); }
.mbn14 { margin-bottom: calc(-1 * var(--space-14)); }


.p15   { padding: var(--space-15); }
.pt15  { padding-top: var(--space-15); }
.pb15  { padding-bottom: var(--space-15); }
.pl15  { padding-left: var(--space-15); }
.pr15  { padding-right: var(--space-15); }
.px15  { padding-left: var(--space-15); padding-right: var(--space-15); }
.py15  { padding-top: var(--space-15); padding-bottom: var(--space-15); }

.m15   { margin: var(--space-15); }
.mt15  { margin-top: var(--space-15); }
.mb15  { margin-bottom: var(--space-15); }
.ml15  { margin-left: var(--space-15); }
.mr15  { margin-right: var(--space-15); }
.mn15  { margin: calc(-1 * var(--space-15)); }
.mxn15 { margin-left: calc(-1 * var(--space-15)); margin-right: calc(-1 * var(--space-15)); }
.myn15 { margin-top: calc(-1 * var(--space-15)); margin-bottom: calc(-1 * var(--space-15)); }
.mln15 { margin-left: calc(-1 * var(--space-15)); }
.mrn15 { margin-right: calc(-1 * var(--space-15)); }
.mtn15 { margin-top: calc(-1 * var(--space-15)); }
.mbn15 { margin-bottom: calc(-1 * var(--space-15)); }


.p16   { padding: var(--space-16); }
.pt16  { padding-top: var(--space-16); }
.pb16  { padding-bottom: var(--space-16); }
.pl16  { padding-left: var(--space-16); }
.pr16  { padding-right: var(--space-16); }
.px16  { padding-left: var(--space-16); padding-right: var(--space-16); }
.py16  { padding-top: var(--space-16); padding-bottom: var(--space-16); }

.m16   { margin: var(--space-16); }
.mt16  { margin-top: var(--space-16); }
.mb16  { margin-bottom: var(--space-16); }
.ml16  { margin-left: var(--space-16); }
.mr16  { margin-right: var(--space-16); }
.mn16  { margin: calc(-1 * var(--space-16)); }
.mxn16 { margin-left: calc(-1 * var(--space-16)); margin-right: calc(-1 * var(--space-16)); }
.myn16 { margin-top: calc(-1 * var(--space-16)); margin-bottom: calc(-1 * var(--space-16)); }
.mln16 { margin-left: calc(-1 * var(--space-16)); }
.mrn16 { margin-right: calc(-1 * var(--space-16)); }
.mtn16 { margin-top: calc(-1 * var(--space-16)); }
.mbn16 { margin-bottom: calc(-1 * var(--space-16)); }


.mr-auto { margin-right: auto; }
.ml-auto { margin-left: auto; }


@media (--breakpoint-sm) {

    .sm-p0   { padding:0; }
    .sm-pt0  { padding-top:0; }
    .sm-pb0  { padding-bottom:0; }
    .sm-pl0  { padding-left:0; }
    .sm-pr0  { padding-right:0; }
    .sm-px0  { padding-left:0; padding-right:0; }
    .sm-py0  { padding-top:0; padding-bottom:0; }

    .sm-m0   { margin:0; }
    .sm-mt0  { margin-top: 0 }
    .sm-my0  { margin-top: 0; margin-bottom: 0; }
    .sm-mt0  { margin-top:0; }
    .sm-mb0  { margin-bottom:0; }
    .sm-ml0  { margin-left:0; }
    .sm-mr0  { margin-right:0; }
    .sm-mn0  { margin: 0; }
    .sm-mxn0 { margin-left: 0; margin-right: 0; }
    .sm-myn0 { margin-top: 0; margin-bottom: 0; }
    .sm-mln0 { margin-left: 0; }
    .sm-mrn0 { margin-right: 0; }
    .sm-mtn0 { margin-top: 0; }
    .sm-mbn0 { margin-bottom: 0; }


    .sm-p1   { padding: var(--space-1); }
    .sm-pt1  { padding-top: var(--space-1); }
    .sm-pb1  { padding-bottom: var(--space-1); }
    .sm-pl1  { padding-left: var(--space-1); }
    .sm-pr1  { padding-right: var(--space-1); }
    .sm-px1  { padding-left: var(--space-1); padding-right: var(--space-1); }
    .sm-py1  { padding-top: var(--space-1); padding-bottom: var(--space-1); }

    .sm-m1   { margin: var(--space-1); }
    .sm-mt1  { margin-top: var(--space-1); }
    .sm-mb1  { margin-bottom: var(--space-1); }
    .sm-ml1  { margin-left: var(--space-1); }
    .sm-mr1  { margin-right: var(--space-1); }
    .sm-mn1  { margin: calc(-1 * var(--space-1)); }
    .sm-mxn1 { margin-left: calc(-1 * var(--space-1)); margin-right: calc(-1 * var(--space-1)); }
    .sm-myn1 { margin-top: calc(-1 * var(--space-1)); margin-bottom: calc(-1 * var(--space-1)); }
    .sm-mln1 { margin-left: calc(-1 * var(--space-1)); }
    .sm-mrn1 { margin-right: calc(-1 * var(--space-1)); }
    .sm-mtn1 { margin-top: calc(-1 * var(--space-1)); }
    .sm-mbn1 { margin-bottom: calc(-1 * var(--space-1)); }


    .sm-p2   { padding: var(--space-2); }
    .sm-pt2  { padding-top: var(--space-2); }
    .sm-pb2  { padding-bottom: var(--space-2); }
    .sm-pl2  { padding-left: var(--space-2); }
    .sm-pr2  { padding-right: var(--space-2); }
    .sm-px2  { padding-left: var(--space-2); padding-right: var(--space-2); }
    .sm-py2  { padding-top: var(--space-2); padding-bottom: var(--space-2); }

    .sm-m2   { margin: var(--space-2); }
    .sm-mt2  { margin-top: var(--space-2); }
    .sm-mb2  { margin-bottom: var(--space-2); }
    .sm-my2  { margin-top: var(--space-2); margin-bottom: var(--space-2); }
    .sm-ml2  { margin-left: var(--space-2); }
    .sm-mr2  { margin-right: var(--space-2); }
    .sm-mn2  { margin: calc(-1 * var(--space-2)); }
    .sm-mxn2 { margin-left: calc(-1 * var(--space-2)); margin-right: calc(-1 * var(--space-2)); }
    .sm-myn2 { margin-top: calc(-1 * var(--space-2)); margin-bottom: calc(-1 * var(--space-2)); }
    .sm-mln2 { margin-left: calc(-1 * var(--space-2)); }
    .sm-mrn2 { margin-right: calc(-1 * var(--space-2)); }
    .sm-mtn2 { margin-top: calc(-1 * var(--space-2)); }
    .sm-mbn2 { margin-bottom: calc(-1 * var(--space-2)); }


    .sm-p3   { padding: var(--space-3); }
    .sm-pt3  { padding-top: var(--space-3); }
    .sm-pb3  { padding-bottom: var(--space-3); }
    .sm-pl3  { padding-left: var(--space-3); }
    .sm-pr3  { padding-right: var(--space-3); }
    .sm-px3  { padding-left: var(--space-3); padding-right: var(--space-3); }
    .sm-py3  { padding-top: var(--space-3); padding-bottom: var(--space-3); }

    .sm-m3   { margin: var(--space-3); }
    .sm-mt3  { margin-top: var(--space-3); }
    .sm-mb3  { margin-bottom: var(--space-3); }
    .sm-ml3  { margin-left: var(--space-3); }
    .sm-mr3  { margin-right: var(--space-3); }
    .sm-mn3  { margin: calc(-1 * var(--space-3)); }
    .sm-mxn3 { margin-left: calc(-1 * var(--space-3)); margin-right: calc(-1 * var(--space-3)); }
    .sm-myn3 { margin-top: calc(-1 * var(--space-3)); margin-bottom: calc(-1 * var(--space-3)); }
    .sm-mln3 { margin-left: calc(-1 * var(--space-3)); }
    .sm-mrn3 { margin-right: calc(-1 * var(--space-3)); }
    .sm-mtn3 { margin-top: calc(-1 * var(--space-3)); }
    .sm-mbn3 { margin-bottom: calc(-1 * var(--space-3)); }


    .sm-p4   { padding: var(--space-4); }
    .sm-pt4  { padding-top: var(--space-4); }
    .sm-pb4  { padding-bottom: var(--space-4); }
    .sm-pl4  { padding-left: var(--space-4); }
    .sm-pr4  { padding-right: var(--space-4); }
    .sm-px4  { padding-left: var(--space-4); padding-right: var(--space-4); }
    .sm-py4  { padding-top: var(--space-4); padding-bottom: var(--space-4); }

    .sm-m4   { margin: var(--space-4); }
    .sm-mt4  { margin-top: var(--space-4); }
    .sm-mb4  { margin-bottom: var(--space-4); }
    .sm-ml4  { margin-left: var(--space-4); }
    .sm-mr4  { margin-right: var(--space-4); }
    .sm-mn4  { margin: calc(-1 * var(--space-4)); }
    .sm-mxn4 { margin-left: calc(-1 * var(--space-4)); margin-right: calc(-1 * var(--space-4)); }
    .sm-myn4 { margin-top: calc(-1 * var(--space-4)); margin-bottom: calc(-1 * var(--space-4)); }
    .sm-mln4 { margin-left: calc(-1 * var(--space-4)); }
    .sm-mrn4 { margin-right: calc(-1 * var(--space-4)); }
    .sm-mtn4 { margin-top: calc(-1 * var(--space-4)); }
    .sm-mbn4 { margin-bottom: calc(-1 * var(--space-4)); }


    .sm-p5   { padding: var(--space-5); }
    .sm-pt5  { padding-top: var(--space-5); }
    .sm-pb5  { padding-bottom: var(--space-5); }
    .sm-pl5  { padding-left: var(--space-5); }
    .sm-pr5  { padding-right: var(--space-5); }
    .sm-px5  { padding-left: var(--space-5); padding-right: var(--space-5); }
    .sm-py5  { padding-top: var(--space-5); padding-bottom: var(--space-5); }

    .sm-m5   { margin: var(--space-5); }
    .sm-mt5  { margin-top: var(--space-5); }
    .sm-mb5  { margin-bottom: var(--space-5); }
    .sm-ml5  { margin-left: var(--space-5); }
    .sm-mr5  { margin-right: var(--space-5); }
    .sm-mn5  { margin: calc(-1 * var(--space-5)); }
    .sm-mxn5 { margin-left: calc(-1 * var(--space-5)); margin-right: calc(-1 * var(--space-5)); }
    .sm-myn5 { margin-top: calc(-1 * var(--space-5)); margin-bottom: calc(-1 * var(--space-5)); }
    .sm-mln5 { margin-left: calc(-1 * var(--space-5)); }
    .sm-mrn5 { margin-right: calc(-1 * var(--space-5)); }
    .sm-mtn5 { margin-top: calc(-1 * var(--space-5)); }
    .sm-mbn5 { margin-bottom: calc(-1 * var(--space-5)); }


    .sm-p6   { padding: var(--space-6); }
    .sm-pt6  { padding-top: var(--space-6); }
    .sm-pb6  { padding-bottom: var(--space-6); }
    .sm-pl6  { padding-left: var(--space-6); }
    .sm-pr6  { padding-right: var(--space-6); }
    .sm-px6  { padding-left: var(--space-6); padding-right: var(--space-6); }
    .sm-py6  { padding-top: var(--space-6); padding-bottom: var(--space-6); }

    .sm-m6   { margin: var(--space-6); }
    .sm-mt6  { margin-top: var(--space-6); }
    .sm-mb6  { margin-bottom: var(--space-6); }
    .sm-ml6  { margin-left: var(--space-6); }
    .sm-mr6  { margin-right: var(--space-6); }
    .sm-mn6  { margin: calc(-1 * var(--space-6)); }
    .sm-mxn6 { margin-left: calc(-1 * var(--space-6)); margin-right: calc(-1 * var(--space-6)); }
    .sm-myn6 { margin-top: calc(-1 * var(--space-6)); margin-bottom: calc(-1 * var(--space-6)); }
    .sm-mln6 { margin-left: calc(-1 * var(--space-6)); }
    .sm-mrn6 { margin-right: calc(-1 * var(--space-6)); }
    .sm-mtn6 { margin-top: calc(-1 * var(--space-6)); }
    .sm-mbn6 { margin-bottom: calc(-1 * var(--space-6)); }


    .sm-p7   { padding: var(--space-7); }
    .sm-pt7  { padding-top: var(--space-7); }
    .sm-pb7  { padding-bottom: var(--space-7); }
    .sm-pl7  { padding-left: var(--space-7); }
    .sm-pr7  { padding-right: var(--space-7); }
    .sm-px7  { padding-left: var(--space-7); padding-right: var(--space-7); }
    .sm-py7  { padding-top: var(--space-7); padding-bottom: var(--space-7); }

    .sm-m7   { margin: var(--space-7); }
    .sm-mt7  { margin-top: var(--space-7); }
    .sm-mb7  { margin-bottom: var(--space-7); }
    .sm-ml7  { margin-left: var(--space-7); }
    .sm-mr7  { margin-right: var(--space-7); }
    .sm-mn7  { margin: calc(-1 * var(--space-7)); }
    .sm-mxn7 { margin-left: calc(-1 * var(--space-7)); margin-right: calc(-1 * var(--space-7)); }
    .sm-myn7 { margin-top: calc(-1 * var(--space-7)); margin-bottom: calc(-1 * var(--space-7)); }
    .sm-mln7 { margin-left: calc(-1 * var(--space-7)); }
    .sm-mrn7 { margin-right: calc(-1 * var(--space-7)); }
    .sm-mtn7 { margin-top: calc(-1 * var(--space-7)); }
    .sm-mbn7 { margin-bottom: calc(-1 * var(--space-7)); }


    .sm-p8   { padding: var(--space-8); }
    .sm-pt8  { padding-top: var(--space-8); }
    .sm-pb8  { padding-bottom: var(--space-8); }
    .sm-pl8  { padding-left: var(--space-8); }
    .sm-pr8  { padding-right: var(--space-8); }
    .sm-px8  { padding-left: var(--space-8); padding-right: var(--space-8); }
    .sm-py8  { padding-top: var(--space-8); padding-bottom: var(--space-8); }

    .sm-m8   { margin: var(--space-8); }
    .sm-mt8  { margin-top: var(--space-8); }
    .sm-mb8  { margin-bottom: var(--space-8); }
    .sm-ml8  { margin-left: var(--space-8); }
    .sm-mr8  { margin-right: var(--space-8); }
    .sm-mn8  { margin: calc(-1 * var(--space-8)); }
    .sm-mxn8 { margin-left: calc(-1 * var(--space-8)); margin-right: calc(-1 * var(--space-8)); }
    .sm-myn8 { margin-top: calc(-1 * var(--space-8)); margin-bottom: calc(-1 * var(--space-8)); }
    .sm-mln8 { margin-left: calc(-1 * var(--space-8)); }
    .sm-mrn8 { margin-right: calc(-1 * var(--space-8)); }
    .sm-mtn8 { margin-top: calc(-1 * var(--space-8)); }
    .sm-mbn8 { margin-bottom: calc(-1 * var(--space-8)); }


    .sm-p9   { padding: var(--space-9); }
    .sm-pt9  { padding-top: var(--space-9); }
    .sm-pb9  { padding-bottom: var(--space-9); }
    .sm-pl9  { padding-left: var(--space-9); }
    .sm-pr9  { padding-right: var(--space-9); }
    .sm-px9  { padding-left: var(--space-9); padding-right: var(--space-9); }
    .sm-py9  { padding-top: var(--space-9); padding-bottom: var(--space-9); }

    .sm-m9   { margin: var(--space-9); }
    .sm-mt9  { margin-top: var(--space-9); }
    .sm-mb9  { margin-bottom: var(--space-9); }
    .sm-ml9  { margin-left: var(--space-9); }
    .sm-mr9  { margin-right: var(--space-9); }
    .sm-mn9  { margin: calc(-1 * var(--space-9)); }
    .sm-mxn9 { margin-left: calc(-1 * var(--space-9)); margin-right: calc(-1 * var(--space-9)); }
    .sm-myn9 { margin-top: calc(-1 * var(--space-9)); margin-bottom: calc(-1 * var(--space-9)); }
    .sm-mln9 { margin-left: calc(-1 * var(--space-9)); }
    .sm-mrn9 { margin-right: calc(-1 * var(--space-9)); }
    .sm-mtn9 { margin-top: calc(-1 * var(--space-9)); }
    .sm-mbn9 { margin-bottom: calc(-1 * var(--space-9)); }


    .sm-p10   { padding: var(--space-10); }
    .sm-pt10  { padding-top: var(--space-10); }
    .sm-pb10  { padding-bottom: var(--space-10); }
    .sm-pl10  { padding-left: var(--space-10); }
    .sm-pr10  { padding-right: var(--space-10); }
    .sm-px10  { padding-left: var(--space-10); padding-right: var(--space-10); }
    .sm-py10  { padding-top: var(--space-10); padding-bottom: var(--space-10); }

    .sm-m10   { margin: var(--space-10); }
    .sm-mt10  { margin-top: var(--space-10); }
    .sm-mb10  { margin-bottom: var(--space-10); }
    .sm-ml10  { margin-left: var(--space-10); }
    .sm-mr10  { margin-right: var(--space-10); }
    .sm-mn10  { margin: calc(-1 * var(--space-10)); }
    .sm-mxn10 { margin-left: calc(-1 * var(--space-10)); margin-right: calc(-1 * var(--space-10)); }
    .sm-myn10 { margin-top: calc(-1 * var(--space-10)); margin-bottom: calc(-1 * var(--space-10)); }
    .sm-mln10 { margin-left: calc(-1 * var(--space-10)); }
    .sm-mrn10 { margin-right: calc(-1 * var(--space-10)); }
    .sm-mtn10 { margin-top: calc(-1 * var(--space-10)); }
    .sm-mbn10 { margin-bottom: calc(-1 * var(--space-10)); }


    .sm-p11   { padding: var(--space-11); }
    .sm-pt11  { padding-top: var(--space-11); }
    .sm-pb11  { padding-bottom: var(--space-11); }
    .sm-pl11  { padding-left: var(--space-11); }
    .sm-pr11  { padding-right: var(--space-11); }
    .sm-px11  { padding-left: var(--space-11); padding-right: var(--space-11); }
    .sm-py11  { padding-top: var(--space-11); padding-bottom: var(--space-11); }

    .sm-m11   { margin: var(--space-11); }
    .sm-mt11  { margin-top: var(--space-11); }
    .sm-mb11  { margin-bottom: var(--space-11); }
    .sm-ml11  { margin-left: var(--space-11); }
    .sm-mr11  { margin-right: var(--space-11); }
    .sm-mn11  { margin: calc(-1 * var(--space-11)); }
    .sm-mxn11 { margin-left: calc(-1 * var(--space-11)); margin-right: calc(-1 * var(--space-11)); }
    .sm-myn11 { margin-top: calc(-1 * var(--space-11)); margin-bottom: calc(-1 * var(--space-11)); }
    .sm-mln11 { margin-left: calc(-1 * var(--space-11)); }
    .sm-mrn11 { margin-right: calc(-1 * var(--space-11)); }
    .sm-mtn11 { margin-top: calc(-1 * var(--space-11)); }
    .sm-mbn11 { margin-bottom: calc(-1 * var(--space-11)); }


    .sm-p12   { padding: var(--space-12); }
    .sm-pt12  { padding-top: var(--space-12); }
    .sm-pb12  { padding-bottom: var(--space-12); }
    .sm-pl12  { padding-left: var(--space-12); }
    .sm-pr12  { padding-right: var(--space-12); }
    .sm-px12  { padding-left: var(--space-12); padding-right: var(--space-12); }
    .sm-py12  { padding-top: var(--space-12); padding-bottom: var(--space-12); }

    .sm-m12   { margin: var(--space-12); }
    .sm-mt12  { margin-top: var(--space-12); }
    .sm-mb12  { margin-bottom: var(--space-12); }
    .sm-ml12  { margin-left: var(--space-12); }
    .sm-mr12  { margin-right: var(--space-12); }
    .sm-mn12  { margin: calc(-1 * var(--space-12)); }
    .sm-mxn12 { margin-left: calc(-1 * var(--space-12)); margin-right: calc(-1 * var(--space-12)); }
    .sm-myn12 { margin-top: calc(-1 * var(--space-12)); margin-bottom: calc(-1 * var(--space-12)); }
    .sm-mln12 { margin-left: calc(-1 * var(--space-12)); }
    .sm-mrn12 { margin-right: calc(-1 * var(--space-12)); }
    .sm-mtn12 { margin-top: calc(-1 * var(--space-12)); }
    .sm-mbn12 { margin-bottom: calc(-1 * var(--space-12)); }


    .sm-p13   { padding: var(--space-13); }
    .sm-pt13  { padding-top: var(--space-13); }
    .sm-pb13  { padding-bottom: var(--space-13); }
    .sm-pl13  { padding-left: var(--space-13); }
    .sm-pr13  { padding-right: var(--space-13); }
    .sm-px13  { padding-left: var(--space-13); padding-right: var(--space-13); }
    .sm-py13  { padding-top: var(--space-13); padding-bottom: var(--space-13); }

    .sm-m13   { margin: var(--space-13); }
    .sm-mt13  { margin-top: var(--space-13); }
    .sm-mb13  { margin-bottom: var(--space-13); }
    .sm-ml13  { margin-left: var(--space-13); }
    .sm-mr13  { margin-right: var(--space-13); }
    .sm-mn13  { margin: calc(-1 * var(--space-13)); }
    .sm-mxn13 { margin-left: calc(-1 * var(--space-13)); margin-right: calc(-1 * var(--space-13)); }
    .sm-myn13 { margin-top: calc(-1 * var(--space-13)); margin-bottom: calc(-1 * var(--space-13)); }
    .sm-mln13 { margin-left: calc(-1 * var(--space-13)); }
    .sm-mrn13 { margin-right: calc(-1 * var(--space-13)); }
    .sm-mtn13 { margin-top: calc(-1 * var(--space-13)); }
    .sm-mbn13 { margin-bottom: calc(-1 * var(--space-13)); }


    .sm-p14   { padding: var(--space-14); }
    .sm-pt14  { padding-top: var(--space-14); }
    .sm-pb14  { padding-bottom: var(--space-14); }
    .sm-pl14  { padding-left: var(--space-14); }
    .sm-pr14  { padding-right: var(--space-14); }
    .sm-px14  { padding-left: var(--space-14); padding-right: var(--space-14); }
    .sm-py14  { padding-top: var(--space-14); padding-bottom: var(--space-14); }

    .sm-m14   { margin: var(--space-14); }
    .sm-mt14  { margin-top: var(--space-14); }
    .sm-mb14  { margin-bottom: var(--space-14); }
    .sm-ml14  { margin-left: var(--space-14); }
    .sm-mr14  { margin-right: var(--space-14); }
    .sm-mn14  { margin: calc(-1 * var(--space-14)); }
    .sm-mxn14 { margin-left: calc(-1 * var(--space-14)); margin-right: calc(-1 * var(--space-14)); }
    .sm-myn14 { margin-top: calc(-1 * var(--space-14)); margin-bottom: calc(-1 * var(--space-14)); }
    .sm-mln14 { margin-left: calc(-1 * var(--space-14)); }
    .sm-mrn14 { margin-right: calc(-1 * var(--space-14)); }
    .sm-mtn14 { margin-top: calc(-1 * var(--space-14)); }
    .sm-mbn14 { margin-bottom: calc(-1 * var(--space-14)); }


    .sm-p15   { padding: var(--space-15); }
    .sm-pt15  { padding-top: var(--space-15); }
    .sm-pb15  { padding-bottom: var(--space-15); }
    .sm-pl15  { padding-left: var(--space-15); }
    .sm-pr15  { padding-right: var(--space-15); }
    .sm-px15  { padding-left: var(--space-15); padding-right: var(--space-15); }
    .sm-py15  { padding-top: var(--space-15); padding-bottom: var(--space-15); }

    .sm-m15   { margin: var(--space-15); }
    .sm-mt15  { margin-top: var(--space-15); }
    .sm-mb15  { margin-bottom: var(--space-15); }
    .sm-ml15  { margin-left: var(--space-15); }
    .sm-mr15  { margin-right: var(--space-15); }
    .sm-mn15  { margin: calc(-1 * var(--space-15)); }
    .sm-mxn15 { margin-left: calc(-1 * var(--space-15)); margin-right: calc(-1 * var(--space-15)); }
    .sm-myn15 { margin-top: calc(-1 * var(--space-15)); margin-bottom: calc(-1 * var(--space-15)); }
    .sm-mln15 { margin-left: calc(-1 * var(--space-15)); }
    .sm-mrn15 { margin-right: calc(-1 * var(--space-15)); }
    .sm-mtn15 { margin-top: calc(-1 * var(--space-15)); }
    .sm-mbn15 { margin-bottom: calc(-1 * var(--space-15)); }


    .sm-p16   { padding: var(--space-16); }
    .sm-pt16  { padding-top: var(--space-16); }
    .sm-pb16  { padding-bottom: var(--space-16); }
    .sm-pl16  { padding-left: var(--space-16); }
    .sm-pr16  { padding-right: var(--space-16); }
    .sm-px16  { padding-left: var(--space-16); padding-right: var(--space-16); }
    .sm-py16  { padding-top: var(--space-16); padding-bottom: var(--space-16); }

    .sm-m16   { margin: var(--space-16); }
    .sm-mt16  { margin-top: var(--space-16); }
    .sm-mb16  { margin-bottom: var(--space-16); }
    .sm-ml16  { margin-left: var(--space-16); }
    .sm-mr16  { margin-right: var(--space-16); }
    .sm-mn16  { margin: calc(-1 * var(--space-16)); }
    .sm-mxn16 { margin-left: calc(-1 * var(--space-16)); margin-right: calc(-1 * var(--space-16)); }
    .sm-myn16 { margin-top: calc(-1 * var(--space-16)); margin-bottom: calc(-1 * var(--space-16)); }
    .sm-mln16 { margin-left: calc(-1 * var(--space-16)); }
    .sm-mrn16 { margin-right: calc(-1 * var(--space-16)); }
    .sm-mtn16 { margin-top: calc(-1 * var(--space-16)); }
    .sm-mbn16 { margin-bottom: calc(-1 * var(--space-16)); }


    .sm-mr-auto { margin-right: auto; }
    .sm-ml-auto { margin-left: auto; }

}


@media (--breakpoint-md) {

    .md-p0   { padding:0; }
    .md-pt0  { padding-top:0; }
    .md-pb0  { padding-bottom:0; }
    .md-pl0  { padding-left:0; }
    .md-pr0  { padding-right:0; }
    .md-px0  { padding-left:0; padding-right:0; }
    .md-py0  { padding-top:0; padding-bottom:0; }

    .md-m0   { margin:0; }
    .md-mt0  { margin-top: 0 }
    .md-my0  { margin-top: 0; margin-bottom: 0; }
    .md-mt0  { margin-top:0; }
    .md-mb0  { margin-bottom:0; }
    .md-ml0  { margin-left:0; }
    .md-mr0  { margin-right:0; }
    .md-mn0  { margin: 0; }
    .md-mxn0 { margin-left: 0; margin-right: 0; }
    .md-myn0 { margin-top: 0; margin-bottom: 0; }
    .md-mln0 { margin-left: 0; }
    .md-mrn0 { margin-right: 0; }
    .md-mtn0 { margin-top: 0; }
    .md-mbn0 { margin-bottom: 0; }


    .md-p1   { padding: var(--space-1); }
    .md-pt1  { padding-top: var(--space-1); }
    .md-pb1  { padding-bottom: var(--space-1); }
    .md-pl1  { padding-left: var(--space-1); }
    .md-pr1  { padding-right: var(--space-1); }
    .md-px1  { padding-left: var(--space-1); padding-right: var(--space-1); }
    .md-py1  { padding-top: var(--space-1); padding-bottom: var(--space-1); }

    .md-m1   { margin: var(--space-1); }
    .md-mt1  { margin-top: var(--space-1); }
    .md-mb1  { margin-bottom: var(--space-1); }
    .md-ml1  { margin-left: var(--space-1); }
    .md-mr1  { margin-right: var(--space-1); }
    .md-mn1  { margin: calc(-1 * var(--space-1)); }
    .md-mxn1 { margin-left: calc(-1 * var(--space-1)); margin-right: calc(-1 * var(--space-1)); }
    .md-myn1 { margin-top: calc(-1 * var(--space-1)); margin-bottom: calc(-1 * var(--space-1)); }
    .md-mln1 { margin-left: calc(-1 * var(--space-1)); }
    .md-mrn1 { margin-right: calc(-1 * var(--space-1)); }
    .md-mtn1 { margin-top: calc(-1 * var(--space-1)); }
    .md-mbn1 { margin-bottom: calc(-1 * var(--space-1)); }


    .md-p2   { padding: var(--space-2); }
    .md-pt2  { padding-top: var(--space-2); }
    .md-pb2  { padding-bottom: var(--space-2); }
    .md-pl2  { padding-left: var(--space-2); }
    .md-pr2  { padding-right: var(--space-2); }
    .md-px2  { padding-left: var(--space-2); padding-right: var(--space-2); }
    .md-py2  { padding-top: var(--space-2); padding-bottom: var(--space-2); }

    .md-m2   { margin: var(--space-2); }
    .md-mt2  { margin-top: var(--space-2); }
    .md-mb2  { margin-bottom: var(--space-2); }
    .md-my2  { margin-top: var(--space-2); margin-bottom: var(--space-2); }
    .md-ml2  { margin-left: var(--space-2); }
    .md-mr2  { margin-right: var(--space-2); }
    .md-mn2  { margin: calc(-1 * var(--space-2)); }
    .md-mxn2 { margin-left: calc(-1 * var(--space-2)); margin-right: calc(-1 * var(--space-2)); }
    .md-myn2 { margin-top: calc(-1 * var(--space-2)); margin-bottom: calc(-1 * var(--space-2)); }
    .md-mln2 { margin-left: calc(-1 * var(--space-2)); }
    .md-mrn2 { margin-right: calc(-1 * var(--space-2)); }
    .md-mtn2 { margin-top: calc(-1 * var(--space-2)); }
    .md-mbn2 { margin-bottom: calc(-1 * var(--space-2)); }


    .md-p3   { padding: var(--space-3); }
    .md-pt3  { padding-top: var(--space-3); }
    .md-pb3  { padding-bottom: var(--space-3); }
    .md-pl3  { padding-left: var(--space-3); }
    .md-pr3  { padding-right: var(--space-3); }
    .md-px3  { padding-left: var(--space-3); padding-right: var(--space-3); }
    .md-py3  { padding-top: var(--space-3); padding-bottom: var(--space-3); }

    .md-m3   { margin: var(--space-3); }
    .md-mt3  { margin-top: var(--space-3); }
    .md-mb3  { margin-bottom: var(--space-3); }
    .md-ml3  { margin-left: var(--space-3); }
    .md-mr3  { margin-right: var(--space-3); }
    .md-mn3  { margin: calc(-1 * var(--space-3)); }
    .md-mxn3 { margin-left: calc(-1 * var(--space-3)); margin-right: calc(-1 * var(--space-3)); }
    .md-myn3 { margin-top: calc(-1 * var(--space-3)); margin-bottom: calc(-1 * var(--space-3)); }
    .md-mln3 { margin-left: calc(-1 * var(--space-3)); }
    .md-mrn3 { margin-right: calc(-1 * var(--space-3)); }
    .md-mtn3 { margin-top: calc(-1 * var(--space-3)); }
    .md-mbn3 { margin-bottom: calc(-1 * var(--space-3)); }


    .md-p4   { padding: var(--space-4); }
    .md-pt4  { padding-top: var(--space-4); }
    .md-pb4  { padding-bottom: var(--space-4); }
    .md-pl4  { padding-left: var(--space-4); }
    .md-pr4  { padding-right: var(--space-4); }
    .md-px4  { padding-left: var(--space-4); padding-right: var(--space-4); }
    .md-py4  { padding-top: var(--space-4); padding-bottom: var(--space-4); }

    .md-m4   { margin: var(--space-4); }
    .md-mt4  { margin-top: var(--space-4); }
    .md-mb4  { margin-bottom: var(--space-4); }
    .md-ml4  { margin-left: var(--space-4); }
    .md-mr4  { margin-right: var(--space-4); }
    .md-mn4  { margin: calc(-1 * var(--space-4)); }
    .md-mxn4 { margin-left: calc(-1 * var(--space-4)); margin-right: calc(-1 * var(--space-4)); }
    .md-myn4 { margin-top: calc(-1 * var(--space-4)); margin-bottom: calc(-1 * var(--space-4)); }
    .md-mln4 { margin-left: calc(-1 * var(--space-4)); }
    .md-mrn4 { margin-right: calc(-1 * var(--space-4)); }
    .md-mtn4 { margin-top: calc(-1 * var(--space-4)); }
    .md-mbn4 { margin-bottom: calc(-1 * var(--space-4)); }


    .md-p5   { padding: var(--space-5); }
    .md-pt5  { padding-top: var(--space-5); }
    .md-pb5  { padding-bottom: var(--space-5); }
    .md-pl5  { padding-left: var(--space-5); }
    .md-pr5  { padding-right: var(--space-5); }
    .md-px5  { padding-left: var(--space-5); padding-right: var(--space-5); }
    .md-py5  { padding-top: var(--space-5); padding-bottom: var(--space-5); }

    .md-m5   { margin: var(--space-5); }
    .md-mt5  { margin-top: var(--space-5); }
    .md-mb5  { margin-bottom: var(--space-5); }
    .md-ml5  { margin-left: var(--space-5); }
    .md-mr5  { margin-right: var(--space-5); }
    .md-mn5  { margin: calc(-1 * var(--space-5)); }
    .md-mxn5 { margin-left: calc(-1 * var(--space-5)); margin-right: calc(-1 * var(--space-5)); }
    .md-myn5 { margin-top: calc(-1 * var(--space-5)); margin-bottom: calc(-1 * var(--space-5)); }
    .md-mln5 { margin-left: calc(-1 * var(--space-5)); }
    .md-mrn5 { margin-right: calc(-1 * var(--space-5)); }
    .md-mtn5 { margin-top: calc(-1 * var(--space-5)); }
    .md-mbn5 { margin-bottom: calc(-1 * var(--space-5)); }


    .md-p6   { padding: var(--space-6); }
    .md-pt6  { padding-top: var(--space-6); }
    .md-pb6  { padding-bottom: var(--space-6); }
    .md-pl6  { padding-left: var(--space-6); }
    .md-pr6  { padding-right: var(--space-6); }
    .md-px6  { padding-left: var(--space-6); padding-right: var(--space-6); }
    .md-py6  { padding-top: var(--space-6); padding-bottom: var(--space-6); }

    .md-m6   { margin: var(--space-6); }
    .md-mt6  { margin-top: var(--space-6); }
    .md-mb6  { margin-bottom: var(--space-6); }
    .md-ml6  { margin-left: var(--space-6); }
    .md-mr6  { margin-right: var(--space-6); }
    .md-mn6  { margin: calc(-1 * var(--space-6)); }
    .md-mxn6 { margin-left: calc(-1 * var(--space-6)); margin-right: calc(-1 * var(--space-6)); }
    .md-myn6 { margin-top: calc(-1 * var(--space-6)); margin-bottom: calc(-1 * var(--space-6)); }
    .md-mln6 { margin-left: calc(-1 * var(--space-6)); }
    .md-mrn6 { margin-right: calc(-1 * var(--space-6)); }
    .md-mtn6 { margin-top: calc(-1 * var(--space-6)); }
    .md-mbn6 { margin-bottom: calc(-1 * var(--space-6)); }


    .md-p7   { padding: var(--space-7); }
    .md-pt7  { padding-top: var(--space-7); }
    .md-pb7  { padding-bottom: var(--space-7); }
    .md-pl7  { padding-left: var(--space-7); }
    .md-pr7  { padding-right: var(--space-7); }
    .md-px7  { padding-left: var(--space-7); padding-right: var(--space-7); }
    .md-py7  { padding-top: var(--space-7); padding-bottom: var(--space-7); }

    .md-m7   { margin: var(--space-7); }
    .md-mt7  { margin-top: var(--space-7); }
    .md-mb7  { margin-bottom: var(--space-7); }
    .md-ml7  { margin-left: var(--space-7); }
    .md-mr7  { margin-right: var(--space-7); }
    .md-mn7  { margin: calc(-1 * var(--space-7)); }
    .md-mxn7 { margin-left: calc(-1 * var(--space-7)); margin-right: calc(-1 * var(--space-7)); }
    .md-myn7 { margin-top: calc(-1 * var(--space-7)); margin-bottom: calc(-1 * var(--space-7)); }
    .md-mln7 { margin-left: calc(-1 * var(--space-7)); }
    .md-mrn7 { margin-right: calc(-1 * var(--space-7)); }
    .md-mtn7 { margin-top: calc(-1 * var(--space-7)); }
    .md-mbn7 { margin-bottom: calc(-1 * var(--space-7)); }


    .md-p8   { padding: var(--space-8); }
    .md-pt8  { padding-top: var(--space-8); }
    .md-pb8  { padding-bottom: var(--space-8); }
    .md-pl8  { padding-left: var(--space-8); }
    .md-pr8  { padding-right: var(--space-8); }
    .md-px8  { padding-left: var(--space-8); padding-right: var(--space-8); }
    .md-py8  { padding-top: var(--space-8); padding-bottom: var(--space-8); }

    .md-m8   { margin: var(--space-8); }
    .md-mt8  { margin-top: var(--space-8); }
    .md-mb8  { margin-bottom: var(--space-8); }
    .md-ml8  { margin-left: var(--space-8); }
    .md-mr8  { margin-right: var(--space-8); }
    .md-mn8  { margin: calc(-1 * var(--space-8)); }
    .md-mxn8 { margin-left: calc(-1 * var(--space-8)); margin-right: calc(-1 * var(--space-8)); }
    .md-myn8 { margin-top: calc(-1 * var(--space-8)); margin-bottom: calc(-1 * var(--space-8)); }
    .md-mln8 { margin-left: calc(-1 * var(--space-8)); }
    .md-mrn8 { margin-right: calc(-1 * var(--space-8)); }
    .md-mtn8 { margin-top: calc(-1 * var(--space-8)); }
    .md-mbn8 { margin-bottom: calc(-1 * var(--space-8)); }


    .md-p9   { padding: var(--space-9); }
    .md-pt9  { padding-top: var(--space-9); }
    .md-pb9  { padding-bottom: var(--space-9); }
    .md-pl9  { padding-left: var(--space-9); }
    .md-pr9  { padding-right: var(--space-9); }
    .md-px9  { padding-left: var(--space-9); padding-right: var(--space-9); }
    .md-py9  { padding-top: var(--space-9); padding-bottom: var(--space-9); }

    .md-m9   { margin: var(--space-9); }
    .md-mt9  { margin-top: var(--space-9); }
    .md-mb9  { margin-bottom: var(--space-9); }
    .md-ml9  { margin-left: var(--space-9); }
    .md-mr9  { margin-right: var(--space-9); }
    .md-mn9  { margin: calc(-1 * var(--space-9)); }
    .md-mxn9 { margin-left: calc(-1 * var(--space-9)); margin-right: calc(-1 * var(--space-9)); }
    .md-myn9 { margin-top: calc(-1 * var(--space-9)); margin-bottom: calc(-1 * var(--space-9)); }
    .md-mln9 { margin-left: calc(-1 * var(--space-9)); }
    .md-mrn9 { margin-right: calc(-1 * var(--space-9)); }
    .md-mtn9 { margin-top: calc(-1 * var(--space-9)); }
    .md-mbn9 { margin-bottom: calc(-1 * var(--space-9)); }


    .md-p10   { padding: var(--space-10); }
    .md-pt10  { padding-top: var(--space-10); }
    .md-pb10  { padding-bottom: var(--space-10); }
    .md-pl10  { padding-left: var(--space-10); }
    .md-pr10  { padding-right: var(--space-10); }
    .md-px10  { padding-left: var(--space-10); padding-right: var(--space-10); }
    .md-py10  { padding-top: var(--space-10); padding-bottom: var(--space-10); }

    .md-m10   { margin: var(--space-10); }
    .md-mt10  { margin-top: var(--space-10); }
    .md-mb10  { margin-bottom: var(--space-10); }
    .md-ml10  { margin-left: var(--space-10); }
    .md-mr10  { margin-right: var(--space-10); }
    .md-mn10  { margin: calc(-1 * var(--space-10)); }
    .md-mxn10 { margin-left: calc(-1 * var(--space-10)); margin-right: calc(-1 * var(--space-10)); }
    .md-myn10 { margin-top: calc(-1 * var(--space-10)); margin-bottom: calc(-1 * var(--space-10)); }
    .md-mln10 { margin-left: calc(-1 * var(--space-10)); }
    .md-mrn10 { margin-right: calc(-1 * var(--space-10)); }
    .md-mtn10 { margin-top: calc(-1 * var(--space-10)); }
    .md-mbn10 { margin-bottom: calc(-1 * var(--space-10)); }


    .md-p11   { padding: var(--space-11); }
    .md-pt11  { padding-top: var(--space-11); }
    .md-pb11  { padding-bottom: var(--space-11); }
    .md-pl11  { padding-left: var(--space-11); }
    .md-pr11  { padding-right: var(--space-11); }
    .md-px11  { padding-left: var(--space-11); padding-right: var(--space-11); }
    .md-py11  { padding-top: var(--space-11); padding-bottom: var(--space-11); }

    .md-m11   { margin: var(--space-11); }
    .md-mt11  { margin-top: var(--space-11); }
    .md-mb11  { margin-bottom: var(--space-11); }
    .md-ml11  { margin-left: var(--space-11); }
    .md-mr11  { margin-right: var(--space-11); }
    .md-mn11  { margin: calc(-1 * var(--space-11)); }
    .md-mxn11 { margin-left: calc(-1 * var(--space-11)); margin-right: calc(-1 * var(--space-11)); }
    .md-myn11 { margin-top: calc(-1 * var(--space-11)); margin-bottom: calc(-1 * var(--space-11)); }
    .md-mln11 { margin-left: calc(-1 * var(--space-11)); }
    .md-mrn11 { margin-right: calc(-1 * var(--space-11)); }
    .md-mtn11 { margin-top: calc(-1 * var(--space-11)); }
    .md-mbn11 { margin-bottom: calc(-1 * var(--space-11)); }


    .md-p12   { padding: var(--space-12); }
    .md-pt12  { padding-top: var(--space-12); }
    .md-pb12  { padding-bottom: var(--space-12); }
    .md-pl12  { padding-left: var(--space-12); }
    .md-pr12  { padding-right: var(--space-12); }
    .md-px12  { padding-left: var(--space-12); padding-right: var(--space-12); }
    .md-py12  { padding-top: var(--space-12); padding-bottom: var(--space-12); }

    .md-m12   { margin: var(--space-12); }
    .md-mt12  { margin-top: var(--space-12); }
    .md-mb12  { margin-bottom: var(--space-12); }
    .md-ml12  { margin-left: var(--space-12); }
    .md-mr12  { margin-right: var(--space-12); }
    .md-mn12  { margin: calc(-1 * var(--space-12)); }
    .md-mxn12 { margin-left: calc(-1 * var(--space-12)); margin-right: calc(-1 * var(--space-12)); }
    .md-myn12 { margin-top: calc(-1 * var(--space-12)); margin-bottom: calc(-1 * var(--space-12)); }
    .md-mln12 { margin-left: calc(-1 * var(--space-12)); }
    .md-mrn12 { margin-right: calc(-1 * var(--space-12)); }
    .md-mtn12 { margin-top: calc(-1 * var(--space-12)); }
    .md-mbn12 { margin-bottom: calc(-1 * var(--space-12)); }


    .md-p13   { padding: var(--space-13); }
    .md-pt13  { padding-top: var(--space-13); }
    .md-pb13  { padding-bottom: var(--space-13); }
    .md-pl13  { padding-left: var(--space-13); }
    .md-pr13  { padding-right: var(--space-13); }
    .md-px13  { padding-left: var(--space-13); padding-right: var(--space-13); }
    .md-py13  { padding-top: var(--space-13); padding-bottom: var(--space-13); }

    .md-m13   { margin: var(--space-13); }
    .md-mt13  { margin-top: var(--space-13); }
    .md-mb13  { margin-bottom: var(--space-13); }
    .md-ml13  { margin-left: var(--space-13); }
    .md-mr13  { margin-right: var(--space-13); }
    .md-mn13  { margin: calc(-1 * var(--space-13)); }
    .md-mxn13 { margin-left: calc(-1 * var(--space-13)); margin-right: calc(-1 * var(--space-13)); }
    .md-myn13 { margin-top: calc(-1 * var(--space-13)); margin-bottom: calc(-1 * var(--space-13)); }
    .md-mln13 { margin-left: calc(-1 * var(--space-13)); }
    .md-mrn13 { margin-right: calc(-1 * var(--space-13)); }
    .md-mtn13 { margin-top: calc(-1 * var(--space-13)); }
    .md-mbn13 { margin-bottom: calc(-1 * var(--space-13)); }


    .md-p14   { padding: var(--space-14); }
    .md-pt14  { padding-top: var(--space-14); }
    .md-pb14  { padding-bottom: var(--space-14); }
    .md-pl14  { padding-left: var(--space-14); }
    .md-pr14  { padding-right: var(--space-14); }
    .md-px14  { padding-left: var(--space-14); padding-right: var(--space-14); }
    .md-py14  { padding-top: var(--space-14); padding-bottom: var(--space-14); }

    .md-m14   { margin: var(--space-14); }
    .md-mt14  { margin-top: var(--space-14); }
    .md-mb14  { margin-bottom: var(--space-14); }
    .md-ml14  { margin-left: var(--space-14); }
    .md-mr14  { margin-right: var(--space-14); }
    .md-mn14  { margin: calc(-1 * var(--space-14)); }
    .md-mxn14 { margin-left: calc(-1 * var(--space-14)); margin-right: calc(-1 * var(--space-14)); }
    .md-myn14 { margin-top: calc(-1 * var(--space-14)); margin-bottom: calc(-1 * var(--space-14)); }
    .md-mln14 { margin-left: calc(-1 * var(--space-14)); }
    .md-mrn14 { margin-right: calc(-1 * var(--space-14)); }
    .md-mtn14 { margin-top: calc(-1 * var(--space-14)); }
    .md-mbn14 { margin-bottom: calc(-1 * var(--space-14)); }


    .md-p15   { padding: var(--space-15); }
    .md-pt15  { padding-top: var(--space-15); }
    .md-pb15  { padding-bottom: var(--space-15); }
    .md-pl15  { padding-left: var(--space-15); }
    .md-pr15  { padding-right: var(--space-15); }
    .md-px15  { padding-left: var(--space-15); padding-right: var(--space-15); }
    .md-py15  { padding-top: var(--space-15); padding-bottom: var(--space-15); }

    .md-m15   { margin: var(--space-15); }
    .md-mt15  { margin-top: var(--space-15); }
    .md-mb15  { margin-bottom: var(--space-15); }
    .md-ml15  { margin-left: var(--space-15); }
    .md-mr15  { margin-right: var(--space-15); }
    .md-mn15  { margin: calc(-1 * var(--space-15)); }
    .md-mxn15 { margin-left: calc(-1 * var(--space-15)); margin-right: calc(-1 * var(--space-15)); }
    .md-myn15 { margin-top: calc(-1 * var(--space-15)); margin-bottom: calc(-1 * var(--space-15)); }
    .md-mln15 { margin-left: calc(-1 * var(--space-15)); }
    .md-mrn15 { margin-right: calc(-1 * var(--space-15)); }
    .md-mtn15 { margin-top: calc(-1 * var(--space-15)); }
    .md-mbn15 { margin-bottom: calc(-1 * var(--space-15)); }


    .md-p16   { padding: var(--space-16); }
    .md-pt16  { padding-top: var(--space-16); }
    .md-pb16  { padding-bottom: var(--space-16); }
    .md-pl16  { padding-left: var(--space-16); }
    .md-pr16  { padding-right: var(--space-16); }
    .md-px16  { padding-left: var(--space-16); padding-right: var(--space-16); }
    .md-py16  { padding-top: var(--space-16); padding-bottom: var(--space-16); }

    .md-m16   { margin: var(--space-16); }
    .md-mt16  { margin-top: var(--space-16); }
    .md-mb16  { margin-bottom: var(--space-16); }
    .md-ml16  { margin-left: var(--space-16); }
    .md-mr16  { margin-right: var(--space-16); }
    .md-mn16  { margin: calc(-1 * var(--space-16)); }
    .md-mxn16 { margin-left: calc(-1 * var(--space-16)); margin-right: calc(-1 * var(--space-16)); }
    .md-myn16 { margin-top: calc(-1 * var(--space-16)); margin-bottom: calc(-1 * var(--space-16)); }
    .md-mln16 { margin-left: calc(-1 * var(--space-16)); }
    .md-mrn16 { margin-right: calc(-1 * var(--space-16)); }
    .md-mtn16 { margin-top: calc(-1 * var(--space-16)); }
    .md-mbn16 { margin-bottom: calc(-1 * var(--space-16)); }


    .md-mr-auto { margin-right: auto; }
    .md-ml-auto { margin-left: auto; }

}


@media (--breakpoint-lg) {

    .lg-p0   { padding:0; }
    .lg-pt0  { padding-top:0; }
    .lg-pb0  { padding-bottom:0; }
    .lg-pl0  { padding-left:0; }
    .lg-pr0  { padding-right:0; }
    .lg-px0  { padding-left:0; padding-right:0; }
    .lg-py0  { padding-top:0; padding-bottom:0; }

    .lg-m0   { margin:0; }
    .lg-mt0  { margin-top: 0 }
    .lg-my0  { margin-top: 0; margin-bottom: 0; }
    .lg-mt0  { margin-top:0; }
    .lg-mb0  { margin-bottom:0; }
    .lg-ml0  { margin-left:0; }
    .lg-mr0  { margin-right:0; }
    .lg-mn0  { margin: 0; }
    .lg-mxn0 { margin-left: 0; margin-right: 0; }
    .lg-myn0 { margin-top: 0; margin-bottom: 0; }
    .lg-mln0 { margin-left: 0; }
    .lg-mrn0 { margin-right: 0; }
    .lg-mtn0 { margin-top: 0; }
    .lg-mbn0 { margin-bottom: 0; }


    .lg-p1   { padding: var(--space-1); }
    .lg-pt1  { padding-top: var(--space-1); }
    .lg-pb1  { padding-bottom: var(--space-1); }
    .lg-pl1  { padding-left: var(--space-1); }
    .lg-pr1  { padding-right: var(--space-1); }
    .lg-px1  { padding-left: var(--space-1); padding-right: var(--space-1); }
    .lg-py1  { padding-top: var(--space-1); padding-bottom: var(--space-1); }

    .lg-m1   { margin: var(--space-1); }
    .lg-mt1  { margin-top: var(--space-1); }
    .lg-mb1  { margin-bottom: var(--space-1); }
    .lg-ml1  { margin-left: var(--space-1); }
    .lg-mr1  { margin-right: var(--space-1); }
    .lg-mn1  { margin: calc(-1 * var(--space-1)); }
    .lg-mxn1 { margin-left: calc(-1 * var(--space-1)); margin-right: calc(-1 * var(--space-1)); }
    .lg-myn1 { margin-top: calc(-1 * var(--space-1)); margin-bottom: calc(-1 * var(--space-1)); }
    .lg-mln1 { margin-left: calc(-1 * var(--space-1)); }
    .lg-mrn1 { margin-right: calc(-1 * var(--space-1)); }
    .lg-mtn1 { margin-top: calc(-1 * var(--space-1)); }
    .lg-mbn1 { margin-bottom: calc(-1 * var(--space-1)); }


    .lg-p2   { padding: var(--space-2); }
    .lg-pt2  { padding-top: var(--space-2); }
    .lg-pb2  { padding-bottom: var(--space-2); }
    .lg-pl2  { padding-left: var(--space-2); }
    .lg-pr2  { padding-right: var(--space-2); }
    .lg-px2  { padding-left: var(--space-2); padding-right: var(--space-2); }
    .lg-py2  { padding-top: var(--space-2); padding-bottom: var(--space-2); }

    .lg-m2   { margin: var(--space-2); }
    .lg-mt2  { margin-top: var(--space-2); }
    .lg-mb2  { margin-bottom: var(--space-2); }
    .lg-my2  { margin-top: var(--space-2); margin-bottom: var(--space-2); }
    .lg-ml2  { margin-left: var(--space-2); }
    .lg-mr2  { margin-right: var(--space-2); }
    .lg-mn2  { margin: calc(-1 * var(--space-2)); }
    .lg-mxn2 { margin-left: calc(-1 * var(--space-2)); margin-right: calc(-1 * var(--space-2)); }
    .lg-myn2 { margin-top: calc(-1 * var(--space-2)); margin-bottom: calc(-1 * var(--space-2)); }
    .lg-mln2 { margin-left: calc(-1 * var(--space-2)); }
    .lg-mrn2 { margin-right: calc(-1 * var(--space-2)); }
    .lg-mtn2 { margin-top: calc(-1 * var(--space-2)); }
    .lg-mbn2 { margin-bottom: calc(-1 * var(--space-2)); }


    .lg-p3   { padding: var(--space-3); }
    .lg-pt3  { padding-top: var(--space-3); }
    .lg-pb3  { padding-bottom: var(--space-3); }
    .lg-pl3  { padding-left: var(--space-3); }
    .lg-pr3  { padding-right: var(--space-3); }
    .lg-px3  { padding-left: var(--space-3); padding-right: var(--space-3); }
    .lg-py3  { padding-top: var(--space-3); padding-bottom: var(--space-3); }

    .lg-m3   { margin: var(--space-3); }
    .lg-mt3  { margin-top: var(--space-3); }
    .lg-mb3  { margin-bottom: var(--space-3); }
    .lg-ml3  { margin-left: var(--space-3); }
    .lg-mr3  { margin-right: var(--space-3); }
    .lg-mn3  { margin: calc(-1 * var(--space-3)); }
    .lg-mxn3 { margin-left: calc(-1 * var(--space-3)); margin-right: calc(-1 * var(--space-3)); }
    .lg-myn3 { margin-top: calc(-1 * var(--space-3)); margin-bottom: calc(-1 * var(--space-3)); }
    .lg-mln3 { margin-left: calc(-1 * var(--space-3)); }
    .lg-mrn3 { margin-right: calc(-1 * var(--space-3)); }
    .lg-mtn3 { margin-top: calc(-1 * var(--space-3)); }
    .lg-mbn3 { margin-bottom: calc(-1 * var(--space-3)); }


    .lg-p4   { padding: var(--space-4); }
    .lg-pt4  { padding-top: var(--space-4); }
    .lg-pb4  { padding-bottom: var(--space-4); }
    .lg-pl4  { padding-left: var(--space-4); }
    .lg-pr4  { padding-right: var(--space-4); }
    .lg-px4  { padding-left: var(--space-4); padding-right: var(--space-4); }
    .lg-py4  { padding-top: var(--space-4); padding-bottom: var(--space-4); }

    .lg-m4   { margin: var(--space-4); }
    .lg-mt4  { margin-top: var(--space-4); }
    .lg-mb4  { margin-bottom: var(--space-4); }
    .lg-ml4  { margin-left: var(--space-4); }
    .lg-mr4  { margin-right: var(--space-4); }
    .lg-mn4  { margin: calc(-1 * var(--space-4)); }
    .lg-mxn4 { margin-left: calc(-1 * var(--space-4)); margin-right: calc(-1 * var(--space-4)); }
    .lg-myn4 { margin-top: calc(-1 * var(--space-4)); margin-bottom: calc(-1 * var(--space-4)); }
    .lg-mln4 { margin-left: calc(-1 * var(--space-4)); }
    .lg-mrn4 { margin-right: calc(-1 * var(--space-4)); }
    .lg-mtn4 { margin-top: calc(-1 * var(--space-4)); }
    .lg-mbn4 { margin-bottom: calc(-1 * var(--space-4)); }


    .lg-p5   { padding: var(--space-5); }
    .lg-pt5  { padding-top: var(--space-5); }
    .lg-pb5  { padding-bottom: var(--space-5); }
    .lg-pl5  { padding-left: var(--space-5); }
    .lg-pr5  { padding-right: var(--space-5); }
    .lg-px5  { padding-left: var(--space-5); padding-right: var(--space-5); }
    .lg-py5  { padding-top: var(--space-5); padding-bottom: var(--space-5); }

    .lg-m5   { margin: var(--space-5); }
    .lg-mt5  { margin-top: var(--space-5); }
    .lg-mb5  { margin-bottom: var(--space-5); }
    .lg-ml5  { margin-left: var(--space-5); }
    .lg-mr5  { margin-right: var(--space-5); }
    .lg-mn5  { margin: calc(-1 * var(--space-5)); }
    .lg-mxn5 { margin-left: calc(-1 * var(--space-5)); margin-right: calc(-1 * var(--space-5)); }
    .lg-myn5 { margin-top: calc(-1 * var(--space-5)); margin-bottom: calc(-1 * var(--space-5)); }
    .lg-mln5 { margin-left: calc(-1 * var(--space-5)); }
    .lg-mrn5 { margin-right: calc(-1 * var(--space-5)); }
    .lg-mtn5 { margin-top: calc(-1 * var(--space-5)); }
    .lg-mbn5 { margin-bottom: calc(-1 * var(--space-5)); }


    .lg-p6   { padding: var(--space-6); }
    .lg-pt6  { padding-top: var(--space-6); }
    .lg-pb6  { padding-bottom: var(--space-6); }
    .lg-pl6  { padding-left: var(--space-6); }
    .lg-pr6  { padding-right: var(--space-6); }
    .lg-px6  { padding-left: var(--space-6); padding-right: var(--space-6); }
    .lg-py6  { padding-top: var(--space-6); padding-bottom: var(--space-6); }

    .lg-m6   { margin: var(--space-6); }
    .lg-mt6  { margin-top: var(--space-6); }
    .lg-mb6  { margin-bottom: var(--space-6); }
    .lg-ml6  { margin-left: var(--space-6); }
    .lg-mr6  { margin-right: var(--space-6); }
    .lg-mn6  { margin: calc(-1 * var(--space-6)); }
    .lg-mxn6 { margin-left: calc(-1 * var(--space-6)); margin-right: calc(-1 * var(--space-6)); }
    .lg-myn6 { margin-top: calc(-1 * var(--space-6)); margin-bottom: calc(-1 * var(--space-6)); }
    .lg-mln6 { margin-left: calc(-1 * var(--space-6)); }
    .lg-mrn6 { margin-right: calc(-1 * var(--space-6)); }
    .lg-mtn6 { margin-top: calc(-1 * var(--space-6)); }
    .lg-mbn6 { margin-bottom: calc(-1 * var(--space-6)); }


    .lg-p7   { padding: var(--space-7); }
    .lg-pt7  { padding-top: var(--space-7); }
    .lg-pb7  { padding-bottom: var(--space-7); }
    .lg-pl7  { padding-left: var(--space-7); }
    .lg-pr7  { padding-right: var(--space-7); }
    .lg-px7  { padding-left: var(--space-7); padding-right: var(--space-7); }
    .lg-py7  { padding-top: var(--space-7); padding-bottom: var(--space-7); }

    .lg-m7   { margin: var(--space-7); }
    .lg-mt7  { margin-top: var(--space-7); }
    .lg-mb7  { margin-bottom: var(--space-7); }
    .lg-ml7  { margin-left: var(--space-7); }
    .lg-mr7  { margin-right: var(--space-7); }
    .lg-mn7  { margin: calc(-1 * var(--space-7)); }
    .lg-mxn7 { margin-left: calc(-1 * var(--space-7)); margin-right: calc(-1 * var(--space-7)); }
    .lg-myn7 { margin-top: calc(-1 * var(--space-7)); margin-bottom: calc(-1 * var(--space-7)); }
    .lg-mln7 { margin-left: calc(-1 * var(--space-7)); }
    .lg-mrn7 { margin-right: calc(-1 * var(--space-7)); }
    .lg-mtn7 { margin-top: calc(-1 * var(--space-7)); }
    .lg-mbn7 { margin-bottom: calc(-1 * var(--space-7)); }


    .lg-p8   { padding: var(--space-8); }
    .lg-pt8  { padding-top: var(--space-8); }
    .lg-pb8  { padding-bottom: var(--space-8); }
    .lg-pl8  { padding-left: var(--space-8); }
    .lg-pr8  { padding-right: var(--space-8); }
    .lg-px8  { padding-left: var(--space-8); padding-right: var(--space-8); }
    .lg-py8  { padding-top: var(--space-8); padding-bottom: var(--space-8); }

    .lg-m8   { margin: var(--space-8); }
    .lg-mt8  { margin-top: var(--space-8); }
    .lg-mb8  { margin-bottom: var(--space-8); }
    .lg-ml8  { margin-left: var(--space-8); }
    .lg-mr8  { margin-right: var(--space-8); }
    .lg-mn8  { margin: calc(-1 * var(--space-8)); }
    .lg-mxn8 { margin-left: calc(-1 * var(--space-8)); margin-right: calc(-1 * var(--space-8)); }
    .lg-myn8 { margin-top: calc(-1 * var(--space-8)); margin-bottom: calc(-1 * var(--space-8)); }
    .lg-mln8 { margin-left: calc(-1 * var(--space-8)); }
    .lg-mrn8 { margin-right: calc(-1 * var(--space-8)); }
    .lg-mtn8 { margin-top: calc(-1 * var(--space-8)); }
    .lg-mbn8 { margin-bottom: calc(-1 * var(--space-8)); }


    .lg-p9   { padding: var(--space-9); }
    .lg-pt9  { padding-top: var(--space-9); }
    .lg-pb9  { padding-bottom: var(--space-9); }
    .lg-pl9  { padding-left: var(--space-9); }
    .lg-pr9  { padding-right: var(--space-9); }
    .lg-px9  { padding-left: var(--space-9); padding-right: var(--space-9); }
    .lg-py9  { padding-top: var(--space-9); padding-bottom: var(--space-9); }

    .lg-m9   { margin: var(--space-9); }
    .lg-mt9  { margin-top: var(--space-9); }
    .lg-mb9  { margin-bottom: var(--space-9); }
    .lg-ml9  { margin-left: var(--space-9); }
    .lg-mr9  { margin-right: var(--space-9); }
    .lg-mn9  { margin: calc(-1 * var(--space-9)); }
    .lg-mxn9 { margin-left: calc(-1 * var(--space-9)); margin-right: calc(-1 * var(--space-9)); }
    .lg-myn9 { margin-top: calc(-1 * var(--space-9)); margin-bottom: calc(-1 * var(--space-9)); }
    .lg-mln9 { margin-left: calc(-1 * var(--space-9)); }
    .lg-mrn9 { margin-right: calc(-1 * var(--space-9)); }
    .lg-mtn9 { margin-top: calc(-1 * var(--space-9)); }
    .lg-mbn9 { margin-bottom: calc(-1 * var(--space-9)); }


    .lg-p10   { padding: var(--space-10); }
    .lg-pt10  { padding-top: var(--space-10); }
    .lg-pb10  { padding-bottom: var(--space-10); }
    .lg-pl10  { padding-left: var(--space-10); }
    .lg-pr10  { padding-right: var(--space-10); }
    .lg-px10  { padding-left: var(--space-10); padding-right: var(--space-10); }
    .lg-py10  { padding-top: var(--space-10); padding-bottom: var(--space-10); }

    .lg-m10   { margin: var(--space-10); }
    .lg-mt10  { margin-top: var(--space-10); }
    .lg-mb10  { margin-bottom: var(--space-10); }
    .lg-ml10  { margin-left: var(--space-10); }
    .lg-mr10  { margin-right: var(--space-10); }
    .lg-mn10  { margin: calc(-1 * var(--space-10)); }
    .lg-mxn10 { margin-left: calc(-1 * var(--space-10)); margin-right: calc(-1 * var(--space-10)); }
    .lg-myn10 { margin-top: calc(-1 * var(--space-10)); margin-bottom: calc(-1 * var(--space-10)); }
    .lg-mln10 { margin-left: calc(-1 * var(--space-10)); }
    .lg-mrn10 { margin-right: calc(-1 * var(--space-10)); }
    .lg-mtn10 { margin-top: calc(-1 * var(--space-10)); }
    .lg-mbn10 { margin-bottom: calc(-1 * var(--space-10)); }


    .lg-p11   { padding: var(--space-11); }
    .lg-pt11  { padding-top: var(--space-11); }
    .lg-pb11  { padding-bottom: var(--space-11); }
    .lg-pl11  { padding-left: var(--space-11); }
    .lg-pr11  { padding-right: var(--space-11); }
    .lg-px11  { padding-left: var(--space-11); padding-right: var(--space-11); }
    .lg-py11  { padding-top: var(--space-11); padding-bottom: var(--space-11); }

    .lg-m11   { margin: var(--space-11); }
    .lg-mt11  { margin-top: var(--space-11); }
    .lg-mb11  { margin-bottom: var(--space-11); }
    .lg-ml11  { margin-left: var(--space-11); }
    .lg-mr11  { margin-right: var(--space-11); }
    .lg-mn11  { margin: calc(-1 * var(--space-11)); }
    .lg-mxn11 { margin-left: calc(-1 * var(--space-11)); margin-right: calc(-1 * var(--space-11)); }
    .lg-myn11 { margin-top: calc(-1 * var(--space-11)); margin-bottom: calc(-1 * var(--space-11)); }
    .lg-mln11 { margin-left: calc(-1 * var(--space-11)); }
    .lg-mrn11 { margin-right: calc(-1 * var(--space-11)); }
    .lg-mtn11 { margin-top: calc(-1 * var(--space-11)); }
    .lg-mbn11 { margin-bottom: calc(-1 * var(--space-11)); }


    .lg-p12   { padding: var(--space-12); }
    .lg-pt12  { padding-top: var(--space-12); }
    .lg-pb12  { padding-bottom: var(--space-12); }
    .lg-pl12  { padding-left: var(--space-12); }
    .lg-pr12  { padding-right: var(--space-12); }
    .lg-px12  { padding-left: var(--space-12); padding-right: var(--space-12); }
    .lg-py12  { padding-top: var(--space-12); padding-bottom: var(--space-12); }

    .lg-m12   { margin: var(--space-12); }
    .lg-mt12  { margin-top: var(--space-12); }
    .lg-mb12  { margin-bottom: var(--space-12); }
    .lg-ml12  { margin-left: var(--space-12); }
    .lg-mr12  { margin-right: var(--space-12); }
    .lg-mn12  { margin: calc(-1 * var(--space-12)); }
    .lg-mxn12 { margin-left: calc(-1 * var(--space-12)); margin-right: calc(-1 * var(--space-12)); }
    .lg-myn12 { margin-top: calc(-1 * var(--space-12)); margin-bottom: calc(-1 * var(--space-12)); }
    .lg-mln12 { margin-left: calc(-1 * var(--space-12)); }
    .lg-mrn12 { margin-right: calc(-1 * var(--space-12)); }
    .lg-mtn12 { margin-top: calc(-1 * var(--space-12)); }
    .lg-mbn12 { margin-bottom: calc(-1 * var(--space-12)); }


    .lg-p13   { padding: var(--space-13); }
    .lg-pt13  { padding-top: var(--space-13); }
    .lg-pb13  { padding-bottom: var(--space-13); }
    .lg-pl13  { padding-left: var(--space-13); }
    .lg-pr13  { padding-right: var(--space-13); }
    .lg-px13  { padding-left: var(--space-13); padding-right: var(--space-13); }
    .lg-py13  { padding-top: var(--space-13); padding-bottom: var(--space-13); }

    .lg-m13   { margin: var(--space-13); }
    .lg-mt13  { margin-top: var(--space-13); }
    .lg-mb13  { margin-bottom: var(--space-13); }
    .lg-ml13  { margin-left: var(--space-13); }
    .lg-mr13  { margin-right: var(--space-13); }
    .lg-mn13  { margin: calc(-1 * var(--space-13)); }
    .lg-mxn13 { margin-left: calc(-1 * var(--space-13)); margin-right: calc(-1 * var(--space-13)); }
    .lg-myn13 { margin-top: calc(-1 * var(--space-13)); margin-bottom: calc(-1 * var(--space-13)); }
    .lg-mln13 { margin-left: calc(-1 * var(--space-13)); }
    .lg-mrn13 { margin-right: calc(-1 * var(--space-13)); }
    .lg-mtn13 { margin-top: calc(-1 * var(--space-13)); }
    .lg-mbn13 { margin-bottom: calc(-1 * var(--space-13)); }


    .lg-p14   { padding: var(--space-14); }
    .lg-pt14  { padding-top: var(--space-14); }
    .lg-pb14  { padding-bottom: var(--space-14); }
    .lg-pl14  { padding-left: var(--space-14); }
    .lg-pr14  { padding-right: var(--space-14); }
    .lg-px14  { padding-left: var(--space-14); padding-right: var(--space-14); }
    .lg-py14  { padding-top: var(--space-14); padding-bottom: var(--space-14); }

    .lg-m14   { margin: var(--space-14); }
    .lg-mt14  { margin-top: var(--space-14); }
    .lg-mb14  { margin-bottom: var(--space-14); }
    .lg-ml14  { margin-left: var(--space-14); }
    .lg-mr14  { margin-right: var(--space-14); }
    .lg-mn14  { margin: calc(-1 * var(--space-14)); }
    .lg-mxn14 { margin-left: calc(-1 * var(--space-14)); margin-right: calc(-1 * var(--space-14)); }
    .lg-myn14 { margin-top: calc(-1 * var(--space-14)); margin-bottom: calc(-1 * var(--space-14)); }
    .lg-mln14 { margin-left: calc(-1 * var(--space-14)); }
    .lg-mrn14 { margin-right: calc(-1 * var(--space-14)); }
    .lg-mtn14 { margin-top: calc(-1 * var(--space-14)); }
    .lg-mbn14 { margin-bottom: calc(-1 * var(--space-14)); }


    .lg-p15   { padding: var(--space-15); }
    .lg-pt15  { padding-top: var(--space-15); }
    .lg-pb15  { padding-bottom: var(--space-15); }
    .lg-pl15  { padding-left: var(--space-15); }
    .lg-pr15  { padding-right: var(--space-15); }
    .lg-px15  { padding-left: var(--space-15); padding-right: var(--space-15); }
    .lg-py15  { padding-top: var(--space-15); padding-bottom: var(--space-15); }

    .lg-m15   { margin: var(--space-15); }
    .lg-mt15  { margin-top: var(--space-15); }
    .lg-mb15  { margin-bottom: var(--space-15); }
    .lg-ml15  { margin-left: var(--space-15); }
    .lg-mr15  { margin-right: var(--space-15); }
    .lg-mn15  { margin: calc(-1 * var(--space-15)); }
    .lg-mxn15 { margin-left: calc(-1 * var(--space-15)); margin-right: calc(-1 * var(--space-15)); }
    .lg-myn15 { margin-top: calc(-1 * var(--space-15)); margin-bottom: calc(-1 * var(--space-15)); }
    .lg-mln15 { margin-left: calc(-1 * var(--space-15)); }
    .lg-mrn15 { margin-right: calc(-1 * var(--space-15)); }
    .lg-mtn15 { margin-top: calc(-1 * var(--space-15)); }
    .lg-mbn15 { margin-bottom: calc(-1 * var(--space-15)); }


    .lg-p16   { padding: var(--space-16); }
    .lg-pt16  { padding-top: var(--space-16); }
    .lg-pb16  { padding-bottom: var(--space-16); }
    .lg-pl16  { padding-left: var(--space-16); }
    .lg-pr16  { padding-right: var(--space-16); }
    .lg-px16  { padding-left: var(--space-16); padding-right: var(--space-16); }
    .lg-py16  { padding-top: var(--space-16); padding-bottom: var(--space-16); }

    .lg-m16   { margin: var(--space-16); }
    .lg-mt16  { margin-top: var(--space-16); }
    .lg-mb16  { margin-bottom: var(--space-16); }
    .lg-ml16  { margin-left: var(--space-16); }
    .lg-mr16  { margin-right: var(--space-16); }
    .lg-mn16  { margin: calc(-1 * var(--space-16)); }
    .lg-mxn16 { margin-left: calc(-1 * var(--space-16)); margin-right: calc(-1 * var(--space-16)); }
    .lg-myn16 { margin-top: calc(-1 * var(--space-16)); margin-bottom: calc(-1 * var(--space-16)); }
    .lg-mln16 { margin-left: calc(-1 * var(--space-16)); }
    .lg-mrn16 { margin-right: calc(-1 * var(--space-16)); }
    .lg-mtn16 { margin-top: calc(-1 * var(--space-16)); }
    .lg-mbn16 { margin-bottom: calc(-1 * var(--space-16)); }


    .lg-mr-auto { margin-right: auto; }
    .lg-ml-auto { margin-left: auto; }

}
