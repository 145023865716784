/*
 * Colours
 */

:root {
    --black: #000000;
    --dark-grey: #222222;
    --grey: #555555;
    --light-grey: #e8e8e8;
    --white: #ffffff;

    --blue: 'blue';
    --green: 'green';
    --orange: 'orange';
    --red: 'red';
    --yellow: 'yellow';
}

.black { color: var(--black); }
.blue { color: var(--blue); }
.dark-grey { color: var(--dark-grey); }
.green { color: var(--green); }
.grey { color: var(--grey); }
.light-grey { color: var(--light-grey); }
.orange { color: var(--orange); }
.red { color: var(--red); }
.white { color: var(--white); }
.yellow { color: var(--yellow); }

.bg-black { background-color: var(--black); }
.bg-blue { background-color: var(--blue); }
.bg-dark-grey { background-color: var(--dark-grey); }
.bg-green { background-color: var(--green); }
.bg-grey { background-color: var(--grey); }
.bg-light-grey { background-color: var(--light-grey); }
.bg-orange { background-color: var(--orange); }
.bg-red { background-color: var(--red); }
.bg-transparent { background-color: transparent; }
.bg-white { background-color: var(--white); }
.bg-yellow { background-color: var(--yellow); }

.border-black { border-color: var(--black); }
.border-blue { border-color: var(--blue); }
.border-dark-grey { border-color: var(--dark-grey); }
.border-green { border-color: var(--green); }
.border-grey { border-color: var(--grey); }
.border-light-grey { border-color: var(--light-grey); }
.border-orange { border-color: var(--orange); }
.border-red { border-color: var(--red); }
.border-white { border-color: var(--white); }
.border-yellow { border-color: var(--yellow); }

.muted { opacity: var(--muted); }

.opacity-0 { opacity: 0; }
.opacity-1 { opacity: 1; }
