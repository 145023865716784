/*
 * Type
 */


:root {

    /* Base */

    --type-style-1-family: sans-serif;
    --type-style-1-weight: inherit;
    --type-style-1-size: 1rem;
    --type-style-1-line-height: 1;
    --type-style-1-letter-spacing: 0;
    --type-style-1-text-transform: none;

    --type-style-2-family: sans-serif;
    --type-style-2-weight: inherit;
    --type-style-2-size: 1rem;
    --type-style-2-line-height: 1;
    --type-style-2-letter-spacing: 0;
    --type-style-2-text-transform: none;

    --type-style-3-family: sans-serif;
    --type-style-3-weight: inherit;
    --type-style-3-size: 1rem;
    --type-style-3-line-height: 1;
    --type-style-3-letter-spacing: 0;
    --type-style-3-text-transform: none;

    --type-style-4-family: sans-serif;
    --type-style-4-weight: inherit;
    --type-style-4-size: 1rem;
    --type-style-4-line-height: 1;
    --type-style-4-letter-spacing: 0;
    --type-style-4-text-transform: none;

    --type-style-5-family: sans-serif;
    --type-style-5-weight: inherit;
    --type-style-5-size: 1rem;
    --type-style-5-line-height: 1;
    --type-style-5-letter-spacing: 0;
    --type-style-5-text-transform: none;

    --type-style-6-family: sans-serif;
    --type-style-6-weight: inherit;
    --type-style-6-size: 1rem;
    --type-style-6-line-height: 1;
    --type-style-6-letter-spacing: 0;
    --type-style-6-text-transform: none;

    --type-style-7-family: sans-serif;
    --type-style-7-weight: inherit;
    --type-style-7-size: 1rem;
    --type-style-7-line-height: 1;
    --type-style-7-letter-spacing: 0;
    --type-style-7-text-transform: none;

    --type-style-8-family: sans-serif;
    --type-style-8-weight: inherit;
    --type-style-8-size: 1rem;
    --type-style-8-line-height: 1;
    --type-style-8-letter-spacing: 0;
    --type-style-8-text-transform: none;

    --type-style-9-family: sans-serif;
    --type-style-9-weight: inherit;
    --type-style-9-size: 1rem;
    --type-style-9-line-height: 1;
    --type-style-9-letter-spacing: 0;
    --type-style-9-text-transform: none;

    --type-style-10-family: sans-serif;
    --type-style-10-weight: inherit;
    --type-style-10-size: 1rem;
    --type-style-10-line-height: 1;
    --type-style-10-letter-spacing: 0;
    --type-style-10-text-transform: none;

    --type-style-11-family: sans-serif;
    --type-style-11-weight: inherit;
    --type-style-11-size: 1rem;
    --type-style-11-line-height: 1;
    --type-style-11-letter-spacing: 0;
    --type-style-11-text-transform: none;

    --type-style-12-family: sans-serif;
    --type-style-12-weight: inherit;
    --type-style-12-size: 1rem;
    --type-style-12-line-height: 1;
    --type-style-12-letter-spacing: 0;
    --type-style-12-text-transform: none;

    /* SM */

    --sm-type-style-1-family: var(--type-style-1-family);
    --sm-type-style-1-weight: var(--type-style-1-weight);
    --sm-type-style-1-size: var(--type-style-1-size);
    --sm-type-style-1-line-height: var(--type-style-1-line-height);
    --sm-type-style-1-letter-spacing: var(--type-style-1-letter-spacing);
    --sm-type-style-1-text-transform: var(--type-style-1-text-transform);

    --sm-type-style-2-family: var(--type-style-2-family);
    --sm-type-style-2-weight: var(--type-style-2-weight);
    --sm-type-style-2-size: var(--type-style-2-size);
    --sm-type-style-2-line-height: var(--type-style-2-line-height);
    --sm-type-style-2-letter-spacing: var(--type-style-2-letter-spacing);
    --sm-type-style-2-text-transform: var(--type-style-2-text-transform);

    --sm-type-style-3-family: var(--type-style-3-family);
    --sm-type-style-3-weight: var(--type-style-3-weight);
    --sm-type-style-3-size: var(--type-style-3-size);
    --sm-type-style-3-line-height: var(--type-style-3-line-height);
    --sm-type-style-3-letter-spacing: var(--type-style-3-letter-spacing);
    --sm-type-style-3-text-transform: var(--type-style-3-text-transform);

    --sm-type-style-4-family: var(--type-style-4-family);
    --sm-type-style-4-weight: var(--type-style-4-weight);
    --sm-type-style-4-size: var(--type-style-4-size);
    --sm-type-style-4-line-height: var(--type-style-4-line-height);
    --sm-type-style-4-letter-spacing: var(--type-style-4-letter-spacing);
    --sm-type-style-4-text-transform: var(--type-style-4-text-transform);

    --sm-type-style-5-family: var(--type-style-5-family);
    --sm-type-style-5-weight: var(--type-style-5-weight);
    --sm-type-style-5-size: var(--type-style-5-size);
    --sm-type-style-5-line-height: var(--type-style-5-line-height);
    --sm-type-style-5-letter-spacing: var(--type-style-5-letter-spacing);
    --sm-type-style-5-text-transform: var(--type-style-5-text-transform);

    --sm-type-style-6-family: var(--type-style-6-family);
    --sm-type-style-6-weight: var(--type-style-6-weight);
    --sm-type-style-6-size: var(--type-style-6-size);
    --sm-type-style-6-line-height: var(--type-style-6-line-height);
    --sm-type-style-6-letter-spacing: var(--type-style-6-letter-spacing);
    --sm-type-style-6-text-transform: var(--type-style-6-text-transform);

    --sm-type-style-7-family: var(--type-style-7-family);
    --sm-type-style-7-weight: var(--type-style-7-weight);
    --sm-type-style-7-size: var(--type-style-7-size);
    --sm-type-style-7-line-height: var(--type-style-7-line-height);
    --sm-type-style-7-letter-spacing: var(--type-style-7-letter-spacing);
    --sm-type-style-7-text-transform: var(--type-style-7-text-transform);

    --sm-type-style-8-family: var(--type-style-8-family);
    --sm-type-style-8-weight: var(--type-style-8-weight);
    --sm-type-style-8-size: var(--type-style-8-size);
    --sm-type-style-8-line-height: var(--type-style-8-line-height);
    --sm-type-style-8-letter-spacing: var(--type-style-8-letter-spacing);
    --sm-type-style-8-text-transform: var(--type-style-8-text-transform);

    --sm-type-style-9-family: var(--type-style-9-family);
    --sm-type-style-9-weight: var(--type-style-9-weight);
    --sm-type-style-9-size: var(--type-style-9-size);
    --sm-type-style-9-line-height: var(--type-style-9-line-height);
    --sm-type-style-9-letter-spacing: var(--type-style-9-letter-spacing);
    --sm-type-style-9-text-transform: var(--type-style-9-text-transform);

    --sm-type-style-10-family: var(--type-style-10-family);
    --sm-type-style-10-weight: var(--type-style-10-weight);
    --sm-type-style-10-size: var(--type-style-10-size);
    --sm-type-style-10-line-height: var(--type-style-10-line-height);
    --sm-type-style-10-letter-spacing: var(--type-style-10-letter-spacing);
    --sm-type-style-10-text-transform: var(--type-style-10-text-transform);

    --sm-type-style-11-family: var(--type-style-11-family);
    --sm-type-style-11-weight: var(--type-style-11-weight);
    --sm-type-style-11-size: var(--type-style-11-size);
    --sm-type-style-11-line-height: var(--type-style-11-line-height);
    --sm-type-style-11-letter-spacing: var(--type-style-11-letter-spacing);
    --sm-type-style-11-text-transform: var(--type-style-11-text-transform);

    --sm-type-style-12-family: var(--type-style-12-family);
    --sm-type-style-12-weight: var(--type-style-12-weight);
    --sm-type-style-12-size: var(--type-style-12-size);
    --sm-type-style-12-line-height: var(--type-style-12-line-height);
    --sm-type-style-12-letter-spacing: var(--type-style-12-letter-spacing);
    --sm-type-style-12-text-transform: var(--type-style-12-text-transform);

    /* MD */

    --md-type-style-1-family: var(--sm-type-style-1-family);
    --md-type-style-1-weight: var(--sm-type-style-1-weight);
    --md-type-style-1-size: var(--sm-type-style-1-size);
    --md-type-style-1-line-height: var(--sm-type-style-1-line-height);
    --md-type-style-1-letter-spacing: var(--sm-type-style-1-letter-spacing);
    --md-type-style-1-text-transform: var(--sm-type-style-1-text-transform);

    --md-type-style-2-family: var(--sm-type-style-2-family);
    --md-type-style-2-weight: var(--sm-type-style-2-weight);
    --md-type-style-2-size: var(--sm-type-style-2-size);
    --md-type-style-2-line-height: var(--sm-type-style-2-line-height);
    --md-type-style-2-letter-spacing: var(--sm-type-style-2-letter-spacing);
    --md-type-style-2-text-transform: var(--sm-type-style-2-text-transform);

    --md-type-style-3-family: var(--sm-type-style-3-family);
    --md-type-style-3-weight: var(--sm-type-style-3-weight);
    --md-type-style-3-size: var(--sm-type-style-3-size);
    --md-type-style-3-line-height: var(--sm-type-style-3-line-height);
    --md-type-style-3-letter-spacing: var(--sm-type-style-3-letter-spacing);
    --md-type-style-3-text-transform: var(--sm-type-style-3-text-transform);

    --md-type-style-4-family: var(--sm-type-style-4-family);
    --md-type-style-4-weight: var(--sm-type-style-4-weight);
    --md-type-style-4-size: var(--sm-type-style-4-size);
    --md-type-style-4-line-height: var(--sm-type-style-4-line-height);
    --md-type-style-4-letter-spacing: var(--sm-type-style-4-letter-spacing);
    --md-type-style-4-text-transform: var(--sm-type-style-4-text-transform);

    --md-type-style-5-family: var(--sm-type-style-5-family);
    --md-type-style-5-weight: var(--sm-type-style-5-weight);
    --md-type-style-5-size: var(--sm-type-style-5-size);
    --md-type-style-5-line-height: var(--sm-type-style-5-line-height);
    --md-type-style-5-letter-spacing: var(--sm-type-style-5-letter-spacing);
    --md-type-style-5-text-transform: var(--sm-type-style-5-text-transform);

    --md-type-style-6-family: var(--sm-type-style-6-family);
    --md-type-style-6-weight: var(--sm-type-style-6-weight);
    --md-type-style-6-size: var(--sm-type-style-6-size);
    --md-type-style-6-line-height: var(--sm-type-style-6-line-height);
    --md-type-style-6-letter-spacing: var(--sm-type-style-6-letter-spacing);
    --md-type-style-6-text-transform: var(--sm-type-style-6-text-transform);

    --md-type-style-7-family: var(--sm-type-style-7-family);
    --md-type-style-7-weight: var(--sm-type-style-7-weight);
    --md-type-style-7-size: var(--sm-type-style-7-size);
    --md-type-style-7-line-height: var(--sm-type-style-7-line-height);
    --md-type-style-7-letter-spacing: var(--sm-type-style-7-letter-spacing);
    --md-type-style-7-text-transform: var(--sm-type-style-7-text-transform);

    --md-type-style-8-family: var(--sm-type-style-8-family);
    --md-type-style-8-weight: var(--sm-type-style-8-weight);
    --md-type-style-8-size: var(--sm-type-style-8-size);
    --md-type-style-8-line-height: var(--sm-type-style-8-line-height);
    --md-type-style-8-letter-spacing: var(--sm-type-style-8-letter-spacing);
    --md-type-style-8-text-transform: var(--sm-type-style-8-text-transform);

    --md-type-style-9-family: var(--sm-type-style-9-family);
    --md-type-style-9-weight: var(--sm-type-style-9-weight);
    --md-type-style-9-size: var(--sm-type-style-9-size);
    --md-type-style-9-line-height: var(--sm-type-style-9-line-height);
    --md-type-style-9-letter-spacing: var(--sm-type-style-9-letter-spacing);
    --md-type-style-9-text-transform: var(--sm-type-style-9-text-transform);

    --md-type-style-10-family: var(--sm-type-style-10-family);
    --md-type-style-10-weight: var(--sm-type-style-10-weight);
    --md-type-style-10-size: var(--sm-type-style-10-size);
    --md-type-style-10-line-height: var(--sm-type-style-10-line-height);
    --md-type-style-10-letter-spacing: var(--sm-type-style-10-letter-spacing);
    --md-type-style-10-text-transform: var(--sm-type-style-10-text-transform);

    --md-type-style-11-family: var(--sm-type-style-11-family);
    --md-type-style-11-weight: var(--sm-type-style-11-weight);
    --md-type-style-11-size: var(--sm-type-style-11-size);
    --md-type-style-11-line-height: var(--sm-type-style-11-line-height);
    --md-type-style-11-letter-spacing: var(--sm-type-style-11-letter-spacing);
    --md-type-style-11-text-transform: var(--sm-type-style-11-text-transform);

    --md-type-style-12-family: var(--sm-type-style-12-family);
    --md-type-style-12-weight: var(--sm-type-style-12-weight);
    --md-type-style-12-size: var(--sm-type-style-12-size);
    --md-type-style-12-line-height: var(--sm-type-style-12-line-height);
    --md-type-style-12-letter-spacing: var(--sm-type-style-12-letter-spacing);
    --md-type-style-12-text-transform: var(--sm-type-style-12-text-transform);

    /* LG */

    --lg-type-style-1-family: var(--md-type-style-1-family);
    --lg-type-style-1-weight: var(--md-type-style-1-weight);
    --lg-type-style-1-size: var(--md-type-style-1-size);
    --lg-type-style-1-line-height: var(--md-type-style-1-line-height);
    --lg-type-style-1-letter-spacing: var(--md-type-style-1-letter-spacing);
    --lg-type-style-1-text-transform: var(--md-type-style-1-text-transform);

    --lg-type-style-2-family: var(--md-type-style-2-family);
    --lg-type-style-2-weight: var(--md-type-style-2-weight);
    --lg-type-style-2-size: var(--md-type-style-2-size);
    --lg-type-style-2-line-height: var(--md-type-style-2-line-height);
    --lg-type-style-2-letter-spacing: var(--md-type-style-2-letter-spacing);
    --lg-type-style-2-text-transform: var(--md-type-style-2-text-transform);

    --lg-type-style-3-family: var(--md-type-style-3-family);
    --lg-type-style-3-weight: var(--md-type-style-3-weight);
    --lg-type-style-3-size: var(--md-type-style-3-size);
    --lg-type-style-3-line-height: var(--md-type-style-3-line-height);
    --lg-type-style-3-letter-spacing: var(--md-type-style-3-letter-spacing);
    --lg-type-style-3-text-transform: var(--md-type-style-3-text-transform);

    --lg-type-style-4-family: var(--md-type-style-4-family);
    --lg-type-style-4-weight: var(--md-type-style-4-weight);
    --lg-type-style-4-size: var(--md-type-style-4-size);
    --lg-type-style-4-line-height: var(--md-type-style-4-line-height);
    --lg-type-style-4-letter-spacing: var(--md-type-style-4-letter-spacing);
    --lg-type-style-4-text-transform: var(--md-type-style-4-text-transform);

    --lg-type-style-5-family: var(--md-type-style-5-family);
    --lg-type-style-5-weight: var(--md-type-style-5-weight);
    --lg-type-style-5-size: var(--md-type-style-5-size);
    --lg-type-style-5-line-height: var(--md-type-style-5-line-height);
    --lg-type-style-5-letter-spacing: var(--md-type-style-5-letter-spacing);
    --lg-type-style-5-text-transform: var(--md-type-style-5-text-transform);

    --lg-type-style-6-family: var(--md-type-style-6-family);
    --lg-type-style-6-weight: var(--md-type-style-6-weight);
    --lg-type-style-6-size: var(--md-type-style-6-size);
    --lg-type-style-6-line-height: var(--md-type-style-6-line-height);
    --lg-type-style-6-letter-spacing: var(--md-type-style-6-letter-spacing);
    --lg-type-style-6-text-transform: var(--md-type-style-6-text-transform);

    --lg-type-style-7-family: var(--md-type-style-7-family);
    --lg-type-style-7-weight: var(--md-type-style-7-weight);
    --lg-type-style-7-size: var(--md-type-style-7-size);
    --lg-type-style-7-line-height: var(--md-type-style-7-line-height);
    --lg-type-style-7-letter-spacing: var(--md-type-style-7-letter-spacing);
    --lg-type-style-7-text-transform: var(--md-type-style-7-text-transform);

    --lg-type-style-8-family: var(--md-type-style-8-family);
    --lg-type-style-8-weight: var(--md-type-style-8-weight);
    --lg-type-style-8-size: var(--md-type-style-8-size);
    --lg-type-style-8-line-height: var(--md-type-style-8-line-height);
    --lg-type-style-8-letter-spacing: var(--md-type-style-8-letter-spacing);
    --lg-type-style-8-text-transform: var(--md-type-style-8-text-transform);

    --lg-type-style-9-family: var(--md-type-style-9-family);
    --lg-type-style-9-weight: var(--md-type-style-9-weight);
    --lg-type-style-9-size: var(--md-type-style-9-size);
    --lg-type-style-9-line-height: var(--md-type-style-9-line-height);
    --lg-type-style-9-letter-spacing: var(--md-type-style-9-letter-spacing);
    --lg-type-style-9-text-transform: var(--md-type-style-9-text-transform);

    --lg-type-style-10-family: var(--md-type-style-10-family);
    --lg-type-style-10-weight: var(--md-type-style-10-weight);
    --lg-type-style-10-size: var(--md-type-style-10-size);
    --lg-type-style-10-line-height: var(--md-type-style-10-line-height);
    --lg-type-style-10-letter-spacing: var(--md-type-style-10-letter-spacing);
    --lg-type-style-10-text-transform: var(--md-type-style-10-text-transform);

    --lg-type-style-11-family: var(--md-type-style-11-family);
    --lg-type-style-11-weight: var(--md-type-style-11-weight);
    --lg-type-style-11-size: var(--md-type-style-11-size);
    --lg-type-style-11-line-height: var(--md-type-style-11-line-height);
    --lg-type-style-11-letter-spacing: var(--md-type-style-11-letter-spacing);
    --lg-type-style-11-text-transform: var(--md-type-style-11-text-transform);

    --lg-type-style-12-family: var(--md-type-style-12-family);
    --lg-type-style-12-weight: var(--md-type-style-12-weight);
    --lg-type-style-12-size: var(--md-type-style-12-size);
    --lg-type-style-12-line-height: var(--md-type-style-12-line-height);
    --lg-type-style-12-letter-spacing: var(--md-type-style-12-letter-spacing);
    --lg-type-style-12-text-transform: var(--md-type-style-12-text-transform);

}


/* Mixins */

@define-mixin fontStyle $family, $weight, $size, $lineHeight, $letterSpacing, $textTransform {
    font-family: $family;
    font-weight: $weight;
    font-size: $size;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
}


/* Reset */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
body {
    line-height: 1;
}

/* Global */

html {
    font-size: 18px;
}


/* Styles */

.type-style-1  { @mixin fontStyle var(--type-style-1-family),  var(--type-style-1-weight),  var(--type-style-1-size),  var(--type-style-1-line-height),  var(--type-style-1-letter-spacing),  var(--type-style-1-text-transform);  }
.type-style-2  { @mixin fontStyle var(--type-style-2-family),  var(--type-style-2-weight),  var(--type-style-2-size),  var(--type-style-2-line-height),  var(--type-style-2-letter-spacing),  var(--type-style-2-text-transform);  }
.type-style-3  { @mixin fontStyle var(--type-style-3-family),  var(--type-style-3-weight),  var(--type-style-3-size),  var(--type-style-3-line-height),  var(--type-style-3-letter-spacing),  var(--type-style-3-text-transform);  }
.type-style-4  { @mixin fontStyle var(--type-style-4-family),  var(--type-style-4-weight),  var(--type-style-4-size),  var(--type-style-4-line-height),  var(--type-style-4-letter-spacing),  var(--type-style-4-text-transform);  }
.type-style-5  { @mixin fontStyle var(--type-style-5-family),  var(--type-style-5-weight),  var(--type-style-5-size),  var(--type-style-5-line-height),  var(--type-style-5-letter-spacing),  var(--type-style-5-text-transform);  }
.type-style-6  { @mixin fontStyle var(--type-style-6-family),  var(--type-style-6-weight),  var(--type-style-6-size),  var(--type-style-6-line-height),  var(--type-style-6-letter-spacing),  var(--type-style-6-text-transform);  }
.type-style-7  { @mixin fontStyle var(--type-style-7-family),  var(--type-style-7-weight),  var(--type-style-7-size),  var(--type-style-7-line-height),  var(--type-style-7-letter-spacing),  var(--type-style-7-text-transform);  }
.type-style-8  { @mixin fontStyle var(--type-style-8-family),  var(--type-style-8-weight),  var(--type-style-8-size),  var(--type-style-8-line-height),  var(--type-style-8-letter-spacing),  var(--type-style-8-text-transform);  }
.type-style-9  { @mixin fontStyle var(--type-style-9-family),  var(--type-style-9-weight),  var(--type-style-9-size),  var(--type-style-9-line-height),  var(--type-style-9-letter-spacing),  var(--type-style-9-text-transform);  }
.type-style-10 { @mixin fontStyle var(--type-style-10-family), var(--type-style-10-weight), var(--type-style-10-size), var(--type-style-10-line-height), var(--type-style-10-letter-spacing), var(--type-style-10-text-transform); }
.type-style-11 { @mixin fontStyle var(--type-style-11-family), var(--type-style-11-weight), var(--type-style-11-size), var(--type-style-11-line-height), var(--type-style-11-letter-spacing), var(--type-style-11-text-transform); }
.type-style-12 { @mixin fontStyle var(--type-style-12-family), var(--type-style-12-weight), var(--type-style-12-size), var(--type-style-12-line-height), var(--type-style-12-letter-spacing), var(--type-style-12-text-transform); }

.line-height-0 { line-height: 0; }
.line-height-1 { line-height: 1; }

.bold { font-weight: bold; }

.text-decoration-reset { text-decoration: none; }
.underline { text-decoration: underline; }
.strike { text-decoration: line-through; }

.align-centre { text-align: center; }
.align-left { text-align: left; }
.align-right { text-align: right; }
.align-justify { text-align: justify; }

.italic { font-style: italic; }

.list-reset {
    list-style: none;
    padding-left: 0;
}

.wrap { white-space: normal; }
.nowrap { white-space: nowrap; }
.break-word { word-wrap: break-word; }

.font-0 { font-size: 0; }

@media (--breakpoint-sm) {

    .type-style-1  { @mixin fontStyle var(--sm-type-style-1-family),  var(--sm-type-style-1-weight),  var(--sm-type-style-1-size),  var(--sm-type-style-1-line-height),  var(--sm-type-style-1-letter-spacing),  var(--sm-type-style-1-text-transform);  }
    .type-style-2  { @mixin fontStyle var(--sm-type-style-2-family),  var(--sm-type-style-2-weight),  var(--sm-type-style-2-size),  var(--sm-type-style-2-line-height),  var(--sm-type-style-2-letter-spacing),  var(--sm-type-style-2-text-transform);  }
    .type-style-3  { @mixin fontStyle var(--sm-type-style-3-family),  var(--sm-type-style-3-weight),  var(--sm-type-style-3-size),  var(--sm-type-style-3-line-height),  var(--sm-type-style-3-letter-spacing),  var(--sm-type-style-3-text-transform);  }
    .type-style-4  { @mixin fontStyle var(--sm-type-style-4-family),  var(--sm-type-style-4-weight),  var(--sm-type-style-4-size),  var(--sm-type-style-4-line-height),  var(--sm-type-style-4-letter-spacing),  var(--sm-type-style-4-text-transform);  }
    .type-style-5  { @mixin fontStyle var(--sm-type-style-5-family),  var(--sm-type-style-5-weight),  var(--sm-type-style-5-size),  var(--sm-type-style-5-line-height),  var(--sm-type-style-5-letter-spacing),  var(--sm-type-style-5-text-transform);  }
    .type-style-6  { @mixin fontStyle var(--sm-type-style-6-family),  var(--sm-type-style-6-weight),  var(--sm-type-style-6-size),  var(--sm-type-style-6-line-height),  var(--sm-type-style-6-letter-spacing),  var(--sm-type-style-6-text-transform);  }
    .type-style-7  { @mixin fontStyle var(--sm-type-style-7-family),  var(--sm-type-style-7-weight),  var(--sm-type-style-7-size),  var(--sm-type-style-7-line-height),  var(--sm-type-style-7-letter-spacing),  var(--sm-type-style-7-text-transform);  }
    .type-style-8  { @mixin fontStyle var(--sm-type-style-8-family),  var(--sm-type-style-8-weight),  var(--sm-type-style-8-size),  var(--sm-type-style-8-line-height),  var(--sm-type-style-8-letter-spacing),  var(--sm-type-style-8-text-transform);  }
    .type-style-9  { @mixin fontStyle var(--sm-type-style-9-family),  var(--sm-type-style-9-weight),  var(--sm-type-style-9-size),  var(--sm-type-style-9-line-height),  var(--sm-type-style-9-letter-spacing),  var(--sm-type-style-9-text-transform);  }
    .type-style-10 { @mixin fontStyle var(--sm-type-style-10-family), var(--sm-type-style-10-weight), var(--sm-type-style-10-size), var(--sm-type-style-10-line-height), var(--sm-type-style-10-letter-spacing), var(--sm-type-style-10-text-transform); }
    .type-style-11 { @mixin fontStyle var(--sm-type-style-11-family), var(--sm-type-style-11-weight), var(--sm-type-style-11-size), var(--sm-type-style-11-line-height), var(--sm-type-style-11-letter-spacing), var(--sm-type-style-11-text-transform); }
    .type-style-12 { @mixin fontStyle var(--sm-type-style-12-family), var(--sm-type-style-12-weight), var(--sm-type-style-12-size), var(--sm-type-style-12-line-height), var(--sm-type-style-12-letter-spacing), var(--sm-type-style-12-text-transform); }

    .line-height-0 { line-height: 0; }
    .line-height-1 { line-height: 1; }

    .bold { font-weight: bold; }

    .sm-type-style-1  { @mixin fontStyle var(--sm-type-style-1-family),  var(--sm-type-style-1-weight),  var(--sm-type-style-1-size),  var(--sm-type-style-1-line-height),  var(--sm-type-style-1-letter-spacing),  var(--sm-type-style-1-text-transform);  }
    .sm-type-style-2  { @mixin fontStyle var(--sm-type-style-2-family),  var(--sm-type-style-2-weight),  var(--sm-type-style-2-size),  var(--sm-type-style-2-line-height),  var(--sm-type-style-2-letter-spacing),  var(--sm-type-style-2-text-transform);  }
    .sm-type-style-3  { @mixin fontStyle var(--sm-type-style-3-family),  var(--sm-type-style-3-weight),  var(--sm-type-style-3-size),  var(--sm-type-style-3-line-height),  var(--sm-type-style-3-letter-spacing),  var(--sm-type-style-3-text-transform);  }
    .sm-type-style-4  { @mixin fontStyle var(--sm-type-style-4-family),  var(--sm-type-style-4-weight),  var(--sm-type-style-4-size),  var(--sm-type-style-4-line-height),  var(--sm-type-style-4-letter-spacing),  var(--sm-type-style-4-text-transform);  }
    .sm-type-style-5  { @mixin fontStyle var(--sm-type-style-5-family),  var(--sm-type-style-5-weight),  var(--sm-type-style-5-size),  var(--sm-type-style-5-line-height),  var(--sm-type-style-5-letter-spacing),  var(--sm-type-style-5-text-transform);  }
    .sm-type-style-6  { @mixin fontStyle var(--sm-type-style-6-family),  var(--sm-type-style-6-weight),  var(--sm-type-style-6-size),  var(--sm-type-style-6-line-height),  var(--sm-type-style-6-letter-spacing),  var(--sm-type-style-6-text-transform);  }
    .sm-type-style-7  { @mixin fontStyle var(--sm-type-style-7-family),  var(--sm-type-style-7-weight),  var(--sm-type-style-7-size),  var(--sm-type-style-7-line-height),  var(--sm-type-style-7-letter-spacing),  var(--sm-type-style-7-text-transform);  }
    .sm-type-style-8  { @mixin fontStyle var(--sm-type-style-8-family),  var(--sm-type-style-8-weight),  var(--sm-type-style-8-size),  var(--sm-type-style-8-line-height),  var(--sm-type-style-8-letter-spacing),  var(--sm-type-style-8-text-transform);  }
    .sm-type-style-9  { @mixin fontStyle var(--sm-type-style-9-family),  var(--sm-type-style-9-weight),  var(--sm-type-style-9-size),  var(--sm-type-style-9-line-height),  var(--sm-type-style-9-letter-spacing),  var(--sm-type-style-9-text-transform);  }
    .sm-type-style-10 { @mixin fontStyle var(--sm-type-style-10-family), var(--sm-type-style-10-weight), var(--sm-type-style-10-size), var(--sm-type-style-10-line-height), var(--sm-type-style-10-letter-spacing), var(--sm-type-style-10-text-transform); }
    .sm-type-style-11 { @mixin fontStyle var(--sm-type-style-11-family), var(--sm-type-style-11-weight), var(--sm-type-style-11-size), var(--sm-type-style-11-line-height), var(--sm-type-style-11-letter-spacing), var(--sm-type-style-11-text-transform); }
    .sm-type-style-12 { @mixin fontStyle var(--sm-type-style-12-family), var(--sm-type-style-12-weight), var(--sm-type-style-12-size), var(--sm-type-style-12-line-height), var(--sm-type-style-12-letter-spacing), var(--sm-type-style-12-text-transform); }

    .sm-line-height-0 { line-height: 0; }
    .sm-line-height-1 { line-height: 1; }

    .sm-bold { font-weight: bold; }

    .sm-text-decoration-reset { text-decoration: none; }
    .sm-underline { text-decoration: underline; }
    .sm-strike { text-decoration: line-through; }

    .sm-align-centre { text-align: center; }
    .sm-align-left { text-align: left; }
    .sm-align-right { text-align: right; }
    .sm-align-justify { text-align: justify; }

    .sm-italic { font-style: italic; }
    .sm-bold { font-weight: bold; }

    .sm-list-reset {
        list-style: none;
        padding-left: 0;
    }

    .sm-wrap { white-space: normal; }
    .sm-nowrap { white-space: nowrap; }
    .sm-break-word { word-wrap: break-word; }

    .sm-font-0 { font-size: 0; }

}

@media (--breakpoint-md) {

    .type-style-1  { @mixin fontStyle var(--md-type-style-1-family),  var(--md-type-style-1-weight),  var(--md-type-style-1-size),  var(--md-type-style-1-line-height),  var(--md-type-style-1-letter-spacing),  var(--md-type-style-1-text-transform);  }
    .type-style-2  { @mixin fontStyle var(--md-type-style-2-family),  var(--md-type-style-2-weight),  var(--md-type-style-2-size),  var(--md-type-style-2-line-height),  var(--md-type-style-2-letter-spacing),  var(--md-type-style-2-text-transform);  }
    .type-style-3  { @mixin fontStyle var(--md-type-style-3-family),  var(--md-type-style-3-weight),  var(--md-type-style-3-size),  var(--md-type-style-3-line-height),  var(--md-type-style-3-letter-spacing),  var(--md-type-style-3-text-transform);  }
    .type-style-4  { @mixin fontStyle var(--md-type-style-4-family),  var(--md-type-style-4-weight),  var(--md-type-style-4-size),  var(--md-type-style-4-line-height),  var(--md-type-style-4-letter-spacing),  var(--md-type-style-4-text-transform);  }
    .type-style-5  { @mixin fontStyle var(--md-type-style-5-family),  var(--md-type-style-5-weight),  var(--md-type-style-5-size),  var(--md-type-style-5-line-height),  var(--md-type-style-5-letter-spacing),  var(--md-type-style-5-text-transform);  }
    .type-style-6  { @mixin fontStyle var(--md-type-style-6-family),  var(--md-type-style-6-weight),  var(--md-type-style-6-size),  var(--md-type-style-6-line-height),  var(--md-type-style-6-letter-spacing),  var(--md-type-style-6-text-transform);  }
    .type-style-7  { @mixin fontStyle var(--md-type-style-7-family),  var(--md-type-style-7-weight),  var(--md-type-style-7-size),  var(--md-type-style-7-line-height),  var(--md-type-style-7-letter-spacing),  var(--md-type-style-7-text-transform);  }
    .type-style-8  { @mixin fontStyle var(--md-type-style-8-family),  var(--md-type-style-8-weight),  var(--md-type-style-8-size),  var(--md-type-style-8-line-height),  var(--md-type-style-8-letter-spacing),  var(--md-type-style-8-text-transform);  }
    .type-style-9  { @mixin fontStyle var(--md-type-style-9-family),  var(--md-type-style-9-weight),  var(--md-type-style-9-size),  var(--md-type-style-9-line-height),  var(--md-type-style-9-letter-spacing),  var(--md-type-style-9-text-transform);  }
    .type-style-10 { @mixin fontStyle var(--md-type-style-10-family), var(--md-type-style-10-weight), var(--md-type-style-10-size), var(--md-type-style-10-line-height), var(--md-type-style-10-letter-spacing), var(--md-type-style-10-text-transform); }
    .type-style-11 { @mixin fontStyle var(--md-type-style-11-family), var(--md-type-style-11-weight), var(--md-type-style-11-size), var(--md-type-style-11-line-height), var(--md-type-style-11-letter-spacing), var(--md-type-style-11-text-transform); }
    .type-style-12 { @mixin fontStyle var(--md-type-style-12-family), var(--md-type-style-12-weight), var(--md-type-style-12-size), var(--md-type-style-12-line-height), var(--md-type-style-12-letter-spacing), var(--md-type-style-12-text-transform); }

    .line-height-0 { line-height: 0; }
    .line-height-1 { line-height: 1; }

    .bold { font-weight: bold; }

    .sm-type-style-1  { @mixin fontStyle var(--md-type-style-1-family),  var(--md-type-style-1-weight),  var(--md-type-style-1-size),  var(--md-type-style-1-line-height),  var(--md-type-style-1-letter-spacing),  var(--md-type-style-1-text-transform);  }
    .sm-type-style-2  { @mixin fontStyle var(--md-type-style-2-family),  var(--md-type-style-2-weight),  var(--md-type-style-2-size),  var(--md-type-style-2-line-height),  var(--md-type-style-2-letter-spacing),  var(--md-type-style-2-text-transform);  }
    .sm-type-style-3  { @mixin fontStyle var(--md-type-style-3-family),  var(--md-type-style-3-weight),  var(--md-type-style-3-size),  var(--md-type-style-3-line-height),  var(--md-type-style-3-letter-spacing),  var(--md-type-style-3-text-transform);  }
    .sm-type-style-4  { @mixin fontStyle var(--md-type-style-4-family),  var(--md-type-style-4-weight),  var(--md-type-style-4-size),  var(--md-type-style-4-line-height),  var(--md-type-style-4-letter-spacing),  var(--md-type-style-4-text-transform);  }
    .sm-type-style-5  { @mixin fontStyle var(--md-type-style-5-family),  var(--md-type-style-5-weight),  var(--md-type-style-5-size),  var(--md-type-style-5-line-height),  var(--md-type-style-5-letter-spacing),  var(--md-type-style-5-text-transform);  }
    .sm-type-style-6  { @mixin fontStyle var(--md-type-style-6-family),  var(--md-type-style-6-weight),  var(--md-type-style-6-size),  var(--md-type-style-6-line-height),  var(--md-type-style-6-letter-spacing),  var(--md-type-style-6-text-transform);  }
    .sm-type-style-7  { @mixin fontStyle var(--md-type-style-7-family),  var(--md-type-style-7-weight),  var(--md-type-style-7-size),  var(--md-type-style-7-line-height),  var(--md-type-style-7-letter-spacing),  var(--md-type-style-7-text-transform);  }
    .sm-type-style-8  { @mixin fontStyle var(--md-type-style-8-family),  var(--md-type-style-8-weight),  var(--md-type-style-8-size),  var(--md-type-style-8-line-height),  var(--md-type-style-8-letter-spacing),  var(--md-type-style-8-text-transform);  }
    .sm-type-style-9  { @mixin fontStyle var(--md-type-style-9-family),  var(--md-type-style-9-weight),  var(--md-type-style-9-size),  var(--md-type-style-9-line-height),  var(--md-type-style-9-letter-spacing),  var(--md-type-style-9-text-transform);  }
    .sm-type-style-10 { @mixin fontStyle var(--md-type-style-10-family), var(--md-type-style-10-weight), var(--md-type-style-10-size), var(--md-type-style-10-line-height), var(--md-type-style-10-letter-spacing), var(--md-type-style-10-text-transform); }
    .sm-type-style-11 { @mixin fontStyle var(--md-type-style-11-family), var(--md-type-style-11-weight), var(--md-type-style-11-size), var(--md-type-style-11-line-height), var(--md-type-style-11-letter-spacing), var(--md-type-style-11-text-transform); }
    .sm-type-style-12 { @mixin fontStyle var(--md-type-style-12-family), var(--md-type-style-12-weight), var(--md-type-style-12-size), var(--md-type-style-12-line-height), var(--md-type-style-12-letter-spacing), var(--md-type-style-12-text-transform); }

    .sm-line-height-0 { line-height: 0; }
    .sm-line-height-1 { line-height: 1; }

    .sm-bold { font-weight: bold; }

    .md-type-style-1  { @mixin fontStyle var(--md-type-style-1-family),  var(--md-type-style-1-weight),  var(--md-type-style-1-size),  var(--md-type-style-1-line-height),  var(--md-type-style-1-letter-spacing),  var(--md-type-style-1-text-transform);  }
    .md-type-style-2  { @mixin fontStyle var(--md-type-style-2-family),  var(--md-type-style-2-weight),  var(--md-type-style-2-size),  var(--md-type-style-2-line-height),  var(--md-type-style-2-letter-spacing),  var(--md-type-style-2-text-transform);  }
    .md-type-style-3  { @mixin fontStyle var(--md-type-style-3-family),  var(--md-type-style-3-weight),  var(--md-type-style-3-size),  var(--md-type-style-3-line-height),  var(--md-type-style-3-letter-spacing),  var(--md-type-style-3-text-transform);  }
    .md-type-style-4  { @mixin fontStyle var(--md-type-style-4-family),  var(--md-type-style-4-weight),  var(--md-type-style-4-size),  var(--md-type-style-4-line-height),  var(--md-type-style-4-letter-spacing),  var(--md-type-style-4-text-transform);  }
    .md-type-style-5  { @mixin fontStyle var(--md-type-style-5-family),  var(--md-type-style-5-weight),  var(--md-type-style-5-size),  var(--md-type-style-5-line-height),  var(--md-type-style-5-letter-spacing),  var(--md-type-style-5-text-transform);  }
    .md-type-style-6  { @mixin fontStyle var(--md-type-style-6-family),  var(--md-type-style-6-weight),  var(--md-type-style-6-size),  var(--md-type-style-6-line-height),  var(--md-type-style-6-letter-spacing),  var(--md-type-style-6-text-transform);  }
    .md-type-style-7  { @mixin fontStyle var(--md-type-style-7-family),  var(--md-type-style-7-weight),  var(--md-type-style-7-size),  var(--md-type-style-7-line-height),  var(--md-type-style-7-letter-spacing),  var(--md-type-style-7-text-transform);  }
    .md-type-style-8  { @mixin fontStyle var(--md-type-style-8-family),  var(--md-type-style-8-weight),  var(--md-type-style-8-size),  var(--md-type-style-8-line-height),  var(--md-type-style-8-letter-spacing),  var(--md-type-style-8-text-transform);  }
    .md-type-style-9  { @mixin fontStyle var(--md-type-style-9-family),  var(--md-type-style-9-weight),  var(--md-type-style-9-size),  var(--md-type-style-9-line-height),  var(--md-type-style-9-letter-spacing),  var(--md-type-style-9-text-transform);  }
    .md-type-style-10 { @mixin fontStyle var(--md-type-style-10-family), var(--md-type-style-10-weight), var(--md-type-style-10-size), var(--md-type-style-10-line-height), var(--md-type-style-10-letter-spacing), var(--md-type-style-10-text-transform); }
    .md-type-style-11 { @mixin fontStyle var(--md-type-style-11-family), var(--md-type-style-11-weight), var(--md-type-style-11-size), var(--md-type-style-11-line-height), var(--md-type-style-11-letter-spacing), var(--md-type-style-11-text-transform); }
    .md-type-style-12 { @mixin fontStyle var(--md-type-style-12-family), var(--md-type-style-12-weight), var(--md-type-style-12-size), var(--md-type-style-12-line-height), var(--md-type-style-12-letter-spacing), var(--md-type-style-12-text-transform); }

    .md-line-height-0 { line-height: 0; }
    .md-line-height-1 { line-height: 1; }

    .md-bold { font-weight: bold; }

    .md-text-decoration-reset { text-decoration: none; }
    .md-underline { text-decoration: underline; }
    .md-strike { text-decoration: line-through; }

    .md-align-centre { text-align: center; }
    .md-align-left { text-align: left; }
    .md-align-right { text-align: right; }
    .md-align-justify { text-align: justify; }

    .md-italic { font-style: italic; }

    .md-list-reset {
        list-style: none;
        padding-left: 0;
    }

    .md-wrap { white-space: normal; }
    .md-nowrap { white-space: nowrap; }
    .md-break-word { word-wrap: break-word; }

    .md-font-0 { font-size: 0; }

}

@media (--breakpoint-lg) {

    .type-style-1  { @mixin fontStyle var(--lg-type-style-1-family),  var(--lg-type-style-1-weight),  var(--lg-type-style-1-size),  var(--lg-type-style-1-line-height),  var(--lg-type-style-1-letter-spacing),  var(--lg-type-style-1-text-transform);  }
    .type-style-2  { @mixin fontStyle var(--lg-type-style-2-family),  var(--lg-type-style-2-weight),  var(--lg-type-style-2-size),  var(--lg-type-style-2-line-height),  var(--lg-type-style-2-letter-spacing),  var(--lg-type-style-2-text-transform);  }
    .type-style-3  { @mixin fontStyle var(--lg-type-style-3-family),  var(--lg-type-style-3-weight),  var(--lg-type-style-3-size),  var(--lg-type-style-3-line-height),  var(--lg-type-style-3-letter-spacing),  var(--lg-type-style-3-text-transform);  }
    .type-style-4  { @mixin fontStyle var(--lg-type-style-4-family),  var(--lg-type-style-4-weight),  var(--lg-type-style-4-size),  var(--lg-type-style-4-line-height),  var(--lg-type-style-4-letter-spacing),  var(--lg-type-style-4-text-transform);  }
    .type-style-5  { @mixin fontStyle var(--lg-type-style-5-family),  var(--lg-type-style-5-weight),  var(--lg-type-style-5-size),  var(--lg-type-style-5-line-height),  var(--lg-type-style-5-letter-spacing),  var(--lg-type-style-5-text-transform);  }
    .type-style-6  { @mixin fontStyle var(--lg-type-style-6-family),  var(--lg-type-style-6-weight),  var(--lg-type-style-6-size),  var(--lg-type-style-6-line-height),  var(--lg-type-style-6-letter-spacing),  var(--lg-type-style-6-text-transform);  }
    .type-style-7  { @mixin fontStyle var(--lg-type-style-7-family),  var(--lg-type-style-7-weight),  var(--lg-type-style-7-size),  var(--lg-type-style-7-line-height),  var(--lg-type-style-7-letter-spacing),  var(--lg-type-style-7-text-transform);  }
    .type-style-8  { @mixin fontStyle var(--lg-type-style-8-family),  var(--lg-type-style-8-weight),  var(--lg-type-style-8-size),  var(--lg-type-style-8-line-height),  var(--lg-type-style-8-letter-spacing),  var(--lg-type-style-8-text-transform);  }
    .type-style-9  { @mixin fontStyle var(--lg-type-style-9-family),  var(--lg-type-style-9-weight),  var(--lg-type-style-9-size),  var(--lg-type-style-9-line-height),  var(--lg-type-style-9-letter-spacing),  var(--lg-type-style-9-text-transform);  }
    .type-style-10 { @mixin fontStyle var(--lg-type-style-10-family), var(--lg-type-style-10-weight), var(--lg-type-style-10-size), var(--lg-type-style-10-line-height), var(--lg-type-style-10-letter-spacing), var(--lg-type-style-10-text-transform); }
    .type-style-11 { @mixin fontStyle var(--lg-type-style-11-family), var(--lg-type-style-11-weight), var(--lg-type-style-11-size), var(--lg-type-style-11-line-height), var(--lg-type-style-11-letter-spacing), var(--lg-type-style-11-text-transform); }
    .type-style-12 { @mixin fontStyle var(--lg-type-style-12-family), var(--lg-type-style-12-weight), var(--lg-type-style-12-size), var(--lg-type-style-12-line-height), var(--lg-type-style-12-letter-spacing), var(--lg-type-style-12-text-transform); }

    .line-height-0 { line-height: 0; }
    .line-height-1 { line-height: 1; }

    .bold { font-weight: bold; }

    .sm-type-style-1  { @mixin fontStyle var(--lg-type-style-1-family),  fontStyle var(--lg-type-style-1-weight),  var(--lg-type-style-1-size),  var(--lg-type-style-1-line-height),  var(--lg-type-style-1-letter-spacing),  var(--lg-type-style-1-text-transform);  }
    .sm-type-style-2  { @mixin fontStyle var(--lg-type-style-2-family),  fontStyle var(--lg-type-style-2-weight),  var(--lg-type-style-2-size),  var(--lg-type-style-2-line-height),  var(--lg-type-style-2-letter-spacing),  var(--lg-type-style-2-text-transform);  }
    .sm-type-style-3  { @mixin fontStyle var(--lg-type-style-3-family),  fontStyle var(--lg-type-style-3-weight),  var(--lg-type-style-3-size),  var(--lg-type-style-3-line-height),  var(--lg-type-style-3-letter-spacing),  var(--lg-type-style-3-text-transform);  }
    .sm-type-style-4  { @mixin fontStyle var(--lg-type-style-4-family),  fontStyle var(--lg-type-style-4-weight),  var(--lg-type-style-4-size),  var(--lg-type-style-4-line-height),  var(--lg-type-style-4-letter-spacing),  var(--lg-type-style-4-text-transform);  }
    .sm-type-style-5  { @mixin fontStyle var(--lg-type-style-5-family),  fontStyle var(--lg-type-style-5-weight),  var(--lg-type-style-5-size),  var(--lg-type-style-5-line-height),  var(--lg-type-style-5-letter-spacing),  var(--lg-type-style-5-text-transform);  }
    .sm-type-style-6  { @mixin fontStyle var(--lg-type-style-6-family),  fontStyle var(--lg-type-style-6-weight),  var(--lg-type-style-6-size),  var(--lg-type-style-6-line-height),  var(--lg-type-style-6-letter-spacing),  var(--lg-type-style-6-text-transform);  }
    .sm-type-style-7  { @mixin fontStyle var(--lg-type-style-7-family),  fontStyle var(--lg-type-style-7-weight),  var(--lg-type-style-7-size),  var(--lg-type-style-7-line-height),  var(--lg-type-style-7-letter-spacing),  var(--lg-type-style-7-text-transform);  }
    .sm-type-style-8  { @mixin fontStyle var(--lg-type-style-8-family),  fontStyle var(--lg-type-style-8-weight),  var(--lg-type-style-8-size),  var(--lg-type-style-8-line-height),  var(--lg-type-style-8-letter-spacing),  var(--lg-type-style-8-text-transform);  }
    .sm-type-style-9  { @mixin fontStyle var(--lg-type-style-9-family),  fontStyle var(--lg-type-style-9-weight),  var(--lg-type-style-9-size),  var(--lg-type-style-9-line-height),  var(--lg-type-style-9-letter-spacing),  var(--lg-type-style-9-text-transform);  }
    .sm-type-style-10 { @mixin fontStyle var(--lg-type-style-10-family), fontStyle var(--lg-type-style-10-weight), var(--lg-type-style-10-size), var(--lg-type-style-10-line-height), var(--lg-type-style-10-letter-spacing), var(--lg-type-style-10-text-transform); }
    .sm-type-style-11 { @mixin fontStyle var(--lg-type-style-11-family), fontStyle var(--lg-type-style-11-weight), var(--lg-type-style-11-size), var(--lg-type-style-11-line-height), var(--lg-type-style-11-letter-spacing), var(--lg-type-style-11-text-transform); }
    .sm-type-style-12 { @mixin fontStyle var(--lg-type-style-12-family), fontStyle var(--lg-type-style-12-weight), var(--lg-type-style-12-size), var(--lg-type-style-12-line-height), var(--lg-type-style-12-letter-spacing), var(--lg-type-style-12-text-transform); }

    .sm-line-height-0 { line-height: 0; }
    .sm-line-height-1 { line-height: 1; }

    .sm-bold { font-weight: bold; }

    .md-type-style-1  { @mixin fontStyle var(--lg-type-style-1-family),  var(--lg-type-style-1-weight),  var(--lg-type-style-1-size),  var(--lg-type-style-1-line-height),  var(--lg-type-style-1-letter-spacing),  var(--lg-type-style-1-text-transform);  }
    .md-type-style-2  { @mixin fontStyle var(--lg-type-style-2-family),  var(--lg-type-style-2-weight),  var(--lg-type-style-2-size),  var(--lg-type-style-2-line-height),  var(--lg-type-style-2-letter-spacing),  var(--lg-type-style-2-text-transform);  }
    .md-type-style-3  { @mixin fontStyle var(--lg-type-style-3-family),  var(--lg-type-style-3-weight),  var(--lg-type-style-3-size),  var(--lg-type-style-3-line-height),  var(--lg-type-style-3-letter-spacing),  var(--lg-type-style-3-text-transform);  }
    .md-type-style-4  { @mixin fontStyle var(--lg-type-style-4-family),  var(--lg-type-style-4-weight),  var(--lg-type-style-4-size),  var(--lg-type-style-4-line-height),  var(--lg-type-style-4-letter-spacing),  var(--lg-type-style-4-text-transform);  }
    .md-type-style-5  { @mixin fontStyle var(--lg-type-style-5-family),  var(--lg-type-style-5-weight),  var(--lg-type-style-5-size),  var(--lg-type-style-5-line-height),  var(--lg-type-style-5-letter-spacing),  var(--lg-type-style-5-text-transform);  }
    .md-type-style-6  { @mixin fontStyle var(--lg-type-style-6-family),  var(--lg-type-style-6-weight),  var(--lg-type-style-6-size),  var(--lg-type-style-6-line-height),  var(--lg-type-style-6-letter-spacing),  var(--lg-type-style-6-text-transform);  }
    .md-type-style-7  { @mixin fontStyle var(--lg-type-style-7-family),  var(--lg-type-style-7-weight),  var(--lg-type-style-7-size),  var(--lg-type-style-7-line-height),  var(--lg-type-style-7-letter-spacing),  var(--lg-type-style-7-text-transform);  }
    .md-type-style-8  { @mixin fontStyle var(--lg-type-style-8-family),  var(--lg-type-style-8-weight),  var(--lg-type-style-8-size),  var(--lg-type-style-8-line-height),  var(--lg-type-style-8-letter-spacing),  var(--lg-type-style-8-text-transform);  }
    .md-type-style-9  { @mixin fontStyle var(--lg-type-style-9-family),  var(--lg-type-style-9-weight),  var(--lg-type-style-9-size),  var(--lg-type-style-9-line-height),  var(--lg-type-style-9-letter-spacing),  var(--lg-type-style-9-text-transform);  }
    .md-type-style-10 { @mixin fontStyle var(--lg-type-style-10-family), var(--lg-type-style-10-weight), var(--lg-type-style-10-size), var(--lg-type-style-10-line-height), var(--lg-type-style-10-letter-spacing), var(--lg-type-style-10-text-transform); }
    .md-type-style-11 { @mixin fontStyle var(--lg-type-style-11-family), var(--lg-type-style-11-weight), var(--lg-type-style-11-size), var(--lg-type-style-11-line-height), var(--lg-type-style-11-letter-spacing), var(--lg-type-style-11-text-transform); }
    .md-type-style-12 { @mixin fontStyle var(--lg-type-style-12-family), var(--lg-type-style-12-weight), var(--lg-type-style-12-size), var(--lg-type-style-12-line-height), var(--lg-type-style-12-letter-spacing), var(--lg-type-style-12-text-transform); }

    .md-line-height-0 { line-height: 0; }
    .md-line-height-1 { line-height: 1; }

    .md-bold { font-weight: bold; }

    .lg-type-style-1  { @mixin fontStyle var(--lg-type-style-1-family),  var(--lg-type-style-1-weight),  var(--lg-type-style-1-size),  var(--lg-type-style-1-line-height),  var(--lg-type-style-1-letter-spacing),  var(--lg-type-style-1-text-transform);  }
    .lg-type-style-2  { @mixin fontStyle var(--lg-type-style-2-family),  var(--lg-type-style-2-weight),  var(--lg-type-style-2-size),  var(--lg-type-style-2-line-height),  var(--lg-type-style-2-letter-spacing),  var(--lg-type-style-2-text-transform);  }
    .lg-type-style-3  { @mixin fontStyle var(--lg-type-style-3-family),  var(--lg-type-style-3-weight),  var(--lg-type-style-3-size),  var(--lg-type-style-3-line-height),  var(--lg-type-style-3-letter-spacing),  var(--lg-type-style-3-text-transform);  }
    .lg-type-style-4  { @mixin fontStyle var(--lg-type-style-4-family),  var(--lg-type-style-4-weight),  var(--lg-type-style-4-size),  var(--lg-type-style-4-line-height),  var(--lg-type-style-4-letter-spacing),  var(--lg-type-style-4-text-transform);  }
    .lg-type-style-5  { @mixin fontStyle var(--lg-type-style-5-family),  var(--lg-type-style-5-weight),  var(--lg-type-style-5-size),  var(--lg-type-style-5-line-height),  var(--lg-type-style-5-letter-spacing),  var(--lg-type-style-5-text-transform);  }
    .lg-type-style-6  { @mixin fontStyle var(--lg-type-style-6-family),  var(--lg-type-style-6-weight),  var(--lg-type-style-6-size),  var(--lg-type-style-6-line-height),  var(--lg-type-style-6-letter-spacing),  var(--lg-type-style-6-text-transform);  }
    .lg-type-style-7  { @mixin fontStyle var(--lg-type-style-7-family),  var(--lg-type-style-7-weight),  var(--lg-type-style-7-size),  var(--lg-type-style-7-line-height),  var(--lg-type-style-7-letter-spacing),  var(--lg-type-style-7-text-transform);  }
    .lg-type-style-8  { @mixin fontStyle var(--lg-type-style-8-family),  var(--lg-type-style-8-weight),  var(--lg-type-style-8-size),  var(--lg-type-style-8-line-height),  var(--lg-type-style-8-letter-spacing),  var(--lg-type-style-8-text-transform);  }
    .lg-type-style-9  { @mixin fontStyle var(--lg-type-style-9-family),  var(--lg-type-style-9-weight),  var(--lg-type-style-9-size),  var(--lg-type-style-9-line-height),  var(--lg-type-style-9-letter-spacing),  var(--lg-type-style-9-text-transform);  }
    .lg-type-style-10 { @mixin fontStyle var(--lg-type-style-10-family), var(--lg-type-style-10-weight), var(--lg-type-style-10-size), var(--lg-type-style-10-line-height), var(--lg-type-style-10-letter-spacing), var(--lg-type-style-10-text-transform); }
    .lg-type-style-11 { @mixin fontStyle var(--lg-type-style-11-family), var(--lg-type-style-11-weight), var(--lg-type-style-11-size), var(--lg-type-style-11-line-height), var(--lg-type-style-11-letter-spacing), var(--lg-type-style-11-text-transform); }
    .lg-type-style-12 { @mixin fontStyle var(--lg-type-style-12-family), var(--lg-type-style-12-weight), var(--lg-type-style-12-size), var(--lg-type-style-12-line-height), var(--lg-type-style-12-letter-spacing), var(--lg-type-style-12-text-transform); }

    .lg-line-height-0 { line-height: 0; }
    .lg-line-height-1 { line-height: 1; }

    .lg-line-height-0 { line-height: 0; }
    .lg-line-height-1 { line-height: 1; }

    .lg-bold { font-weight: bold; }

    .lg-text-decoration-reset { text-decoration: none; }
    .lg-underline { text-decoration: underline; }
    .lg-strike { text-decoration: line-through; }

    .lg-align-centre { text-align: center; }
    .lg-align-left { text-align: left; }
    .lg-align-right { text-align: right; }
    .lg-align-justify { text-align: justify; }

    .lg-italic { font-style: italic; }

    .lg-list-reset {
        list-style: none;
        padding-left: 0;
    }

    .lg-wrap { white-space: normal; }
    .lg-nowrap { white-space: nowrap; }
    .lg-break-word { word-wrap: break-word; }

    .lg-font-0 { font-size: 0; }

}
