/*
 * Position
 */

.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }
.sticky { position: sticky; }
.top-0 { top: 0; }
.right-0 { right: 0; }
.bottom-0 { bottom: 0; }
.left-0 { left: 0; }

@media (--breakpoint-sm) {
    .sm-relative { position: relative; }
    .sm-absolute { position: absolute; }
    .sm-fixed { position: fixed; }
    .sm-sticky { position: sticky; }
    .sm-top-0 { top: 0; }
    .sm-right-0 { right: 0; }
    .sm-bottom-0 { bottom: 0; }
    .sm-left-0 { left: 0; }
}

@media (--breakpoint-md) {
    .md-relative { position: relative; }
    .md-absolute { position: absolute; }
    .md-fixed { position: fixed; }
    .md-sticky { position: sticky; }
    .md-top-0 { top: 0; }
    .md-right-0 { right: 0; }
    .md-bottom-0 { bottom: 0; }
    .md-left-0 { left: 0; }
}

@media (--breakpoint-lg) {
    .lg-relative { position: relative; }
    .lg-absolute { position: absolute; }
    .lg-fixed { position: fixed; }
    .lg-sticky { position: sticky; }
    .lg-top-0 { top: 0; }
    .lg-right-0 { right: 0; }
    .lg-bottom-0 { bottom: 0; }
    .lg-left-0 { left: 0; }
}
