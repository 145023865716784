/* Base */

@import './bva';

/* Modules */

@import './modules/reset';
@import './modules/accessiblity';
@import './modules/colours';
@import './modules/spaces';
@import './modules/type';
@import './modules/layout';
@import './modules/layers';
@import './modules/icons';
@import './modules/flex';
@import './modules/header';
@import './modules/transition';
@import './modules/hero';
@import './modules/scrollbar';
@import './modules/swiper';
@import './modules/effects';
@import './modules/borders';
@import './modules/video';
@import './modules/instagram';
@import './modules/cookiebot';
@import './modules/z-index';

:root {
    --muted: 0.4;
    --container-width: 198rem;
}

/* Breakpoints */

@custom-media --breakpoint-xs-only (max-width: 639px); /* Mobile */
@custom-media --breakpoint-sm (min-width: 640px);  /* Tablet */

@custom-media --breakpoint-sm-only (min-width: 640px) and (max-width: 1023px); /* Tablet */

@custom-media --breakpoint-md (min-width: 1024px); /* Small desktop */
@custom-media --breakpoint-md-only (min-width: 1024px) and (max-width: 1479px); /* Small desktop */

@custom-media --breakpoint-lg (min-width: 1800px); /* Large desktop */


html {
    scroll-behavior: smooth;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.cursor-default {
    cursor: default;
}

.isolate {
    isolation: isolate;
}
