/*
 * Container
 */

:root {
    --container-width: 1440px;
}

.container {
    max-width: var(--container-width);
    margin: 0 auto;
}
