/*
 * Table
 */

table {
    border-collapse: collapse;
}

.table-auto { table-layout: auto; }
.table-fixed { table-layout: fixed; }
