/*
 * Header
 */

.header {
    transition: opacity 200ms ease-out;
    height: 79px;
}

@media (--breakpoint-sm) {
    .header {
    height: 99px;
    }
}

.mobile-header {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 180ms ease-out, transform 180ms ease-out;
}

.mobile-header.active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 250ms ease-out, transform 250ms ease-out;
}

.menu-open .mobile-header {
    z-index: 0;
}

.menu-open {
    overflow: hidden;
}