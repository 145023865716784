/*
* Flexbox
*/

.flex-initial { flex: 0 1 auto; }
.flex-1 { flex: 1 1 0%; }
.flex-auto { flex: 1 1 auto; }
.flex-none { flex: none; }

.flex-col { flex-direction: column; }
.flex-col-reverse { flex-direction: column-reverse; }
.flex-row { flex-direction: row; }
.flex-row-reverse { flex-direction: row-reverse; }

.flex-no-wrap { flex-wrap: nowrap; }
.flex-wrap { flex-wrap: wrap; }
.flex-wrap-reverse { flex-wrap: wrap-reverse; }

.items-baseline { align-items: baseline; }
.items-center { align-items: center; }
.items-end { align-items: flex-end; }
.items-start { align-items: flex-start; }
.items-stretch { align-items: stretch; }

.content-around { align-content: space-around; }
.content-between { align-content: space-between; }
.content-center { align-content: center; }
.content-end { align-content: flex-end; }
.content-start { align-content: flex-start; }

.self-auto { align-self: auto; }
.self-center { align-self: center; }
.self-end { align-self: flex-end; }
.self-start { align-self: flex-start; }
.self-stretch { align-self: stretch; }

.justify-around { justify-content: space-around; }
.justify-between { justify-content: space-between; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-start { justify-content: flex-start; }

.flex-grow { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }

.flex-shrink { flex-shrink: 1; }
.flex-shrink-0 { flex-shrink: 0; }

.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }
.order-4 { order: 4; }
.order-5 { order: 5; }
.order-6 { order: 6; }
.order-7 { order: 7; }
.order-8 { order: 8; }
.order-9 { order: 9; }
.order-10 { order: 10; }
.order-11 { order: 11; }
.order-12 { order: 12; }
.order-first { order: -9999; }
.order-last { order: 9999; }
.order-none { order: 0; }

@media (--breakpoint-sm) {
    .sm-flex-initial { flex: 0 1 auto; }
    .sm-flex-1 { flex: 1 1 0%; }
    .sm-flex-auto { flex: 1 1 auto; }
    .sm-flex-none { flex: none; }

    .sm-flex-col { flex-direction: column; }
    .sm-flex-col-reverse { flex-direction: column-reverse; }
    .sm-flex-row { flex-direction: row; }
    .sm-flex-row-reverse { flex-direction: row-reverse; }

    .sm-flex-no-wrap { flex-wrap: nowrap; }
    .sm-flex-wrap { flex-wrap: wrap; }
    .sm-flex-wrap-reverse { flex-wrap: wrap-reverse; }

    .sm-items-baseline { align-items: baseline; }
    .sm-items-center { align-items: center; }
    .sm-items-end { align-items: flex-end; }
    .sm-items-start { align-items: flex-start; }
    .sm-items-stretch { align-items: stretch; }

    .sm-content-around { align-content: space-around; }
    .sm-content-between { align-content: space-between; }
    .sm-content-center { align-content: center; }
    .sm-content-end { align-content: flex-end; }
    .sm-content-start { align-content: flex-start; }

    .sm-self-auto { align-self: auto; }
    .sm-self-center { align-self: center; }
    .sm-self-end { align-self: flex-end; }
    .sm-self-start { align-self: flex-start; }
    .sm-self-stretch { align-self: stretch; }

    .sm-justify-around { justify-content: space-around; }
    .sm-justify-between { justify-content: space-between; }
    .sm-justify-center { justify-content: center; }
    .sm-justify-end { justify-content: flex-end; }
    .sm-justify-start { justify-content: flex-start; }

    .sm-flex-grow { flex-grow: 1; }
    .sm-flex-grow-0 { flex-grow: 0; }

    .sm-flex-shrink { flex-shrink: 1; }
    .sm-flex-shrink-0 { flex-shrink: 0; }

    .sm-order-1 { order: 1; }
    .sm-order-2 { order: 2; }
    .sm-order-3 { order: 3; }
    .sm-order-4 { order: 4; }
    .sm-order-5 { order: 5; }
    .sm-order-6 { order: 6; }
    .sm-order-7 { order: 7; }
    .sm-order-8 { order: 8; }
    .sm-order-9 { order: 9; }
    .sm-order-10 { order: 10; }
    .sm-order-11 { order: 11; }
    .sm-order-12 { order: 12; }
    .sm-order-first { order: -9999; }
    .sm-order-last { order: 9999; }
    .sm-order-none { order: 0; }
}

@media (--breakpoint-md) {
    .md-flex-initial { flex: 0 1 auto; }
    .md-flex-1 { flex: 1 1 0%; }
    .md-flex-auto { flex: 1 1 auto; }
    .md-flex-none { flex: none; }

    .md-flex-col { flex-direction: column; }
    .md-flex-col-reverse { flex-direction: column-reverse; }
    .md-flex-row { flex-direction: row; }
    .md-flex-row-reverse { flex-direction: row-reverse; }

    .md-flex-no-wrap { flex-wrap: nowrap; }
    .md-flex-wrap { flex-wrap: wrap; }
    .md-flex-wrap-reverse { flex-wrap: wrap-reverse; }

    .md-items-baseline { align-items: baseline; }
    .md-items-center { align-items: center; }
    .md-items-end { align-items: flex-end; }
    .md-items-start { align-items: flex-start; }
    .md-items-stretch { align-items: stretch; }

    .md-content-around { align-content: space-around; }
    .md-content-between { align-content: space-between; }
    .md-content-center { align-content: center; }
    .md-content-end { align-content: flex-end; }
    .md-content-start { align-content: flex-start; }

    .md-self-auto { align-self: auto; }
    .md-self-center { align-self: center; }
    .md-self-end { align-self: flex-end; }
    .md-self-start { align-self: flex-start; }
    .md-self-stretch { align-self: stretch; }

    .md-justify-around { justify-content: space-around; }
    .md-justify-between { justify-content: space-between; }
    .md-justify-center { justify-content: center; }
    .md-justify-end { justify-content: flex-end; }
    .md-justify-start { justify-content: flex-start; }

    .md-flex-grow { flex-grow: 1; }
    .md-flex-grow-0 { flex-grow: 0; }

    .md-flex-shrink { flex-shrink: 1; }
    .md-flex-shrink-0 { flex-shrink: 0; }

    .md-order-1 { order: 1; }
    .md-order-2 { order: 2; }
    .md-order-3 { order: 3; }
    .md-order-4 { order: 4; }
    .md-order-5 { order: 5; }
    .md-order-6 { order: 6; }
    .md-order-7 { order: 7; }
    .md-order-8 { order: 8; }
    .md-order-9 { order: 9; }
    .md-order-10 { order: 10; }
    .md-order-11 { order: 11; }
    .md-order-12 { order: 12; }
    .md-order-first { order: -9999; }
    .md-order-last { order: 9999; }
    .md-order-none { order: 0; }
}

@media (--breakpoint-lg) {
    .lg-flex-initial { flex: 0 1 auto; }
    .lg-flex-1 { flex: 1 1 0%; }
    .lg-flex-auto { flex: 1 1 auto; }
    .lg-flex-none { flex: none; }

    .lg-flex-col { flex-direction: column; }
    .lg-flex-col-reverse { flex-direction: column-reverse; }
    .lg-flex-row { flex-direction: row; }
    .lg-flex-row-reverse { flex-direction: row-reverse; }

    .lg-flex-no-wrap { flex-wrap: nowrap; }
    .lg-flex-wrap { flex-wrap: wrap; }
    .lg-flex-wrap-reverse { flex-wrap: wrap-reverse; }

    .lg-items-baseline { align-items: baseline; }
    .lg-items-center { align-items: center; }
    .lg-items-end { align-items: flex-end; }
    .lg-items-start { align-items: flex-start; }
    .lg-items-stretch { align-items: stretch; }

    .lg-content-around { align-content: space-around; }
    .lg-content-between { align-content: space-between; }
    .lg-content-center { align-content: center; }
    .lg-content-end { align-content: flex-end; }
    .lg-content-start { align-content: flex-start; }

    .lg-self-auto { align-self: auto; }
    .lg-self-center { align-self: center; }
    .lg-self-end { align-self: flex-end; }
    .lg-self-start { align-self: flex-start; }
    .lg-self-stretch { align-self: stretch; }

    .lg-justify-around { justify-content: space-around; }
    .lg-justify-between { justify-content: space-between; }
    .lg-justify-center { justify-content: center; }
    .lg-justify-end { justify-content: flex-end; }
    .lg-justify-start { justify-content: flex-start; }

    .lg-flex-grow { flex-grow: 1; }
    .lg-flex-grow-0 { flex-grow: 0; }

    .lg-flex-shrink { flex-shrink: 1; }
    .lg-flex-shrink-0 { flex-shrink: 0; }

    .lg-order-1 { order: 1; }
    .lg-order-2 { order: 2; }
    .lg-order-3 { order: 3; }
    .lg-order-4 { order: 4; }
    .lg-order-5 { order: 5; }
    .lg-order-6 { order: 6; }
    .lg-order-7 { order: 7; }
    .lg-order-8 { order: 8; }
    .lg-order-9 { order: 9; }
    .lg-order-10 { order: 10; }
    .lg-order-11 { order: 11; }
    .lg-order-12 { order: 12; }
    .lg-order-first { order: -9999; }
    .lg-order-last { order: 9999; }
    .lg-order-none { order: 0; }
}
