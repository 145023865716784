/*
 * Grid
 */

.col {
    float: left;
    box-sizing: border-box;
}

.col-right {
    float: right;
    box-sizing: border-box;
}

.col-1 { width: calc(1 / 12 * 100%); }
.col-2 { width: calc(2 / 12 * 100%); }
.col-3 { width: calc(3 / 12 * 100%); }
.col-4 { width: calc(4 / 12 * 100%); }
.col-5 { width: calc(5 / 12 * 100%); }
.col-6 { width: calc(6 / 12 * 100%); }
.col-7 { width: calc(7 / 12 * 100%); }
.col-8 { width: calc(8 / 12 * 100%); }
.col-9 { width: calc(9 / 12 * 100%); }
.col-10 { width: calc(10 / 12 * 100%); }
.col-11 { width: calc(11 / 12 * 100%); }
.col-12 { width: 100%; }

@media (--breakpoint-sm) {
    .sm-col {
        float: left;
        box-sizing: border-box;
    }

    .sm-col-right {
        float: right;
        box-sizing: border-box;
    }

    .sm-col-1 { width: calc(1 / 12 * 100%); }
    .sm-col-2 { width: calc(2 / 12 * 100%); }
    .sm-col-3 { width: calc(3 / 12 * 100%); }
    .sm-col-4 { width: calc(4 / 12 * 100%); }
    .sm-col-5 { width: calc(5 / 12 * 100%); }
    .sm-col-6 { width: calc(6 / 12 * 100%); }
    .sm-col-7 { width: calc(7 / 12 * 100%); }
    .sm-col-8 { width: calc(8 / 12 * 100%); }
    .sm-col-9 { width: calc(9 / 12 * 100%); }
    .sm-col-10 { width: calc(10 / 12 * 100%); }
    .sm-col-11 { width: calc(11 / 12 * 100%); }
    .sm-col-12 { width: 100%; }

    .sm-width-auto { width: auto; }
}

@media (--breakpoint-md) {
    .md-col {
        float: left;
        box-sizing: border-box;
    }

    .md-col-right {
        float: right;
        box-sizing: border-box;
    }

    .md-col-1 { width: calc(1 / 12 * 100%); }
    .md-col-2 { width: calc(2 / 12 * 100%); }
    .md-col-3 { width: calc(3 / 12 * 100%); }
    .md-col-4 { width: calc(4 / 12 * 100%); }
    .md-col-5 { width: calc(5 / 12 * 100%); }
    .md-col-6 { width: calc(6 / 12 * 100%); }
    .md-col-7 { width: calc(7 / 12 * 100%); }
    .md-col-8 { width: calc(8 / 12 * 100%); }
    .md-col-9 { width: calc(9 / 12 * 100%); }
    .md-col-10 { width: calc(10 / 12 * 100%); }
    .md-col-11 { width: calc(11 / 12 * 100%); }
    .md-col-12 { width: 100%; }

    .md-width-auto { width: auto; }
}

@media (--breakpoint-lg) {
    .lg-col {
        float: left;
        box-sizing: border-box;
    }

    .lg-col-right {
        float: right;
        box-sizing: border-box;
    }

    .lg-col-1 { width: calc(1 / 12 * 100%); }
    .lg-col-2 { width: calc(2 / 12 * 100%); }
    .lg-col-3 { width: calc(3 / 12 * 100%); }
    .lg-col-4 { width: calc(4 / 12 * 100%); }
    .lg-col-5 { width: calc(5 / 12 * 100%); }
    .lg-col-6 { width: calc(6 / 12 * 100%); }
    .lg-col-7 { width: calc(7 / 12 * 100%); }
    .lg-col-8 { width: calc(8 / 12 * 100%); }
    .lg-col-9 { width: calc(9 / 12 * 100%); }
    .lg-col-10 { width: calc(10 / 12 * 100%); }
    .lg-col-11 { width: calc(11 / 12 * 100%); }
    .lg-col-12 { width: 100%; }

    .lg-width-auto { width: auto; }
}
