/*
 * Borders
 */

.rounded-full {
    border-radius: 9999px;
}

.border {
    border-width: 1px;
    border-style: solid;
}

.border-radius-50 {
    border-radius: 50%;
}