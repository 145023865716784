/*
 * Layout
 */

:root {
    --width-1: 24rem;
    --width-2: 32rem;
    --width-3: 48rem;
    --width-4: 64rem;
}

html { box-sizing: border-box; }

*,
*:before,
*:after {
    box-sizing: inherit;
}

.overflow-auto { overflow: auto; }
.overflow-hidden { overflow: hidden; }
.overflow-scroll { overflow: scroll; }
.overflow-x-auto { overflow-x: auto; }
.overflow-x-hidden { overflow-x: hidden; }
.overflow-x-scroll { overflow-x: scroll; }
.overflow-y-auto { overflow-y: auto; }
.overflow-y-hidden { overflow-y: hidden; }
.overflow-y-scroll { overflow-y: scroll; }

.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after { clear: both; }

.max-width-1 { max-width: var(--width-1); }
.max-width-2 { max-width: var(--width-2); }
.max-width-3 { max-width: var(--width-3); }
.max-width-4 { max-width: var(--width-4); }

.not-empty {
    min-height: 1px;
}

.opacity-0 { opacity: 0; }
.opacity-1 { opacity: 1; }

.height-0 { height: 0; }
.height-100 { height: 100%; }
.height-100vh { height: 100vh; }

.v-bottom { vertical-align: bottom; }
.v-middle { vertical-align: middle; }
.v-top { vertical-align: top; }

.block { display: block; }
.flex { display: flex; }
.inline { display: inline; }
.inline-block { display: inline-block; }
.inline-flex { display: inline-flex; }
.table { display: table; }
.table-cell { display: table-cell; }
.table-column { display: table-column; }
.table-row { display: table-row; }

.left { float: left; }
.right { float: right; }

.width-100 { width: 100%; }

.lg-show { display: none; }
.lg-hide { display: block; }

.md-show { display: none; }
.md-hide { display: block; }

.sm-show { display: none; }
.sm-hide { display: block; }

.show { display: block; }
.hide { display: none; }

@media (--breakpoint-sm) {
    .sm-block { display: block; }
    .sm-flex { display: flex; }
    .sm-inline { display: inline; }
    .sm-inline-block { display: inline-block; }
    .sm-inline-flex { display: inline-flex; }
    .sm-table { display: table; }
    .sm-table-cell { display: table-cell; }
    .sm-table-column { display: table-column; }
    .sm-table-row { display: table-row; }

    .lg-show { display: none; }
    .lg-hide { display: block; }

    .md-show { display: none; }
    .md-hide { display: block; }

    .show { display: block; }
    .hide { display: none; }

    .sm-show { display: block; }
    .sm-hide { display: none; }
}

@media (--breakpoint-md) {
    .md-block { display: block; }
    .md-flex { display: flex; }
    .md-inline { display: inline; }
    .md-inline-block { display: inline-block; }
    .md-inline-flex { display: inline-flex; }
    .md-table { display: table; }
    .md-table-cell { display: table-cell; }
    .md-table-column { display: table-column; }
    .md-table-row { display: table-row; }

    .lg-show { display: none; }
    .lg-hide { display: block; }

    .show { display: block; }
    .hide { display: none; }

    .sm-show { display: block; }
    .sm-hide { display: none; }

    .md-show { display: block; }
    .md-hide { display: none; }
}

@media (--breakpoint-lg) {
    .lg-block { display: block; }
    .lg-flex { display: flex; }
    .lg-inline { display: inline; }
    .lg-inline-block { display: inline-block; }
    .lg-inline-flex { display: inline-flex; }
    .lg-table { display: table; }
    .lg-table-cell { display: table-cell; }
    .lg-table-column { display: table-column; }
    .lg-table-row { display: table-row; }

    .lg-left { float: left; }
    .lg-right { float: right; }

    .show { display: block; }
    .hide { display: none; }

    .sm-show { display: block; }
    .sm-hide { display: none; }

    .md-show { display: block; }
    .md-hide { display: none; }

    .lg-show { display: block; }
    .lg-hide { display: none; }
}
