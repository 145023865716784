/*
 * Custom Scrollbar
 */

.custom-scrollbar,
.custom-scrollbar > .custom-scrollbar__inner {
    transform: rotateX(180deg);
}

.custom-scrollbar::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: var(--black-20);
    border-radius: 1px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border-radius: 1px;
}
