/*
 * Borders
 */

:root {
    --border-width: 1px;
}

.circle { border-radius: 50%; }

.border-0 { border: 0; }

.border {
    border-style: solid;
    border-width: var(--border-width);
}

.border-top {
    border-top-style: solid;
    border-top-width: var(--border-width);
}

.border-right {
    border-right-style: solid;
    border-right-width: var(--border-width);
}

.border-bottom {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
}

.border-left {
    border-left-style: solid;
    border-left-width: var(--border-width);
}

@media (--breakpoint-sm) {
    .sm-border-0 { border: 0; }

    .sm-border {
        border-style: solid;
        border-width: var(--border-width);
    }

    .sm-border-top {
        border-top-style: solid;
        border-top-width: var(--border-width);
    }

    .sm-border-right {
        border-right-style: solid;
        border-right-width: var(--border-width);
    }

    .sm-border-bottom {
        border-bottom-style: solid;
        border-bottom-width: var(--border-width);
    }

    .sm-border-left {
        border-left-style: solid;
        border-left-width: var(--border-width);
    }
}

@media (--breakpoint-md) {
    .md-border-0 { border: 0; }

    .md-border {
        border-style: solid;
        border-width: var(--border-width);
    }

    .md-border-top {
        border-top-style: solid;
        border-top-width: var(--border-width);
    }

    .md-border-right {
        border-right-style: solid;
        border-right-width: var(--border-width);
    }

    .md-border-bottom {
        border-bottom-style: solid;
        border-bottom-width: var(--border-width);
    }

    .md-border-left {
        border-left-style: solid;
        border-left-width: var(--border-width);
    }
}

@media (--breakpoint-lg) {
    .lg-border-0 { border: 0; }

    .lg-border {
        border-style: solid;
        border-width: var(--border-width);
    }

    .lg-border-top {
        border-top-style: solid;
        border-top-width: var(--border-width);
    }

    .lg-border-right {
        border-right-style: solid;
        border-right-width: var(--border-width);
    }

    .lg-border-bottom {
        border-bottom-style: solid;
        border-bottom-width: var(--border-width);
    }

    .lg-border-left {
        border-left-style: solid;
        border-left-width: var(--border-width);
    }
}
