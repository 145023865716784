/*
 * Z-index
 */

:root {
    --z1: 1;
    --z2: 2;
    --z3: 3;
    --z4: 4;
    --z5: 5;
    --z6: 6;
}

.z1 { z-index: var(--z1); }
.z2 { z-index: var(--z2); }
.z3 { z-index: var(--z3); }
.z4 { z-index: var(--z4); }
.z5 { z-index: var(--z5); }
.z6 { z-index: var(--z6); }
