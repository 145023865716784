/*
 * Icons
 */

 .transition-background-color {
     transition-property: background-color;
     transition-duration: 0.15s;
     transition-timing-function: ease-out;
 }

.hover-underline:hover {
    text-decoration: underline;
}

.transition-color {
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: ease;
}

.transition-opacity {
    transition: opacity 0.2s ease;
}

.transition-border-color {
    transition: border-color 0.2s ease;
}

.transition-all {
    transition-property: all;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

/* ========================================================================= */
/* Scales/translates */
/* ========================================================================= */

.scope-scaler__media,
.scope-translate-left__media,
.scope-translate-left__target {
    overflow: hidden;
    transform-origin: center;
}

.scope-scaler__media > * {
    transform: scale(1);
    transition: transform 0.2s ease-out;
}

.scope-scaler:hover .scope-scaler__media > * {
    transform: scale(1.02);
    transition: transform .3s ease-out;
}

@media (--breakpoint-md) {
    .scope-translate-left__media > *,
    .scope-translate-left__media .scope-translate-left__target {
        width: 145%;
        transform: translateX(0) scale(1);
        transition: transform 0.4s ease-out, opacity 0.2s ease-out;
    }

    .scope-translate-left:hover .scope-translate-left__media > *,
    .scope-translate-left:hover .scope-translate-left__target {
        transform: translateX(-15%) scale(1);
        transition:
            transform 2s cubic-bezier(0.25, 1, 0.5, 1),
            opacity 0.2s ease-out;
    }
}

/* ========================================================================= */
/* States */
/* ========================================================================= */

.is-dimmed {
    opacity: var(--muted);
    pointer-events: none;
}

/* ========================================================================= */
/* Animations */
/* ========================================================================= */

.animation-opacity {
    animation: keyframe-opacity 0.2s ease forwards;
}

@keyframes keyframe-opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.slide-fade-in {
    animation: slide-fade-in 0.4s ease-out 1 forwards;
}

@keyframes slide-fade-in {
    from {
        transform: translateY(3rem);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
