.skip-link {
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--space-2) var(--space-3);
    background-color: #ffffff;
    border: 1px solid #000000;
    transform: translate(-110%, 0);
    transform: translate(calc(-100% - 1px), 0);
}

.skip-link:focus {
    transform: translate(0, 0);
}

*:focus-visible:not([tabindex = '-1']) {
    outline: auto !important;
}

/* ========================================================================= */
/* Display */
/* ========================================================================= */


[hidden] {
    display: none !important;
}

/**
 * If the hidden element receives any kind of focus/active state,
 * we display the related content on screen.
 */
.sm-accessible-hide:focus,
.sm-accessible-hide:focus-within,
.sm-accessible-hide:active {
    display: unset;
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    clip-path: unset;
    white-space: normal;
}

@media (--breakpoint-sm) {
    .sm-accessible-hide:not(:focus):not(:focus-within):not(:active) {
        clip-path: inset(1px);
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        display: block;
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .sm-accessible-hide-reset {
        display: unset;
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        clip-path: unset;
        white-space: normal;
    }
}

@media (--breakpoint-md) {
    .md-accessible-hide:not(:focus):not(:focus-within):not(:active) {
        clip-path: inset(1px);
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        display: block;
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .md-accessible-hide-reset {
        display: unset;
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        clip-path: unset;
        white-space: normal;
    }
}

@media (--breakpoint-lg) {
    .lg-accessible-hide:not(:focus):not(:focus-within):not(:active) {
        clip-path: inset(1px);
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        display: block;
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .lg-accessible-hide-reset {
        display: unset;
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        clip-path: unset;
        white-space: normal;
    }
}

/**
 * When checkbox/radio element is visually element, we must put the visual focus
 * state on the label to preserve the accessibility.
 */
.accessible-hide[type='checkbox']:focus-visible ~ label,
.accessible-hide[type='radio']:focus-visible ~ label {
    outline: 3px solid currentColor;
}

/* ========================================================================= */
/* Expand interactions */
/* ========================================================================= */

/**
 * Expands the clickable area of the nested link to the current element.
 * This technique is useful to ensure the link labels are optimized for tools
 * such as screenreaders.
 * 1. Expands the main action to the container boundaries of its container.
 * 2. Places all other links on top of the expanded action (to preserve their ‘clickability’).
 */
 .expand-interaction {
    position: relative;
}

/* [1] */
.expand-interaction .expand-interaction__action::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

/* [2] */
.expand-interaction a:not(.expand-interaction__action),
.expand-interaction button:not(.expand-interaction__action) {
    position: relative;
    z-index: 2;
}