/*
 * Colours
 */

:root {
    --black: #272727;
    --black-40: #27272766;
    --black-30: #27272766;
    --black-20: #27272733;
    --black-30: #2727274d;
    --black-10: #2727271a;
    --heather: #ebeaee;
    --heather-20: #ebeaee33;
    --white: #ffffff;
    --white-40: #ffffff40;
    --acid: #edff17;

    --sage: #92917b;
    --sage-40: #92917b66;
    --sand: #d9cdbb;
    --umber: #e5dddf;
    --steel: #b9bdbb;
}

.bg-none { background: none; }
.bg-transparent { background: transparent; }
.border-transparent { border-color: transparent; }

.black { color: var(--black); }
.black-40 { color: var(--black-40); }
.black-30 { color: var(--black-30); }
.black-20 { color: var(--black-20); }
.black-10 { color: var(--black-10); }
.heather { color: var(--heather); }
.heather-20 { color: var(--heather-20); }
.white { color: var(--white); }
.white-40 { color: var(--white-40); }
.acid { color: var(--acid); }
.sage { color: var(--sage); }
.sage-40 { color: var(--sage-40); }
.sand { color: var(--sand); }
.stone { color: var(--stone); }
.umber { color: var(--umber); }
.steel { color: var(--steel); }

.bg-black { background-color: var(--black); }
.bg-black-40 { background-color: var(--black-40); }
.bg-black-30 { background-color: var(--black-30); }
.bg-black-20 { background-color: var(--black-20); }
.bg-black-10 { background-color: var(--black-10); }
.bg-heather { background-color: var(--heather); }
.bg-heather-20 { background-color: var(--heather-20); }
.bg-white { background-color: var(--white); }
.bg-acid { background-color: var(--acid); }
.bg-sage { background-color: var(--sage); }
.bg-sage-40 { background-color: var(--sage-40); }
.bg-sand { background-color: var(--sand); }
.bg-stone { background-color: var(--stone); }
.bg-umber { background-color: var(--umber); }
.bg-steel { background-color: var(--steel); }
.bg-white { background-color: var(--white); }

.border-black { border-color: var(--black); }
.border-black-40 { border-color: var(--black-40); }
.border-black-30 { border-color: var(--black-30); }
.border-black-20 { border-color: var(--black-20); }
.border-black-10 { border-color: var(--black-10); }
.border-heather { border-color: var(--heather); }
.border-heather-20 { border-color: var(--heather-20); }
.border-white { border-color: var(--white); }
.border-acid { border-color: var(--acid); }
.border-sage { border-color: var(--sage); }
.border-sage-40 { border-color: var(--sage-40); }
.border-sand { border-color: var(--sand); }
.border-stone { border-color: var(--stone); }
.border-umber { border-color: var(--umber); }
.border-steel { border-color: var(--steel); }

@media (--breakpoint-sm) {

    .sm-bg-none { background: none; }

    .sm-black { color: var(--black); }
    .sm-black-40 { color: var(--black-40); }
    .sm-black-30 { color: var(--black-30); }
    .sm-black-20 { color: var(--black-20); }
    .sm-black-10 { color: var(--black-10); }
    .sm-heather { color: var(--heather); }
    .sm-heather-20 { color: var(--heather-20); }
    .sm-white { color: var(--white); }
    .sm-acid { color: var(--acid); }
    .sm-sage { color: var(--sage); }
    .sm-sage-40 { color: var(--sage-40); }
    .sm-sand { color: var(--sand); }
    .sm-stone { color: var(--stone); }
    .sm-umber { color: var(--umber); }
    .sm-steel { color: var(--steel); }

    .sm-bg-black { background-color: var(--black); }
    .sm-bg-black-40 { background-color: var(--black-40); }
    .sm-bg-black-30 { background-color: var(--black-30); }
    .sm-bg-black-20 { background-color: var(--black-20); }
    .sm-bg-black-10 { background-color: var(--black-10); }
    .sm-bg-heather { background-color: var(--heather); }
    .sm-bg-heather-20 { background-color: var(--heather-20); }
    .sm-bg-white { background-color: var(--white); }
    .sm-bg-acid { background-color: var(--acid); }
    .sm-bg-sage { background-color: var(--sage); }
    .sm-bg-sage-40 { background-color: var(--sage-40); }
    .sm-bg-sand { background-color: var(--sand); }
    .sm-bg-stone { background-color: var(--stone); }
    .sm-bg-umber { background-color: var(--umber); }
    .sm-bg-steel { background-color: var(--steel); }

    .sm-border-black { border-color: var(--black); }
    .sm-border-black-40 { border-color: var(--black-40); }
    .sm-border-black-30 { border-color: var(--black-30); }
    .sm-border-black-20 { border-color: var(--black-20); }
    .sm-border-black-10 { border-color: var(--black-10); }
    .sm-border-heather { border-color: var(--heather); }
    .sm-border-heather-20 { border-color: var(--heather-20); }
    .sm-border-white { border-color: var(--white); }
    .sm-border-acid { border-color: var(--acid); }
    .sm-border-sage { border-color: var(--sage); }
    .sm-border-sage-40 { border-color: var(--sage-40); }
    .sm-border-sand { border-color: var(--sand); }
    .sm-border-stone { border-color: var(--stone); }
    .sm-border-umber { border-color: var(--umber); }
    .sm-border-steel { border-color: var(--steel); }
}

@media (--breakpoint-md) {

    .md-bg-none { background: none; }

    .md-bg-transparent { background: transparent; }

    .md-black { color: var(--black); }
    .md-black-40 { color: var(--black-40); }
    .md-black-30 { color: var(--black-30); }
    .md-black-20 { color: var(--black-20); }
    .md-black-10 { color: var(--black-10); }
    .md-heather { color: var(--heather); }
    .md-heather-20 { color: var(--heather-20); }
    .md-white { color: var(--white); }
    .md-acid { color: var(--acid); }
    .md-sage { color: var(--sage); }
    .md-sage-40 { color: var(--sage-40); }
    .md-sand { color: var(--sand); }
    .md-stone { color: var(--stone); }
    .md-umber { color: var(--umber); }
    .md-steel { color: var(--steel); }

    .md-bg-black { background-color: var(--black); }
    .md-bg-black-40 { background-color: var(--black-40); }
    .md-bg-black-30 { background-color: var(--black-30); }
    .md-bg-black-20 { background-color: var(--black-20); }
    .md-bg-black-10 { background-color: var(--black-10); }
    .md-bg-heather { background-color: var(--heather); }
    .md-bg-heather-20 { background-color: var(--heather-20); }
    .md-bg-white { background-color: var(--white); }
    .md-bg-acid { background-color: var(--acid); }
    .md-bg-sage { background-color: var(--sage); }
    .md-bg-sage-40 { background-color: var(--sage-40); }
    .md-bg-sand { background-color: var(--sand); }
    .md-bg-stone { background-color: var(--stone); }
    .md-bg-umber { background-color: var(--umber); }
    .md-bg-steel { background-color: var(--steel); }

    .md-border-black { border-color: var(--black); }
    .md-border-black-40 { border-color: var(--black-40); }
    .md-border-black-30 { border-color: var(--black-30); }
    .md-border-black-20 { border-color: var(--black-20); }
    .md-border-black-10 { border-color: var(--black-10); }
    .md-border-heather { border-color: var(--heather); }
    .md-border-heather-20 { border-color: var(--heather-20); }
    .md-border-white { border-color: var(--white); }
    .md-border-acid { border-color: var(--acid); }
    .md-border-sage { border-color: var(--sage); }
    .md-border-sage-40 { border-color: var(--sage-40); }
    .md-border-sand { border-color: var(--sand); }
    .md-border-stone { border-color: var(--stone); }
    .md-border-umber { border-color: var(--umber); }
    .md-border-steel { border-color: var(--steel); }
}

@media (--breakpoint-lg) {

    .lg-bg-none { background: none; }

    .lg-black { color: var(--black); }
    .lg-black-40 { color: var(--black-40); }
    .lg-black-30 { color: var(--black-30); }
    .lg-black-20 { color: var(--black-20); }
    .lg-black-10 { color: var(--black-10); }
    .lg-heather { color: var(--heather); }
    .lg-heather-20 { color: var(--heather-20); }
    .lg-white { color: var(--white); }
    .lg-acid { color: var(--acid); }
    .lg-sage { color: var(--sage); }
    .lg-sage-40 { color: var(--sage-40); }
    .lg-sand { color: var(--sand); }
    .lg-stone { color: var(--stone); }
    .lg-umber { color: var(--umber); }
    .lg-steel { color: var(--steel); }

    .lg-bg-black { background-color: var(--black); }
    .lg-bg-black-40 { background-color: var(--black-40); }
    .lg-bg-black-30 { background-color: var(--black-30); }
    .lg-bg-black-20 { background-color: var(--black-20); }
    .lg-bg-black-10 { background-color: var(--black-10); }
    .lg-bg-heather { background-color: var(--heather); }
    .lg-bg-heather-20 { background-color: var(--heather-20); }
    .lg-bg-white { background-color: var(--white); }
    .lg-bg-acid { background-color: var(--acid); }
    .lg-bg-sage { background-color: var(--sage); }
    .lg-bg-sage-40 { background-color: var(--sage-40); }
    .lg-bg-sand { background-color: var(--sand); }
    .lg-bg-stone { background-color: var(--stone); }
    .lg-bg-umber { background-color: var(--umber); }
    .lg-bg-steel { background-color: var(--steel); }

    .lg-border-black { border-color: var(--black); }
    .lg-border-black-40 { border-color: var(--black-40); }
    .lg-border-black-30 { border-color: var(--black-30); }
    .lg-border-black-20 { border-color: var(--black-20); }
    .lg-border-black-10 { border-color: var(--black-10); }
    .lg-border-heather { border-color: var(--heather); }
    .lg-border-heather-20 { border-color: var(--heather-20); }
    .lg-border-white { border-color: var(--white); }
    .lg-border-acid { border-color: var(--acid); }
    .lg-border-sage { border-color: var(--sage); }
    .lg-border-sage-40 { border-color: var(--sage-40); }
    .lg-border-sand { border-color: var(--sand); }
    .lg-border-stone { border-color: var(--stone); }
    .lg-border-umber { border-color: var(--umber); }
    .lg-border-steel { border-color: var(--steel); }
}

/* hovers */

.hover-sage:focus,
.hover-sage:hover {
    color: var(--sage);
}

.hover-white:focus,
.hover-white:hover {
    color: var(--white);
}

.hover-bg-acid:focus,
.hover-bg-acid:hover {
    background-color: var(--acid);
}

@media (--breakpoint-md) {
    .md-hover-bg-acid:focus,
    .md-hover-bg-acid:hover {
        background-color: var(--acid);
    }
}

.hover-bg-black:focus,
.hover-bg-black:hover {
    background-color: var(--black);
}

.hover-border-acid:focus,
.hover-border-acid:hover {
    border-color: var(--acid);
}

.hover-border-black:focus,
.hover-border-black:hover {
    border-color: var(--black);
}

.hover-black:focus,
.hover-black:hover {
    color: var(--black);
}

.hover-border-black:focus,
.hover-border-black:hover {
    border-color: var(--black);
}

.hover-fill-white:focus path,
.hover-fill-white:hover path {
    fill: var(--white) !important;
}

.hover-stroke-white:focus path,
.hover-stroke-white:hover path {
    stroke: var(--white) !important;
}
/* opacity */

.opacity-05 { opacity: 0.05; }
.opacity-10 { opacity: 0.1; }
.opacity-20 { opacity: 0.2; }
.opacity-40 { opacity: 0.4; }
.opacity-60 { opacity: 0.6; }
.opacity-80 { opacity: 0.8; }

/* underline */

.underline-acid {
    text-decoration-color: var(--acid);
}

/* Pills */
.pill-counter__counter {
    color: var(--black-40);
}

.pill-counter:hover .pill-counter__counter,
.pill-counter:focus .pill-counter__counter {
    color: var(--white-40);
}