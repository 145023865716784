/*
 * Layout
 */

 .hero-slides-wrapper {
     margin-top: -14.3rem;
 }

@media (--breakpoint-sm) {
    .hero-slides-wrapper {
        margin-top: -16.3rem;
    }
}

@media (--breakpoint-md) {
    .hero-slides-wrapper {
        margin-top: 0;
        height: calc(300vw + 100vh - 100vw);
    }

    .hero-slides-section {
        clip: rect(0, auto, auto, 0);
        /* Safari fix */
        -webkit-mask-image:
            -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    }
}

@media (--breakpoint-xs-only) {
    .hero-slides-container {
        transform: translate3d(0, 0, 0) !important;
    }
}

@media (--breakpoint-sm-only) {
    .hero-slides-container {
        transform: translate3d(0, 0, 0) !important;
    }
}

.hero-slide {
    width: 100vw;
}

@media (--breakpoint-md) {
    .hero-slide {
        width: calc(150vw + 60px);
    }
}

@media (--breakpoint-md) {
    .hero-slide {
        width: calc(150vw + 100px);
    }
}
