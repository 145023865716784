/*
 *  Reset
 */

.link-reset {
    text-decoration: none;
    color: inherit;
}

.button-reset {
    border: none;
    appearance: none;
    color: currentColor;
}

.button-reset:not(:disabled) {
    cursor: pointer;
}

.form-field-reset {
    color: inherit;
}
