.duotone-umber-grey__overlay-1,
.duotone-umber-grey__overlay-2,
.duotone-steel-darker-grey__overlay-1,
.duotone-steel-darker-grey__overlay-2 {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* Umber – Grey */

.duotone-umber-grey__overlay-1 {
    background-color: var(--umber);
    mix-blend-mode: color;
}

.duotone-umber-grey__overlay-2 {
    background-color: #3c3c3c;
    mix-blend-mode: lighten;
}

/* Steel – Darker grey */

.duotone-steel-darker-grey__overlay-1 {
    background-color: var(--steel);
    mix-blend-mode: color;
}

.duotone-steel-darker-grey__overlay-2 {
    background-color: #1f1f1f;
    mix-blend-mode: lighten;
}

/* Disable overlay for ios and safari devices due to rendering bugs */

html[class *= 'safari'] .duotone-umber-grey__overlay-1,
html[class *= 'safari'] .duotone-umber-grey__overlay-2,
html[class *= 'safari'] .duotone-steel-darker-grey__overlay-1,
html[class *= 'safari'] .duotone-steel-darker-grey__overlay-2,
html[class *= 'ios'] .duotone-umber-grey__overlay-1,
html[class *= 'ios'] .duotone-umber-grey__overlay-2,
html[class *= 'ios'] .duotone-steel-darker-grey__overlay-1,
html[class *= 'ios'] .duotone-steel-darker-grey__overlay-2 {
    display: none !important;
}

/* ClientListing hover image reveal */

.client-reveal-image__contaier .client-reveal-image {
    opacity: 0;
    transform-style: preserve-3d;
    perspective-origin: 50% 50%;
    transform:  perspective(1000px)
                translate3d(0, -20%, -50px)
                rotateX(24deg)
                scale(0.9, 0.9);
    transition: all 0.15s ease-out;
}

.client-reveal-image__contaier:hover .client-reveal-image {
    opacity: 1;
    transform: translate3d(0, -33%, -250px);
}

/* Module Related Hero Overlay Opacity */

@media (hover: hover) {
    .hover-overlay__target {
        opacity: 0.8;
        transition: opacity 0.2s ease-out;
    }

    .hover-overlay:focus .hover-overlay__target,
    .hover-overlay:hover .hover-overlay__target {
        opacity: 0;
    }
}

@media (hover: none) {
    .hover-overlay__target {
        opacity: 0;
    }
}
