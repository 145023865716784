/*
 * Colours
 */

.flex-2 { flex: 2 2 0%; }
.flex-6 { flex: 6 6 0%; }

@media (--breakpoint-sm) {
.sm-flex-2 { flex: 2 2 0%; }
.sm-flex-6 { flex: 6 6 0%; }
}

@media (--breakpoint-md) {
.md-flex-2 { flex: 2 2 0%; }
.md-flex-6 { flex: 6 6 0%; }
}

@media (--breakpoint-lg) {
.lg-flex-2 { flex: 2 2 0%; }
.lg-flex-6 { flex: 6 6 0%; }
}
