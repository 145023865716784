/*
 * oEmbed
 */

.oembed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.oembed iframe,
.oembed object,
.oembed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
