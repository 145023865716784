/*
 * Cookiebot
 */

/* Remove credit footer */

#CybotCookiebotDialogPoweredbyLink,
#CybotCookiebotDialogDetailFooter {
    display: none !important;
}

#CybotCookiebotDialog * {
    color: var(--white);
    box-sizing: border-box !important;
}

/* Typefaces */

#CybotCookiebotDialogBodyContentText,
#CybotCookiebotDialogDetailBodyContentTextAbout {
    font: var(--type-style-5-weight) var(--type-style-5-size) / var(--type-style-5-line-height)  var(--type-style-5-family) !important;
    letter-spacing: var(--type-style-5-letter-spacing) !important;
}

#CybotCookiebotDialog h2 {
    font: var(--type-style-3-weight) var(--type-style-3-size) / var(--type-style-3-line-height)  var(--type-style-3-family) !important;
    letter-spacing: var(--type-style-3-letter-spacing) !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
}

/* Container */

#CybotCookiebotDialog {
    box-shadow: none !important;
    border-top: 1px solid var(--black-10) !important;
    transform: none !important;
    background-color: var(--black) !important;
    max-width: 100vw !important;
    overflow: auto !important;
    max-height: 100vh !important;
    padding-bottom: 50px !important;
    padding-bottom: var(--space-7) !important;
}

#CybotCookiebotDialogDetailBodyContentTextOverview {
    max-width: 100% !important;
}

#CybotCookiebotDialogBodyContent,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyLevelWrapper,
#CybotCookiebotDialogBodyButtons {
    padding: 0 !important;
}

#CybotCookiebotDialogBodyContent {
    margin-top: 0 !important;
    margin-bottom: var(--space-5) !important;
}

#CybotCookiebotDialogDetailBody {
    margin-top: var(--space-7) !important;
}

#CybotCookiebotDialogBody,
#CybotCookiebotDialogDetail {
    max-width: var(--container-width) !important;
    padding: 0 var(--space-5) !important;
}

#CybotCookiebotDialogDetail,
#CybotCookiebotDialogDetail * {
    font-family: var(--type-style-4-family) !important;
    font-weight: var(--type-style-4-weight) !important;
    letter-spacing: var(--type-style-4-letter-spacing) !important;
    text-transform: var(--type-style-4-text-transform) !important;
}

#CybotCookiebotDialogDetail {
    padding-bottom: var(--space-7) !important;
    overflow: visible !important;
    background-color: var(--black) !important;
}

#CybotCookiebotDialogDetailBodyContent {
    border-width: 1px !important;
}

/* Buttons */

.CybotCookiebotDialogBodyButton,
.CybotCookiebotDialogBodyLink {
    border-radius: 9999px;
}

#CybotCookiebotDialogBodyButtons {
    margin: 0 !important;
}

#CybotCookiebotDialogBodyButtons a:not(:last-child) {
    margin-bottom: 10px !important;
}

#CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll,
#CybotCookiebotDialogBodyButtonDetails {
    background: var(--acid) !important;
    color: var(--black) !important;
    font: var(--type-style-4-weight) var(--type-style-4-size) / var(--type-style-4-line-height)  var(--type-style-4-family) !important;
    letter-spacing: var(--type-style-4-letter-spacing) !important;
    padding: 1.4rem 2rem !important;
    width: auto !important;
    float: left !important;
    margin: 0 !important;
    margin-right: 20px !important;
    border: 0 !important;
    text-align: left !important;
    transition: background 0.2s cubic-bezier(0.45, 0.34, 0.65, 0.96);
}

#CybotCookiebotDialogBodyButtonAccept:hover,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover,
#CybotCookiebotDialogBodyButtonDetails:hover {
    color: var(--black) !important;
    background-color: var(--white) !important;
    text-decoration: none !important;
}
/* More details button */

/* #CybotCookiebotDialogBodyButtonDetails {
    background: var(--acid) !important;
    border: 1px solid var(--black-50) !important;
    padding: 0.875rem 1.25rem !important;
    transition: border-color 0.1s cubic-bezier(0.45, 0.34, 0.65, 0.96);
}

 {
    border: 1px solid var(--black) !important;

} */

/* Table */

.CybotCookiebotDialogDetailBodyContentTab {
    border-radius: 0 !important;
}

.CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
    font: 14px / 1.45  var(--serif) !important;
    letter-spacing: var(--body-letter-spacing) !important;
}

/* Checkbox icons */

input[type = 'checkbox'].CybotCookiebotDialogBodyLevelButton + label { /* Unchecked */
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23E4E4E4' d='M0 0h14v14H0z' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
}

input[type = 'checkbox'].CybotCookiebotDialogBodyLevelButton:checked + label { /* Checked */
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23edff17' d='M0 0h14v14H0z'/%3E%3Cpath stroke='%23272727' stroke-width='2' d='M11.39 3.5L4.89 10 2.5 7.61'/%3E%3C/g%3E%3C/svg%3E") !important;
}

input[type = 'checkbox'].CybotCookiebotDialogBodyLevelButton.CybotCookiebotDialogBodyLevelButtonDisabled + label { /* Checked disabled */
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23BEBEBE' d='M0 0h14v14H0z'/%3E%3Cpath stroke='%23FFF' stroke-width='2' d='M11.39 3.5L4.89 10 2.5 7.61'/%3E%3C/g%3E%3C/svg%3E") !important;
}

@media (--breakpoint-sm) {
    #CybotCookiebotDialogBodyContent,
    #CybotCookiebotDialogBodyContentControls,
    #CybotCookiebotDialogBodyLevelWrapper,
    #CybotCookiebotDialogBodyButtons {
        padding: 0 !important;
    }

    #CybotCookiebotDialog {
        padding-bottom: 60px !important;
    }

    #CybotCookiebotDialogBody,
    #CybotCookiebotDialogDetail {
        padding: 0  var(--space-7) !important;
        margin: 0 auto !important;
    }

    /* Add column spacer */

    #CybotCookiebotDialogBodyContent {
        margin-top: 0 !important;
        margin-bottom: var(--space-7) !important;
    }

    /* Buttons */

    #CybotCookiebotDialogBodyButtons a:not(:last-child) {
        margin-bottom: 0 !important;
    }
}

@media (--breakpoint-md) {
    #CybotCookiebotDialogBodyContent,
    #CybotCookiebotDialogBodyContentControls,
    #CybotCookiebotDialogBodyLevelWrapper,
    #CybotCookiebotDialogBodyButtons,
    #CybotCookiebotDialogDetailBody {
        padding: 0 20px !important;
    }

    #CybotCookiebotDialog h2 {
        font: var(--type-style-3-weight) var(--type-style-3-size) / var(--type-style-3-line-height)  var(--type-style-3-family) !important;
        letter-spacing: var(--type-style-3-letter-spacing) !important;
        margin-top: 0 !important;
        margin-bottom: 20px !important;
    }

    #CybotCookiebotDialogBody,
    #CybotCookiebotDialogDetail {
        padding: 0 var(--space-9) 0 var(--space-15) !important;
    }

    /* Add column spacer */

    #CybotCookiebotDialogBody > *,
    #CybotCookiebotDialogDetail > * {
        margin-left: 0 !important;
    }
}

@media (--breakpoint-lg) {
    #CybotCookiebotDialogBody,
    #CybotCookiebotDialogDetail {
        padding:
            0
            var(--space-9)
            0
            calc(var(--space-9) + var(--space-16)) !important;
    }

    /* Typefaces */

    #CybotCookiebotDialogDetail,
    #CybotCookiebotDialogDetail * {
    font-family: var(--lg-type-style-4-family) !important;
    font-weight: var(--lg-type-style-4-weight) !important;
    letter-spacing: var(--lg-type-style-4-letter-spacing) !important;
    text-transform: var(--lg-type-style-4-text-transform) !important;
}


    #CybotCookiebotDialogBodyContentText,
    #CybotCookiebotDialogDetailBodyContentTextAbout {
        font: var(--lg-type-style-5-weight) var(--lg-type-style-5-size) / var(--lg-type-style-5-line-height)  var(--lg-type-style-5-family) !important;
        letter-spacing: var(--lg-type-style-5-letter-spacing) !important;
    }

    #CybotCookiebotDialog h2 {
        font: var(--lg-type-style-3-weight) var(--lg-type-style-3-size) / var(--lg-type-style-3-line-height)  var(--lg-type-style-3-family) !important;
        letter-spacing: var(--lg-type-style-3-letter-spacing) !important;
        margin-top: 0 !important;
        margin-bottom: 20px !important;
    }

    /* Buttons */

    #CybotCookiebotDialogBodyButtonAccept,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll,
    #CybotCookiebotDialogBodyButtonDetails {
        font: var(--lg-type-style-4-weight) var(--lg-type-style-4-size) / var(--lg-type-style-4-line-height)  var(--lg-type-style-4-family) !important;
        letter-spacing: var(--lg-type-style-4-letter-spacing) !important;
        margin-right: 40px !important;
        padding: 1.4rem 3rem !important;
    }
}
