/* ========================================================================= */
/* Indexes */
/* ========================================================================= */

.z-index-0 { z-index: 0 }
.z-index-1 { z-index: 1 }
.z-index-2 { z-index: 2 }
.z-index-3 { z-index: 3 }
.z-index-4 { z-index: 4 }
.z-index-5 { z-index: 5 }
.z-index-6 { z-index: 6 }
.z-index-7 { z-index: 7 }
.z-index-8 { z-index: 8 }
.z-index-9 { z-index: 9 }
.z-index-10 { z-index: 10 }

/* ========================================================================= */
/* Mouse */
/* ========================================================================= */

.cursor-pointer-none {
    pointer-events: none;
}