/*
 * Layout
 */

 .width-13 {
     width: var(--space-13);
}

.width-8 {
     width: var(--space-8);
}

.height-8 {
     height: var(--space-8);
}

.width-16 {
    width: var(--space-16);
}

.width-10 {
    width: var(--space-10);
}

.height-10 {
    height: var(--space-10);
}

.width-100vw {
    width: 100vw;
}

.width-150vw {
    width: 150vw;
}

.min-height-100vh {
    min-height: 100vh;
}

@media (--breakpoint-sm) {
    .sm-width-12 {
        width: var(--space-12);
    }

    .sm-height-12 {
        height: var(--space-12);
    }
}

@media (--breakpoint-md) {
    .md-width-13 {
        width: var(--space-13);
    }

    .md-height-13 {
        height: var(--space-13);
    }
}

@media (--breakpoint-md) {
    .md-height-100vh {
        height: 100vh;
    }

    .md-height-200vh {
        height: 200vh;
    }
}

.object-cover {
    object-fit: cover;
}

.object-center {
    object-position: center;
}

@media (--breakpoint-sm) {
    .sm-width-13 {
        width: var(--space-13);
    }

    .sm-width-8 {
        width: var(--space-8);
    }

 heightm-width-8 {
        height: var(--space-8);
    }

    .sm-width-16 {
        width: var(--space-16);
    }

    .sm-width-100vw {
        width: 100vw;
    }

    .sm-width-150vw {
        width: 150vw;
    }

    .sm-min-height-100vh {
        min-height: 100vh;
    }
}

@media (--breakpoint-md) {
    .md-width-13 {
        width: var(--space-13);
    }

    .md-width-8 {
        width: var(--space-8);
    }

 heightd-width-8 {
        height: var(--space-8);
    }

    .md-width-16 {
        width: var(--space-16);
    }

    .md-width-100vw {
        width: 100vw;
    }

    .md-width-150vw {
        width: 150vw;
    }

    .md-min-height-100vh {
        min-height: 100vh;
    }
}

@media (--breakpoint-lg) {
    .lg-width-13 {
        width: var(--space-13);
    }

    .lg-width-8 {
        width: var(--space-8);
    }

 heightg-width-8 {
        height: var(--space-8);
    }

    .lg-width-16 {
        width: var(--space-16);
    }

    .lg-width-100vw {
        width: 100vw;
    }

    .lg-width-150vw {
        width: 150vw;
    }

    .lg-min-height-100vh {
        min-height: 100vh;
    }
}

:root {
    --lg-sidebar-width: 19.9rem;
}

@media screen and (min-width: 2200px) {
    .break-container {
        width: calc(100% + 100vw - var(--container-width));
        margin-left: calc(calc(100vw - var(--container-width)) / 2 * -1 - var(--lg-sidebar-width) / 2);
    }
}

.right-side-media img,
.right-side-media video {
    width: 100vw;
}

@media (--breakpoint-md) {
    .right-side-media {
        width: calc(100% / 12 * 5 + var(--space-9));
        margin-right: calc(var(--space-9) * -1);
        position: relative;
        font-size: 0;
    }

    .right-side-media img,
    .right-side-media video {
        height: 100vh !important;
        max-height: 132rem;
        left: 0;
        right: 0;
        object-fit: cover;
        object-position: left center;
        width: auto;
    }
}

@media screen and (min-width: 2200px) {
    .right-side-media {
        width: calc(100% / 12 * 5 + calc(100vw - var(--space-16)) / 2 - var(--container-width) /2 + var(--space-9));
        margin-right: 0;
        flex-shrink: 0;
    }
}

.object-left {
    object-position: left;
}

/* ========================================================================= */
/* Ratios */
/* ========================================================================= */

.squared-ratio {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
}
