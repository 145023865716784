/*
 * Transitions
 */

.transition-opacity {
    transition: opacity 0.2s cubic-bezier(0.45, 0.34, 0.65, 0.96);
    -webkit-backface-visibility: hidden;
}

.transition-transform { transition: transform 0.2s cubic-bezier(0.45, 0.34, 0.65, 0.96); }
