.instagram-floaty-link {
    width: 130px;
    height: 130px;
    transform: translate(-50%, -50%);
}

.instagram-gallery__position-0 {
    transform:  translate(0, 0);
}

.instagram-gallery__position-1 {
    transform:  translate(-10px, 50px);
}

.instagram-gallery__position-2 {
    transform:  translate(20px, 50px);
}

.instagram-gallery__position-3 {
    transform:  translate(-40px, 30px);
}

@media (--breakpoint-sm) {
    .instagram-gallery__position-0 {
        transform:  translateY(0);
    }

    .instagram-gallery__position-1 {
        transform:  translateY(90px);
    }

    .instagram-gallery__position-2 {
        transform:  translateY(-100px);
    }

    .instagram-gallery__position-3 {
        transform:  translateY(100px);
    }
}

@media (--breakpoint-md) {
    .instagram-gallery__position-0 {
        transform:  translateY(0);
    }

    .instagram-gallery__position-1 {
        transform:  translateY(90px);
    }

    .instagram-gallery__position-2 {
        transform:  translateY(0);
    }

    .instagram-gallery__position-3 {
        transform:  translateY(-150px);
    }
}

@media (--breakpoint-lg) {
    .instagram-floaty-link {
        width: 220px;
        height: 220px;
    }
}
