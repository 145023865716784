/*
 * Video
 */

 .video-embed {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.video-embed iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

/* Background video */

.videos-background-html5 {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
