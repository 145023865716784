:root {
    --space-1: 1px;
    --space-2: 0.5rem;
    --space-3: 1rem;
    --space-4: 1.5rem;
    --space-5: 2rem;
    --space-6: 3rem;
    --space-7: 4rem;
    --space-8: 5rem;
    --space-9: 6rem;
    --space-10: 8rem;
    --space-11: 9rem;
    --space-12: 10rem;
    --space-13: 12rem;
    --space-14: 16rem;
    --space-15: 18rem;
    --space-16: 20rem;

    --space-project-1: 19rem;
    --space-project-2: 23rem;
    --space-project-3: 30rem;

}

/* extra spaces for project header */

@media (--breakpoint-xs-only) {
    .mt-ps1 {
        margin-top: var(--space-project-1);
    }
}

@media (--breakpoint-sm-only) {
    .sm-mt-ps2 {
        margin-top: var(--space-project-2);
    }
}

@media (--breakpoint-sm-only) {
    /* Used consitently around container items */

    .sm-only-px3 {
        padding-left: var(--space-3);
        padding-right: var(--space3);
    }
}
@media (--breakpoint-lg) {
    .lg-pyp3 {
        padding-bottom: var(--space-project-3);
        padding-top: var(--space-project-3);
    }
}

.padding-tag {
    padding: 1.0rem 1.2rem 0.8rem;
}

@media (--breakpoint-md) {
    .padding-tag {
        padding: 1.1rem 1.3rem .95rem;
    }
}

@media (--breakpoint-lg) {
    .padding-tag {
        padding: 1.4rem 1.4rem 1.1rem;
    }
}

.padding-tag .padding-tag__counter {
    margin-left: var(--space-3);
}

/* <ButtonPill/> */

.padding-pill {
    padding: 1.4rem 2rem;
}

@media (--breakpoint-md) {
    .padding-pill {
        padding: 1.4rem 3rem;
    }
}

/* Adjust padding if it also has border-* attribute */

.padding-pill[class *= 'border-'],
.padding-pill.border {
    padding: 1.3rem 2rem;
}

@media (--breakpoint-md) {
    .padding-pill[class *= 'border-'],
    .padding-pill.border {
        padding: 1.3rem 3rem;
    }
}

/* Column spaces */

@media (--breakpoint-sm) {
    .sm-px-col-1 {
        padding-right: calc(2 / 12 * 100%);
        padding-left: calc(2 / 12 * 100%);
    }

    .sm-pr-col-1 { padding-right: calc(2 / 12 * 100%); }
    .sm-pl-col-1 { padding-left: calc(2 / 12 * 100%); }

    .sm-px-col-2 {
        padding-right: calc(2 / 12 * 100%);
        padding-left: calc(2 / 12 * 100%);
    }

    .sm-pr-col-2 { padding-right: calc(2 / 12 * 100%); }
    .sm-pl-col-2 { padding-left: calc(2 / 12 * 100%); }

    .sm-mr-col-1 { margin-right: calc(2 / 12 * 100%); }
    .sm-ml-col-1 { margin-left: calc(2 / 12 * 100%); }

    .sm-mr-col-2 { margin-right: calc(2 / 12 * 100%); }
    .sm-ml-col-2 { margin-left: calc(2 / 12 * 100%); }
}

@media (--breakpoint-md) {
    .md-px-col-1 {
        padding-right: calc(1 / 12 * 100%);
        padding-left: calc(1 / 12 * 100%);
    }

    .md-pr-col-1 { padding-right: calc(1 / 12 * 100%); }
    .md-pl-col-1 { padding-left: calc(1 / 12 * 100%); }

    .md-px-col-2 {
        padding-right: calc(2 / 12 * 100%);
        padding-left: calc(2 / 12 * 100%);
    }

    .md-pr-col-2 { padding-right: calc(2 / 12 * 100%); }
    .md-pl-col-2 { padding-left: calc(2 / 12 * 100%); }

    .md-mr-col-1 { margin-right: calc(1 / 12 * 100%); }
    .md-ml-col-1 { margin-left: calc(1 / 12 * 100%); }

    .md-mr-col-2 { margin-right: calc(2 / 12 * 100%); }
    .md-ml-col-2 { margin-left: calc(2 / 12 * 100%); }
}

@media (--breakpoint-lg) {
    .lg-px-col-1 {
        padding-right: calc(1 / 12 * 100%);
        padding-left: calc(1 / 12 * 100%);
    }

    .lg-pr-col-1 { padding-right: calc(1 / 12 * 100%); }
    .lg-pl-col-1 { padding-left: calc(1 / 12 * 100%); }

    .lg-px-col-2 {
        padding-right: calc(2 / 12 * 100%);
        padding-left: calc(2 / 12 * 100%);
    }

    .lg-pr-col-2 { padding-right: calc(2 / 12 * 100%); }
    .lg-pl-col-2 { padding-left: calc(2 / 12 * 100%); }

    .lg-mr-col-1 { margin-right: calc(1 / 12 * 100%); }
    .lg-ml-col-1 { margin-left: calc(1 / 12 * 100%); }

    .lg-mr-col-2 { margin-right: calc(2 / 12 * 100%); }
    .lg-ml-col-2 { margin-left: calc(2 / 12 * 100%); }
}

/* Max Width */

.max-width-100 {
    max-width: 100%;
}

/* Margin */

.mt-auto {
    margin-top: auto;
}

.mb-auto {
    margin-bottom: auto;
}
 
@media (--breakpoint-md) {
    .md-my-auto {
        margin-bottom: auto;
        margin-top: auto;
    }
}
