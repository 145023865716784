/*
 * Accessible Hide
 */

.accessible-hide {
    clip-path: inset(1px);
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
