/*
 * form
 */

.form-field-reset {
    -webkit-appearance: none;
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: 0;
    display: inline-block;
    height: auto;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.form-field-reset:hover { text-decoration: none; }

.form-field-reset:focus {
    box-shadow: none;
    outline: none;
}
