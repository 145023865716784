/**
 *  Swiper
 */

.swiper-wrapper {
    display: flex;
    list-style: none;
    padding-left: 0;
}

.slots-swiper-container .swiper-wrapper,
.date-swiper-container .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.editorial-swiper-container .swiper-container { 
    padding-bottom: var(--space-13); 
}

@media (--breakpoint-lg) {
    /* lg-py13 */
    .editorial-swiper-container .swiper-container { 
        padding-top: var(--space-13);
        padding-bottom: var(--space-13); 
    }

}
@media (--breakpoint-md) {
    /* md-py9  */
    .editorial-swiper-container .swiper-container { 
        padding-top: var(--space-9);
        padding-bottom: var(--space-9); 
    }
}


@media (--breakpoint-sm) {
    /* sm-pb13 */
    .editorial-swiper-container .swiper-container { 
        padding-bottom: var(--space-13); 
    }
}

